import { Action } from './index';
import { TitleActionType, LiveEventActionType, AppActionType } from '../enums';

export interface LiveEventState {
    readonly list?: Entities.LiveEvent[];
    readonly details?: Entities.LiveEvent;
    readonly currentVersion?: number;
    readonly playersCount?: number;
    readonly leaderBoardDefinition?: Entities.EntityLeaderBoard;
}

export type LiveEventAction = Action<Partial<LiveEventState>>;

const initState: LiveEventState = {
};

const reducer = (
    state: LiveEventState = initState,
    action: LiveEventAction,
): LiveEventState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case LiveEventActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;
