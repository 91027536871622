import {
    LiveEventActionType,
    ListEventFilterStateType,
    LeaderBoardType,
    AggregationMethod,
    TimerType,
    RecurringResetTimerType,
} from '../enums';
import { Request } from '../utils';
import { dispatch } from '../reducers';

export const list = async (
    filterState: ListEventFilterStateType = ListEventFilterStateType.All
): Promise<void> => {
    const response = await Request.get<Entities.LiveEvent>(`liveEvent/list`, { filterState });
    dispatch({
        type: LiveEventActionType.Update,
        payload: {
            list: response,
        },
    });
};

export const get = async (liveEventId: string): Promise<void> => {
    const liveEventDetail = await Request.get(`liveEvent/get/${liveEventId}`);
    dispatch({
        type: LiveEventActionType.Update,
        payload: {
            details: liveEventDetail.liveEvent,
            currentVersion: liveEventDetail.currentVersion,
            leaderBoardDefinition: liveEventDetail.leaderBoardDefinition,
        },
    });
};

export const getAndList = async (
    liveEventId: string,
    filterState: ListEventFilterStateType = ListEventFilterStateType.All
): Promise<void> => {
    const [listLiveEvent, liveEventDetail] = await Promise.all([
        Request.get<Entities.LiveEvent>(`liveEvent/list`, { filterState }),
        Request.get(`liveEvent/get/${liveEventId}`)
    ]);
    dispatch({
        type: LiveEventActionType.Update,
        payload: {
            list: listLiveEvent,
            details: liveEventDetail.liveEvent,
            leaderBoardDefinition: liveEventDetail.leaderBoardDefinition,
        },
    });
};

export const createEventRegular = async(
    name: string,
    gameData: string,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
    startTimestamp: number | null,
    endTimestamp: number | null,
    timerType: TimerType,
    recurringResetTimerType: RecurringResetTimerType | null,
    recurringStart: number | null,
    recurringDuration: number | null,
    recurringDailyDays: number[] | null,
    recurringWeeklyDayStart: number | null,
): Promise<void> => {
    await Request.post(
        `liveEvent/createEventRegular`,
        {
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
        },
    );
};

export const createEventLeaderBoard = async(
    name: string,
    gameData: string,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
    startTimestamp: number | null,
    endTimestamp: number | null,
    timerType: TimerType,
    recurringResetTimerType: RecurringResetTimerType | null,
    recurringStart: number | null,
    recurringDuration: number | null,
    recurringDailyDays: number[] | null,
    recurringWeeklyDayStart: number | null,
    ranks: Entities.EntityLeaderBoardRank[],
    maxUsersPerInstance: number,
    leaderBoardType: LeaderBoardType,
    includeGroupLeaderBoard: boolean,
    groupLeaderBoardAggregationMethod: AggregationMethod,
): Promise<void> => {
    await Request.post(
        `liveEvent/createEventLeaderBoard`,
        {
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
            maxUsersPerInstance,
            leaderBoardType,
            ranks,
            includeGroupLeaderBoard,
            groupLeaderBoardAggregationMethod,
        },
    );
};

export const updateEventRegular = async (
    eventId: string,
    name: string,
    gameData: string,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
    startTimestamp: number | null,
    endTimestamp: number | null,
    timerType: TimerType,
    recurringResetTimerType: RecurringResetTimerType | null,
    recurringStart: number | null,
    recurringDuration: number | null,
    recurringDailyDays: number[] | null,
    recurringWeeklyDayStart: number | null,
): Promise<void> => {
    await Request.post(
        `liveEvent/${eventId}/updateEventRegular`,
        {
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
        },
    );
};

export const updateEventLeaderBoard = async (
    eventId: string,
    name: string,
    gameData: string,
    scheduleColor: string,
    developerOnly: boolean,
    finishActions: Entities.AutomaticPlayerAction[],
    startTimestamp: number | null,
    endTimestamp: number | null,
    timerType: TimerType,
    recurringResetTimerType: RecurringResetTimerType | null,
    recurringStart: number | null,
    recurringDuration: number | null,
    recurringDailyDays: number[] | null,
    recurringWeeklyDayStart: number | null,
    ranks: Entities.EntityLeaderBoardRank[],
    maxUsersPerInstance: number,
    leaderBoardType: LeaderBoardType,
    includeGroupLeaderBoard: boolean,
    groupLeaderBoardAggregationMethod: AggregationMethod,
): Promise<void> => {
    await Request.post(
        `liveEvent/${eventId}/updateEventLeaderBoard`,
        {
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
            maxUsersPerInstance,
            leaderBoardType,
            ranks,
            includeGroupLeaderBoard,
            groupLeaderBoardAggregationMethod,
        },
    );
};

export const remove = async (eventId: string): Promise<void> => {
    await Request.post(`liveEvent/${eventId}/delete`);
};

export const collectPlayerRewards = async (eventId: string, actions: Entities.AutomaticPlayerAction[]): Promise<void> => {
    await Request.post(`liveEvent/${eventId}/collectPlayerRewards`, { actions });
};
