import * as React from 'react';
import { TimerType, RecurringResetTimerType } from '../../enums';
import {
    Grid2 as Grid,
    Step,
    StepButton,
    Stepper,
    CardMedia, Chip, Hidden,
} from '@mui/material';
import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    DeveloperModeOutlined, ExpandMore,
} from '@mui/icons-material';
import {
    formatMomentDate,
    getColorIndexByTitle, getColorTitleByIndex,
    RecurringEntity,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../../utils';
import { BaseCreateNewEventComponent, CreateLiveEventProps, CreateLiveEventState } from './BaseCreateNewEventComponent';
import { AutomatePlayerAction, Button, LabelCheckBox, Metadata } from '../common';
import { default as moment } from 'moment';

interface Props extends CreateLiveEventProps {
    readonly onCreate: (
        name: string,
        gameData: string,
        scheduleColor: string,
        developerOnly: boolean,
        finishActions: Entities.AutomaticPlayerAction[],
        startTimestamp: number | null,
        endTimestamp: number | null,
        timerType: TimerType,
        recurringResetTimerType: RecurringResetTimerType | null,
        recurringStart: number | null,
        recurringDuration: number | null,
        recurringDailyDays: number[] | null,
        recurringWeeklyDayStart: number | null
    ) => void;
}

export class CreateNewRegularEventComponent extends BaseCreateNewEventComponent<Props> {

    state = this.initState();

    render() {
        const { step } = this.state;

        let detailContent: React.JSX.Element;
        let detailButtons: React.JSX.Element;

        let activeStep;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Schedule':
                activeStep = 1;
                detailContent = this.renderScheduleContent();
                detailButtons = this.renderScheduleButtons();
                break;
            case 'FinishActions':
                activeStep = 2;
                detailContent = this.renderFinishActionsContent();
                detailButtons = this.renderFinishActionsButtons();
                break;
            case 'Confirm':
                activeStep = 3;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
            default:
                activeStep = -1;
                detailContent = <></>;
                detailButtons = <></>;
                break;
        }

        return (
            <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                <Hidden smDown={true}>
                    <Grid size={12}>
                        <Stepper
                            alternativeLabel={true}
                            nonLinear={true}
                            activeStep={activeStep}
                        >
                            <Step key={'Details'} completed={activeStep > 0}>
                                <StepButton onClick={this.onDetailStepSet} >
                                    {'Details'}
                                </StepButton>
                            </Step>
                            <Step key={'Schedule'} completed={activeStep > 1}>
                                <StepButton onClick={this.onScheduleStepSet} >
                                    {'Schedule'}
                                </StepButton>
                            </Step>
                            <Step key={'FinishActions'} completed={activeStep > 2}>
                                <StepButton onClick={this.onFinishActionsStepSet} >
                                    {'Finish Actions'}
                                </StepButton>
                            </Step>
                            <Step key={'Confirm'} completed={activeStep > 3}>
                                <StepButton onClick={this.onConfirmStepSet} >
                                    {'Confirm'}
                                </StepButton>
                            </Step>
                        </Stepper>
                    </Grid>
                </Hidden>
                <Grid size={12} style={{height: 30}}/>
                <Grid size={12}>
                    {detailContent}
                </Grid>
                <Grid size={12} style={{height: 30}}/>
                <Grid size={12} style={{
                    display: 'grid',
                    justifyContent: 'flex-end',
                    gridAutoFlow: 'column',
                    gridColumnGap: 10,
                }}>
                    {detailButtons}
                </Grid>
            </Grid>
        );
    }

    protected updateFromOld() {
        const { oldLiveEvent, type } = this.props;
        if (!oldLiveEvent) {
            return;
        }

        const { name, startTimestamp, endTimestamp, gameData, finishActions, scheduleColor, developerOnly } = oldLiveEvent;
        if (type === 'Create') {
            this.setState({
                name: `${name} (Copy)`,
                gameData,
                finishActions: finishActions ? finishActions : [],
                developerOnly,
            });
            return;
        }

        this.setState({
            name,
            start: moment(startTimestamp),
            color: getColorIndexByTitle(scheduleColor),
            gameData,
            finishActions: finishActions ? finishActions : [],
            developerOnly,
        });
    }

    protected renderDetailContent(): React.JSX.Element {
        const { developerOnly } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={8} style={{ margin: 'auto' }}>
                    {developerOnly && (
                        <Chip
                            icon={<DeveloperModeOutlined />}
                            size='medium'
                            label='Developer Only'
                            style={{ backgroundColor: '#21BA47', color: 'white'}}
                        />
                    )}
                </Grid>
                <Grid size={4} alignContent={'end'} >
                    <LabelCheckBox
                        label="Developer Only"
                        labelSize={'14px'}
                        icon={DeveloperModeOutlined}
                        iconColor={'inherit'}
                        labelPlacement={'start'}
                        checked={developerOnly}
                        justifyContent={'flex-end'}
                        onChange={this.handleDeveloperOnlyChange}
                    />
                </Grid>
                {this.renderNameField()}
                {this.renderImageField()}
                {this.renderGameDataField()}
            </Grid>
        );
    }

    protected renderConfirmContent(): React.JSX.Element {
        const {
            name,
            gameData,
            finishActions,
            file,
            developerOnly,
            start,
            end,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringWeeklyDayStart,
        } = this.state;

        const recurringStartTimestamp = recurringStart.clone().utc().valueOf() - recurringStart.clone().utc().startOf('day').valueOf();;
        const recurringEntity = new RecurringEntity({
            createdTimestamp: 0,
            startTimestamp: 0,
            endTimestamp: 0,
            timerType,
            recurringResetTimerType,
            recurringStart: recurringStartTimestamp,
            recurringDuration: recurringDuration * 60 * 1000,
            recurringWeeklyDayStart,
        });
        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                {developerOnly && (
                                    <Grid size={12}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<DeveloperModeOutlined />}
                                            size='small'
                                            label='Developer Only'
                                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                                        />
                                    </Grid>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                {timerType === TimerType.Scheduled && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start)}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end)}
                                        </Grid>
                                    </>
                                )}
                                {timerType === TimerType.Recurring && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Repeat</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {recurringEntity.getRecurringText()}
                                        </Grid>
                                    </>
                                )}
                                {file && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Image</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            <CardMedia image={URL.createObjectURL(file)} style={{width: 200, height: 200, margin: 10}}/>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {gameData !== '' && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Metadata
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Metadata
                                    text={gameData}
                                    readOnly={true}
                                    style={{ width: '100%' }}
                                    keyValueContainerStyle={{ boxShadow: 'none' }}
                                    showBox={false}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
                {finishActions.length > 0 && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Actions
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <AutomatePlayerAction
                                    actions={finishActions}
                                    readOnly={true}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
            </Grid>
        );
    }

    protected initState(): CreateLiveEventState {
        const baseState = super.initState();

        return {
            ...baseState,
        };
    }

    protected onCreate = () => {
        const {
            name,
            gameData,
            color,
            developerOnly,
            finishActions,
            start,
            end,
            timerType,
            recurringStart,
        } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        recurringStart.set('seconds', 0);
        recurringStart.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        let startTimestamp: number | null = null;
        let endTimestamp: number | null = null;
        let recurringResetTimerType: number | null = null;
        let recurringStartTimestamp: number | null = null;
        let recurringDuration: number | null = null;
        let recurringDailyDays: number[] | null = null;
        let recurringWeeklyDayStart: number | null = null;
        switch (timerType) {
            case TimerType.Scheduled:
                startTimestamp = start.clone().utc().valueOf();
                endTimestamp = end.clone().utc().valueOf();
                break;
            case TimerType.Recurring:
                startTimestamp = start.clone().utc().startOf('day').valueOf();
                endTimestamp = end.clone().utc().endOf('day').valueOf();
                recurringResetTimerType = this.state.recurringResetTimerType;
                recurringStartTimestamp = recurringStart.clone().utc().valueOf() - recurringStart.clone().utc().startOf('day').valueOf();
                recurringDuration = this.state.recurringDuration * 60 * 1000;
                switch (this.state.recurringResetTimerType) {
                    case RecurringResetTimerType.Daily:
                        recurringDailyDays = this.state.recurringDailyDays;
                        break;
                    case RecurringResetTimerType.Weekly:
                        recurringWeeklyDayStart = this.state.recurringWeeklyDayStart;
                        break;
                }
                break;
        }

        this.props.onCreate(
            name,
            gameData,
            getColorTitleByIndex(color),
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStartTimestamp,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
        );
    }

    protected renderScheduleButtons = () => {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary" />
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onFinishActionsStepSet} variant="contained" />
            </>
        );
    };

    protected renderFinishActionsButtons = () => {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onScheduleStepSet} variant="contained" color="secondary" />
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained" />
            </>
        );
    };
}
