import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import actions from '../actions';
import {
    WarningDialog,
    BaseCostumerSupportCompensationContainer,
    CreateCompensationPreDefineItemDialog,
    Table,
    UpdateCompensationPreDefineItemDialog,
    BaseElement,
    HeaderButton,
} from '../components';
import { UserTitleRole } from '../enums';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    LoyaltyOutlined as LoyaltyIcon,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { formatDate } from '../utils';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
}

type DialogType = 'Create' | 'Update' | 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selected: Entities.CompensationPreDefineItem | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selected: null,
    };

    async componentDidMount() {
        await actions.costumerSupport.listCompensationPreDefineItems();
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Create', icon: AddOutlined, onClick: this.openCreateDialog},
        ];
        return (
            <BaseCostumerSupportCompensationContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                buttons = {buttons}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BaseCostumerSupportCompensationContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderUpdateDialog()}
                {this.renderDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { preDefinedItems, userTitleRole } = this.props;
        const columns = [
            { title: 'Name', field: 'name'},
            { title: 'Key', field: 'key'},
            { title: 'Amount', field: 'amount'},
            { title: 'Created', field: 'created'},
            { title: 'Updated', field: 'updated'},
        ];
        const data = _.map(
            _.orderBy(preDefinedItems, ['name']),
            item => {
                return {
                    ...item,
                    created: formatDate(item.created),
                    updated: formatDate(item.updated),
                };
            }
        );
        return (
            <Table
                data={data}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                    actionsColumnIndex: -1
                }}
                actions ={
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openUpdateDialog(rowData)
                            }),
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openDeleteDialog(rowData)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });
    private renderCreateDialog = () => {
        return (
            <CreateCompensationPreDefineItemDialog
                open={this.state.openDialog === 'Create'}
                title={'Create Compensation Pre Define Item'}
                TitleIcon={LoyaltyIcon}
                onClose={this.closeDialog}
                onCreate={this.onCreate}
            />
        );
    };
    private onCreate = async (name: string, key: string, amount: number) => {
        this.closeDialog();
        await actions.costumerSupport.createCompensationPreDefineItem(name, key, amount);
    };

    private openUpdateDialog = (item: Entities.CompensationPreDefineItem) => this.setState({ openDialog: 'Update', selected: item });
    private renderUpdateDialog = () => {
        const { selected } = this.state;
        if (!selected) {
            return;
        }
        return (
            <UpdateCompensationPreDefineItemDialog
                open={this.state.openDialog === 'Update'}
                title={'Create Compensation Pre Define Item'}
                TitleIcon={LoyaltyIcon}
                selected={selected}
                onClose={this.closeDialog}
                onUpdate={this.onUpdate}
            />
        );
    };
    private onUpdate = async (id: string, name: string, key: string, amount: number) => {
        this.closeDialog();
        await actions.costumerSupport.updateCompensationPreDefineItem(id, name, key, amount);
    };

    private openDeleteDialog = (item: Entities.CompensationPreDefineItem) => this.setState({ openDialog: 'Delete', selected: item });
    private renderDeleteDialog = () => {
        const { openDialog, selected } = this.state;
        if (!selected) {
            return;
        }

        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={'Delete Compensation Pre Define Item'}
                TitleIcon={LoyaltyIcon}
                content="This will permanently delete the item."
                onClose={this.closeDialog}
                onSubmit={this.onDelete}
                maxWidth={'xs'}
            />
        );
    };
    private onDelete = async () => {
        const { selected } = this.state;
        this.closeDialog();
        if (!selected) {
            return;
        }
        await actions.costumerSupport.deleteCompensationPreDefineItem(selected.id);
    };

    private closeDialog = () => this.setState({ openDialog: null, selected: null });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    preDefinedItems: state.costumerSupport.preDefinedItems,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
