import * as React from 'react';
import { default as moment } from 'moment';
import {
    formatMomentDate,
    getDurationValueFromTimeStamp, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary,
} from '../../utils';
import {
    Grid2 as Grid,
    InputLabel,
    Step as MaterialStep,
    StepButton,
    Stepper,
    DialogContentText,
    Chip,
} from '@mui/material';
import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    DeveloperModeOutlined, ExpandMore,
} from '@mui/icons-material';
import {
    Metadata,
    LabelCheckBox,
    Button,
    TextField,
    NumberField,
} from '../common';
import { SeasonTiers } from './SeasonTiers';
import { BaseCreateSeasonComponent, CreateSeasonProps, CreateSeasonState } from './BaseCreateSeasonComponent';
import { LeaderBoardRanks } from '../liveEvent/LeaderBoardRanks';
import { LeaderBoardType } from '../../enums';

interface Props extends CreateSeasonProps {
    readonly maxUsersPerInstance?: number;
    readonly maxAmountPerReport?: number;
    readonly onCreate: (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], ranks: Entities.EntityLeaderBoardRank[], maxUsersPerInstance: number, maxAmountPerReport: number, leaderBoardType: LeaderBoardType) => void;
}

interface State extends CreateSeasonState {
    readonly maxUsersPerInstance: number;
    readonly leaderBoardType: LeaderBoardType;
    readonly ranks: Entities.EntityLeaderBoardRank[];
}

export class CreateSeasonLeaderBoardComponent extends BaseCreateSeasonComponent<Props, State> {
    state = this.initState();

    render() {
        const { step } = this.state;

        let detailContent: React.JSX.Element;
        let detailButtons: React.JSX.Element;

        let activeStep;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Tiers':
                activeStep = 1;
                detailContent = this.renderTiersContent();
                detailButtons = this.renderTiersButtons();
                break;
            case 'Rewards':
                activeStep = 2;
                detailContent = this.renderRewardsContent();
                detailButtons = this.renderRewardsButtons();
                break;
            case 'Confirm':
                activeStep = 4;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
            default:
                activeStep = -1;
                detailContent = <></>;
                detailButtons = <></>;
                break;
        }

        return (
            <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                <Grid size={12}>
                    <Stepper alternativeLabel={true} nonLinear={true} activeStep={activeStep}>
                        <MaterialStep key={'Details'} completed={activeStep > 0}>
                            <StepButton onClick={this.onDetailStepSet} >
                                {'Details'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Schedule'} completed={activeStep > 1}>
                            <StepButton onClick={this.onTiersStepSet} >
                                {'Tiers'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Rewards'} completed={activeStep > 2}>
                            <StepButton onClick={this.onRewardsStepSet} >
                                {'Rewards'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Confirm'} completed={activeStep > 4}>
                            <StepButton onClick={this.onConfirmStepSet} >
                                {'Confirm'}
                            </StepButton>
                        </MaterialStep>
                    </Stepper>
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    {detailContent}
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <div style={{
                        display: 'grid',
                        justifyContent: 'flex-end',
                        gridAutoFlow: 'column',
                        gridColumnGap: 10,
                    }}>
                        {detailButtons}
                    </div>
                </Grid>
            </Grid>
        );
    }

    protected updateFromOld() {
        const { oldSeason, maxUsersPerInstance, type} = this.props;
        if (!oldSeason) {
            return;
        }

        const { name, startTimestamp, endTimestamp, metadata, tiers, developerOnly } = oldSeason;
        let maxUsers = 200;
        if (maxUsersPerInstance) {
            maxUsers = maxUsersPerInstance;
        }
        if (type === 'Create') {
            this.setState({
                name: `${name} (Copy)`,
                metadata,
                tiers: tiers ?? [],
                developerOnly,
                maxUsersPerInstance: maxUsers,
            });
            return;
        }
        this.setState({
            name,
            start: moment(startTimestamp),
            duration: getDurationValueFromTimeStamp(startTimestamp, endTimestamp),
            metadata,
            tiers: tiers ?? [],
            developerOnly,
            maxUsersPerInstance: 200,
        });
    }

    protected renderDetailContent(): React.JSX.Element {
        const { name, metadata, developerOnly, maxUsersPerInstance, maxAmountPerReport } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={8} style={{ margin: 'auto' }}>
                    {developerOnly && (
                        <Chip
                            icon={<DeveloperModeOutlined />}
                            size='medium'
                            label='Developer Only'
                            style={{ backgroundColor: '#21BA47', color: 'white' }}
                        />
                    )}
                </Grid>
                <Grid size={4} alignContent={'end'} >
                    <LabelCheckBox
                        label="Developer Only"
                        labelSize={'14px'}
                        icon={DeveloperModeOutlined}
                        labelPlacement={'start'}
                        checked={developerOnly}
                        justifyContent={'flex-end'}
                        onChange={this.handleChangeDeveloperOnly}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="live-event-name"
                        label=""
                        value={name}
                        onChange={this.onUpdateName}
                        fullWidth={true}
                        required={true}
                        variant="outlined"
                    />
                </Grid>
                {this.renderTimerCreation()}
                <Grid size={12}>
                    <InputLabel>Max Amount Per Report</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        label=""
                        value={maxAmountPerReport}
                        onChange={this.handleMaxAmountPerReport}
                        fullWidth={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Max Users Per Instance</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        label=""
                        value={maxUsersPerInstance}
                        onChange={this.handleMaxUsersPerInstanceChange}
                        fullWidth={true}
                        variant="outlined"
                    />
                </Grid>
                {/*this.renderLeaderBoardTypeField()*/}
                <Grid size={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Metadata
                        text={metadata}
                        onChange={this.handleChangeMetadata}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderRewardsContent = () => {
        const { ranks } = this.state;
        return (
            <Grid container={true} spacing={2}>
                <Grid size={12}>
                    <DialogContentText>
                        List here the prizes ranks that Nucleo will be automatically calculate at the moment to end the event.
                    </DialogContentText>
                </Grid>
                <Grid size={12}>
                    <LeaderBoardRanks ranks={ranks} onUpdateRanks={this.updateRanks} />
                </Grid>
            </Grid>
        );
    };

    /*
    private renderLeaderBoardTypeField = () => {
        const { leaderBoardType } = this.state;

        const onChangeMethod = (event: SelectChangeEvent<number>) => {
            const type =  event.target.value as LeaderBoardType;
            this.setState({ leaderBoardType: type })
        }

        const leaderBoardTypeKeys = Object.keys(LeaderBoardType)
            .filter((key) => Number.isNaN(+key))
            .map((key: string, value: number) => ({
                key,
                value,
            }));
        return (
            <>
                <Grid size={12}>
                    <InputLabel>LeaderBoard Type</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={leaderBoardType}
                        onChange={onChangeMethod}
                        variant={'outlined'}
                        fullWidth={true}
                    >
                        {leaderBoardTypeKeys.map(leaderBoardType => (
                            <MenuItem key={leaderBoardType.key} value={leaderBoardType.value}>
                                {leaderBoardType.key.replace(/([A-Z])/g, ' $1').trim()}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </>
        );
    };
    */

    protected renderRewardsButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onTiersStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained"/>
            </>
        );
    }

    protected renderTiersButtons = () => {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary" />
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onRewardsStepSet} variant="contained" />
            </>
        );
    };

    protected renderConfirmContent(): React.JSX.Element {
        const {
            name,
            start,
            duration,
            metadata,
            tiers,
            maxUsersPerInstance,
            leaderBoardType,
            useDuration,
            developerOnly,
        } = this.state;
        let { end } = this.state;
        if (useDuration) {
            end = start.clone().add(duration, 'minutes');
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                {developerOnly && (
                                    <Grid size={12}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<DeveloperModeOutlined />}
                                            size='small'
                                            label='Developer Only'
                                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                                        />
                                    </Grid>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Start</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatMomentDate(start)}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>End</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatMomentDate(end)}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Max Users Per Instance</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {maxUsersPerInstance}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Leader Board Type</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {LeaderBoardType[leaderBoardType].replace(/([A-Z])/g, ' $1').trim()}
                                </Grid>
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {metadata !== '' && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Metadata
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Metadata
                                    text={metadata}
                                    readOnly={true}
                                    style={{ width: '100%' }}
                                    keyValueContainerStyle={{ boxShadow: 'none' }}
                                    showBox={false}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
                {tiers.length > 0 && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={false}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Tiers ({tiers.length})
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <SeasonTiers
                                    tiers={tiers}
                                    readOnly={true}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
            </Grid>
        );
    }

    protected initState(): State {
        const baseState = super.initState();

        return {
            ...baseState,
            maxUsersPerInstance: 200,
            leaderBoardType: LeaderBoardType.RandomOld,
            ranks: [],
        };
    }

    protected onCreate = () => {
        const { name, start, end, duration, metadata, developerOnly, tiers, ranks, maxUsersPerInstance, useDuration, maxAmountPerReport, leaderBoardType } = this.state;
        start.set('seconds', 0);
        start.set('millisecond', 0);
        if (useDuration) {
            this.props.onCreate(name, start.utc().valueOf(), start.add(duration, 'minutes').utc().valueOf(), metadata, developerOnly, tiers, ranks, maxUsersPerInstance, maxAmountPerReport, leaderBoardType);
        } else {
            this.props.onCreate(name, start.utc().valueOf(), end.utc().valueOf(), metadata, developerOnly, tiers, ranks, maxUsersPerInstance, maxAmountPerReport, leaderBoardType);
        }
    };

    private handleMaxUsersPerInstanceChange = (maxUsersPerInstance: number) => this.setState({maxUsersPerInstance});

    private updateRanks = (ranks: Entities.EntityLeaderBoardRank[]) => this.setState({ ranks });
}
