import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { Components } from '@mui/material/styles/components';
import { fontFamily } from './font';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const styleComponents = (themeMode: ThemeMode): Components<Omit<Theme, 'components'>> => {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '.body': {
                    backgroundColor: themeMode === 'light' ? '#fafafa' : '#121212',
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                    backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                    border: 'none',
                },
                elevation2: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                },
                elevation5: {
                    borderRadius: '8px !important',
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px  !important',
                    backgroundColor: themeMode === 'light' ? '#fff !important' : '#212121 !important',
                    border: 'none',
                }
            },
        },
        MuiModal: {
            styleOverrides: {
                backdrop: {
                    backgroundColor: 'transparent',
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                },
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                    },
                }),
                item: ({ theme }) => ({
                    alignItems: 'center',
                    [theme.breakpoints.only('xs')]: {
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                    },
                }),
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    "&:after": {
                        borderBottom: `1px solid rgba(0,0,0,0.8)`
                    }
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: 28,
                },
                multiline: {
                    height: '100%',
                    padding: '5px !important',
                },
                input: {
                    fontSize: 12,
                    padding: '2px !important',
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#737373' : '#ababab',
                    paddingTop: 14,
                    paddingBottom: 2,
                    paddingLeft: 5,
                    fontWeight: 500,
                    fontSize: 11,
                },
                outlined: {
                    color: themeMode === 'light' ? '#737373' : '#ababab',
                    paddingTop: 0,
                    fontWeight: 500,
                    fontSize: 11,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffff8c',
                    backgroundColor: themeMode === 'light' ? '#fff' : '#ffffff14',
                    boxShadow: themeMode === 'light' ? 'rgb(224 224 224) 0px 0px 1px 1px !important' : 'none',
                    border: 'none',
                    lineHeight: '12px',
                    padding: '0px 5px',
                },
                input: ({ theme }) => ({
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                    },
                    paddingTop: 6,
                    paddingBottom: 6,
                    boxShadow: 'none',
                    '&.MuiSelect-select': {
                        boxShadow: 'none',
                    }
                }),
                inputAdornedStart: {
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                notchedOutline: {
                    border: 'none',

                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                    },
                    '&$focused': {
                        color: 'rgba(0,0,0,0.8)',
                    }
                }),
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&:disabled': {
                        backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                        color: themeMode === 'light' ? '#b8b8b8' : '#717171',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                        color: themeMode === 'light' ? '#b8b8b8' : '#717171',
                    }
                },
                disabled : {
                    backgroundColor: themeMode === 'light' ? '#1973e8' : '#313131',
                },
                outlined: {
                    display: 'flex',
                    gridAutoFlow: 'column',
                    gridColumnGap: 5,
                    justifyContent: 'center',
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                    padding: '6px 16px',
                    borderRadius: 0,
                    '&:hover': {},
                },
                contained: {
                    backgroundColor: themeMode === 'light' ? '#e9eaeb' : '#ffffff14',
                    color: themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffff99',
                    fontWeight: 500,
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                    padding: '6px 16px',
                    borderRadius: 8,
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: themeMode === 'light' ? '#c6c9cc' : '#ffffff3d',
                    },
                },
                containedPrimary: {
                    backgroundColor: themeMode === 'light' ? '#eeeeee' : '#373737',
                    border: 'none',
                    color: themeMode === 'light' ? '#696969' : '#b5b5b5',
                    fontWeight: 500,
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                    padding: '6px 16px',
                    borderRadius: 8,
                    borderWidth: 0,
                    borderStyle: 'solid',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: themeMode === 'light' ? '#c9c9c9' : '#5f5f5f',
                    },
                },
                containedSecondary: {
                    backgroundColor: themeMode === 'light' ? '#fff' : 'transparent',
                    borderColor: themeMode === 'light' ? "#d3d8dd" : '#555555',
                    color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                    fontWeight: 500,
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                    padding: '6px 16px',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    boxShadow: 'none',
                    '&:hover': {
                        borderColor: themeMode === 'light' ? "#8eaae8" : '#536f9c',
                        backgroundColor: themeMode === 'light' ? '#f3f6fd' : '#272a30',
                    },
                },
                containedError: {
                    color: 'rgb(211, 47, 47)',
                    backgroundColor: themeMode === 'light' ? '#fff' : 'transparent',
                    borderColor: themeMode === 'light' ? "#d3d8dd" : '#555555',
                    fontWeight: 500,
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                    padding: '6px 16px',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    boxShadow: 'none',
                    '&:hover': {
                        borderColor: themeMode === 'light' ? "#8eaae8" : '#536f9c',
                        backgroundColor: themeMode === 'light' ? '#f3f6fd' : '#272a30',
                    },
                },
                text: {
                    color: themeMode === 'light' ? '#717171' : '#a5a5a5',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    borderColor: 'rgba(0, 0, 0, 0)',
                    textTransform: 'none',
                    padding: '6px 16px',
                    borderRadius: 8,
                    '&:disabled': {
                        color: themeMode === 'light' ? '#0b57d0' : '#6d6d6d',
                    },
                    '&:hover': {
                        backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                    },
                },
                textSecondary: {
                    color: 'white',
                    backgroundColor: '#1a73e8',
                    fontWeight: 500,
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                    padding: '6px 16px',
                    '&:disabled': {
                        color: '#00000042',
                        borderColor: 'rgba(0, 0, 0, 0)',
                        backgroundColor: 'transparent',
                    },
                    '&:hover': {
                        backgroundColor: '#1967d2',
                    },
                },
                outlinedSecondary: {
                    backgroundColor: "#fff",
                    borderColor: "#545b64",
                    color: "#545b64",
                    fontWeight: 500,
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                    padding: '6px 16px',
                    borderRadius: 0,
                    '&:hover': {
                        borderColor: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                    },
                }
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    borderColor: 'rgba(0, 0, 0, 0)',
                    textTransform: 'none',
                    '&:disabled': {
                        borderColor: 'rgba(0, 0, 0, 0)',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    display: 'contents',
                    padding: '0 !important',
                }
            },
        },
        MuiCard: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                    },
                    height: '100%',
                    width: '100%',
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                    backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                    borderRadius: 8,
                    boxSizing: 'border-box',
                    border: 'none',
                    padding: 5,
                })
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: 10,
                    color: themeMode === 'light' ? '' : 'rgba(255, 255, 255, 0.9)',
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                    },
                    '&:last-child': {
                        paddingBottom: 10,
                    },
                })
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.9)',
                    borderBottom: themeMode === 'light' ? '1px solid rgb(224, 224, 224)' : '1px solid #5e636d',
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                    },
                })
            },
        },
        MuiCardMedia: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                        margin: 'auto',
                    },
                })
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: ({ theme }) => ({
                    justifyContent: 'flex-end',
                    [theme.breakpoints.only('xs')]: {
                        textAlign: 'center',
                        justifyContent: 'center',
                    },
                })
            },
        },
        MuiCardActionArea: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: themeMode === 'light' ? '#efefef !important' : '#2e2e2e',
                    },
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    border: 'none',
                },
                select: ({ theme }) => ({
                    color: themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffff8c',
                    backgroundColor: themeMode === 'light' ? '#fff' : '#2b2b2b',
                    boxShadow: themeMode === 'light' ? 'rgb(224 224 224) 0px 0px 1px 1px' : 'none',
                    border: 'none',
                    borderRadius: 4,
                    boxSizing: 'border-box',
                    width: '100%',
                    padding: '0px 10px',
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridColumnGap: 10,
                    justifyContent: 'flex-start',
                    [theme.breakpoints.only('xs')]: {
                        justifyContent: 'center',
                    },
                    '&:disabled': {
                        backgroundColor: 'transparent',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }),
                outlined: {
                    color: themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffff8c',
                    backgroundColor: 'transparent',
                    boxShadow: themeMode === 'light' ? 'rgb(224 224 224) 0px 0px 1px 1px' : 'none',
                    border: 'none',
                    borderRadius: 4,
                    '&:disabled': {
                        backgroundColor: 'transparent',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                icon: {
                    color: 'inherit',
                }
            },
        },
        MuiNativeSelect: {
            styleOverrides: {
                root: {
                    height: 28,
                },
                icon: {
                    color: 'inherit',
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: '8px  !important',
                    boxShadow: themeMode === 'light' ? 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px !important'
                        : 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px !important',
                    backgroundColor: themeMode === 'light' ? '#fff !important' : '#212121 !important',
                    boxSizing: 'border-box',
                    border: 'none',
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffffe6',
                    '&:hover': {
                        backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                    },
                    '&$selected': {
                        backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                    },
                },
                selected: ({ theme }) => ({
                    width: '100%',
                    padding: '0px 10px',
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridColumnGap: 10,
                    justifyContent: 'flex-start',
                    [theme.breakpoints.only('xs')]: {
                        justifyContent: 'center',
                    },
                }),
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                    borderRadius: 16,
                    boxSizing: 'border-box',
                    backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: '10px',
                    borderBottom: themeMode === 'light' ? '1px solid #dfdfdf' : '1px solid #6d6d6d',
                    color: themeMode === 'light' ? '#333333' : '#fafafa',
                    [theme.breakpoints.only('xs')]: {
                        display: 'flex',
                        justifyContent: 'center',
                    },
                })
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                    '&::-webkit-scrollbar': {
                        width: 8,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        border: '2px solid white',
                        borderRadius: 4,
                        backgroundColor: 'rgba(0, 0, 0, .3)',
                    }
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: 8,
                    [theme.breakpoints.only('xs')]: {
                        display: 'flex',
                        justifyContent: 'center',
                    },
                })
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: themeMode === 'light' ? '#e5e7e9' : '#26272b',
                    borderColor: themeMode === 'light' ? '#e5e7e9' : '#26272b',
                    [theme.breakpoints.only('xs')]: {
                        width: '100%'
                    },
                }),
                vertical: ({ theme }) => ({
                    backgroundColor: themeMode === 'light' ? '#e5e7e9' : '#26272b',
                    borderColor: themeMode === 'light' ? '#e5e7e9' : '#26272b',
                    [theme.breakpoints.only('xs')]: {
                        width: 0,
                    },
                }),
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#737373' : '#ababab',
                },
                colorSecondary: {
                    '&$checked': {
                        color: '#0073bb',
                    },
                }
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#737373' : '#ababab',
                },
            }
        },
        MuiIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: '#0073bb',
                }
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: ({ theme }) => ({
                    minHeight: 30,
                    margin: 0,
                    borderBottom: themeMode === 'light' ? '1px solid rgba(0, 0, 0, 0.12)' : '1px solid #575b64',
                    width: '100%',
                    [theme.breakpoints.only('xs')]: {
                        marginTop: 2,
                        borderTop: '1px solid rgb(216, 222, 228)',
                    },
                }),
                flexContainer: ({ theme }) => ({
                    display: 'box',
                    [theme.breakpoints.only('xs')]: {
                        display: 'block',
                        justifyContent: 'center',
                    },
                }),
                indicator: {
                    display: 'contents',
                    backgroundColor: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                },
                scrollButtons: {
                    color: themeMode === 'light' ? '#0b57d0' : '#ffffffe6',
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '4px 16px',
                    fontSize: 12,
                    color: themeMode === 'light' ? '#0009' :  '#ffffff99',
                    fontWeight: 500,
                    minWidth: 0,
                    margin: 0,
                    textTransform: 'none',
                    minHeight: 40,
                    letterSpacing: '.25px',
                    '&:hover': {
                        backgroundColor: themeMode === 'light' ? '#f1f1f1' : '#1e1e1e',
                    },
                    '&$selected': {
                        backgroundColor: themeMode === 'light' ? '#f1f1f1' : '#1e1e1e',
                    },
                    '&.Mui-selected': {
                        color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                        fontWeight: 500,
                        borderBottom: themeMode === 'light' ? '2px solid #0b57d0' : '2px solid #7cacf8',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&$selected': {
                            backgroundColor: 'transparent',
                        },
                    }
                },
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                    borderRadius: 8,
                    boxSizing: 'border-box',
                    backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                    color: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#ffffffe6',
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                    backgroundColor: 'transparent',
                    [theme.breakpoints.only('xs')]: {
                        display: 'inline-flex',
                        justifyContent: 'center',
                        flexDirection: 'row-reverse',
                        gridColumnGap: 5,
                    },
                }),
                expandIconWrapper: {
                   color: 'inherit',
                }
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderTop: themeMode === 'light' ? '1px solid #dadce0' : '1px solid #343639',
                    borderRadius: '0px 0px 8px 8px',
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                    color: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.9)',
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.9)',
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffffe6',
                    '&:hover': {
                        backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                    },
                    '&$selected': {
                        backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                    },
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                    color: themeMode === 'light' ? '#d0dcf5' : '#07224d',
                    height: 24,
                },
                icon: {
                    color: 'inherit',
                    width: 15,
                    height: 15,
                },
                label: {
                    color: 'inherit',
                    padding: '2px 8px',
                    margin: 'auto',
                },
                deleteIcon: {
                    color: 'inherit',
                    '&:hover': {
                        color: 'inherit',
                    },
                }
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    textAlign: 'center',
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: themeMode === 'light' ? '#f5f5f5' : '#3f3f3f',
                }
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: 10,
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    width: 24,
                    height: 24,
                    color: themeMode === 'light' ? '#737373' : '#2b2b2b',
                    '&.Mui-active': {
                        border: 'none',
                        color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                        width: 26,
                        height: 26,
                    },
                    '&.Mui-completed': {
                        border: 'none',
                        color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                        width: 26,
                        height: 26,
                    }
                },
                text: {
                    color: themeMode === 'light' ? '#404040' : '#fafafa',
                    fill: themeMode === 'light' ? '#fff' : '#fafafa',
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                },
                alternativeLabel: {
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                },
                labelContainer: {
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                },
                label: {
                    '&.Mui-active': {
                        color: themeMode === 'light' ? '#404040' : '#adadad',
                    },
                    '&.Mui-completed': {
                        color: themeMode === 'light' ? '#404040' : '#adadad',
                    },
                    '&$alternativeLabel': {
                        color: themeMode === 'light' ? '#404040' : '#adadad',
                        textTransform: 'uppercase',
                        fontSize: 'smaller',
                        fontWeight: 'bold',
                    }
                }
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                'root': {
                    '&.Mui-active': {
                        'color': themeMode === 'light' ? '#e5e7e9' : '#26272b',
                        '& $line': {}
                    },
                    '&.Mui-completed': {
                        '& $line': {
                            borderColor: themeMode === 'light' ? '#e5e7e9' : '#26272b',
                        },
                    }
                },
                alternativeLabel: {
                    top: 10,
                    left: 'calc(-50% + 16px)',
                    right: 'calc(50% + 16px)',
                },
                line: {
                    borderTopWidth: 1,
                    borderRadius: 1,
                },
            },
        },
        MuiPickersToolbar: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#262626' : '#e5e5e5',
                    '&.Mui-selected': {
                        color: themeMode === 'light' ? '#3d3d3c' : '#8b8b8b',
                    },
                    '& .MuiTypography-overline': {
                        color: themeMode === 'light' ? '#717171' : '#6d6d6d',
                    },
                }
            },
        },
        MuiPickersToolbarText: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#717171' : '#6d6d6d',
                    '&.Mui-selected': {
                        color: themeMode === 'light' ? '#252525' : '#aeaeae',
                    },
                }
            },
        },
        MuiDatePickerToolbar: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#717171 !important' : '#6d6d6d !important',
                    '& .MuiTypography-overline': {
                        color: themeMode === 'light' ? '#717171' : '#6d6d6d',
                    },
                },
            }
        },
        MuiDateTimePickerToolbar: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#717171 !important' : '#6d6d6d !important'
                },
                timeDigitsContainer: {
                    alignItems: 'center'
                }
            }
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    color: themeMode === 'light' ? '#3d3d3c' : '#8b8b8b',
                }
            }
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    color: themeMode === 'light' ? '#262626' : '#e5e5e5',
                    '&.Mui-selected': {
                        backgroundColor: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                    },
                    '&.Mui-disabled': {
                        color: themeMode === 'light' ? '#bdbdbd !important' : '#535353 !important',
                    }
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#262626' : '#e5e5e5',
                    '&.Mui-selected': {
                        backgroundColor: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                        color: themeMode === 'light' ? '#ffffff' : '#051f48',
                    },
                    '&.Mui-disabled': {
                        color: themeMode === 'light' ? '#bdbdbd !important' : '#535353 !important',
                    }
                }
            }
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                button: {
                    color: themeMode === 'light' ? '#717171' : '#aeaeae',
                    '&:disabled': {
                        color: themeMode === 'light' ? '#bbbbba' : '#6d6d6d',
                    }
                },
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                labelContainer: {
                    color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                },
                switchViewButton: {
                    color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                },
                switchViewIcon: {
                    color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
                }
            }
        },
        MuiClock: {
            styleOverrides: {
                clock: {
                    backgroundColor: themeMode === 'light' ? '#efefef' : '#3d3d3d',
                },
                pin: {
                    backgroundColor: themeMode === 'light' ? '#1976d2' : '#90caf9',
                }
            }
        },
        MuiClockNumber: {
            styleOverrides: {
                root: {
                    color: themeMode === 'light' ? '#222222' : '#ffffff',
                    '&.Mui-selected': {
                        color: themeMode === 'light' ? '#222222' : '#ffffff',
                    }
                }
            }
        },
        MuiClockPointer: {
            styleOverrides: {
                root: {
                    backgroundColor: themeMode === 'light' ? '#1976d2' : '#90caf9',
                },
                thumb: {
                    borderColor: themeMode === 'light' ? '#1976d2' : '#90caf9',
                    backgroundColor: themeMode === 'light' ? '#1976d2' : '#90caf9',
                }
            }
        },
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent !important',
                }
            }
        }
    };
};

export const lightTheme = createTheme({
    typography: {
        fontFamily,
    },
    palette: {
        primary: {
            main: '#404040',
            dark: '#0b57d0',
        },
        action: {
            hover: '#1f3453',
            active: '#fff'
        },
    },
    components: styleComponents('light'),
});

export const darkTheme = createTheme({
    typography: {
        fontFamily,
    },
    palette: {
        primary: {
            main: '#adadad',
            dark: '#7cacf8',
        },
        action: {
            hover: '#d3e2fa',
            active: '#fff'
        },
    },
    components: styleComponents('dark'),
});
