import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { ContainerContext, mapProps } from './';
import { BaseContainer, Table, BaseElement, HeaderButton, WarningDialog } from '../components';
import {
    AggregationMethod,
    LeaderboardType,
    TimerType,
    UserTitleRole,
} from '../enums';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    Newspaper as NewspaperIcon,
    PollOutlined as PollIcon,
    RestartAltOutlined,
} from '@mui/icons-material';
import {
    formatDate,
    getLeaderBoardLink, isActive, isEnded,
    RecurringEntity,
} from '../utils';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Reset'| 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selected: Entities.LeaderBoard | null;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selected: null
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Create', icon: AddOutlined, onClick: (event: any) => this.toLink(`/leaderBoard/create`)},
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"LeaderBoards"}
                TitleIcon = {PollIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderConfirmDeleteDialog()}
                {this.renderConfirmResetDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Reset', field: 'reset'},
            { title: 'Type', field: 'type'},
            { title: 'Aggregation Method', field: 'aggregationMethod'},
            { title: 'Current version', field: 'currentVersion'},
        ];

        const onRowClick = (event: any, rowData: any) => this.toLink(`/leaderBoard/${rowData.leaderboard.name}/details`);

        return (
            <Table
                data={this.getData}
                columns={columns}
                onRowClick={onRowClick}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: true,
                    sorting: false,
                    draggable: false,
                    emptyRowsWhenPaging: false,
                    actionsColumnIndex: -1,
                }}
                actions={ userTitleRole > UserTitleRole.Viewer ?
                    [
                        rowData => ({
                            icon: RestartAltOutlined,
                            tooltip: 'reset',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openResetDialog(rowData.leaderboard)
                        }),
                        rowData => ({
                            icon: DeleteOutlineOutlined,
                            tooltip: 'delete',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openDeleteDialog(rowData.leaderboard)
                        }),
                    ]
                    : undefined
                }
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`leaderboard/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.LeaderBoard) => {
            return {
                leaderboard: e,
                details: this.getDetails(e),
                reset: this.getReset(e),
                type: LeaderboardType[e.type],
                aggregationMethod: AggregationMethod[e.aggregationMethod],
                created: formatDate(e.createdTimestamp),
                currentVersion: e.currentVersion,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getDetails = (leaderBoard: Entities.LeaderBoard) => {
        return (
            <div>
                {getLeaderBoardLink(leaderBoard.name)}
                <div>
                    <div style={{ fontWeight: 500 }}>Created</div>
                    {formatDate(leaderBoard.createdTimestamp)}
                </div>
            </div>
        );
    };

    private getReset = (leaderboard: Entities.LeaderBoard) => {
        switch (leaderboard.timerType) {
            case TimerType.None:
                return (
                    <div style={{ fontWeight: 500 }}>Never</div>
                );
            case TimerType.Scheduled:
                return (
                    <div>
                        <div>
                            {this.getStatus(leaderboard)}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>Start</div>
                            {formatDate(leaderboard.startTimestamp)}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>End</div>
                            {formatDate(leaderboard.endTimestamp)}
                        </div>
                    </div>
                );
            case TimerType.Recurring:
                if (!isActive(leaderboard.startTimestamp, leaderboard.endTimestamp)) {
                    return (
                        <div style={{ color: 'orange', fontWeight: 500 }}>Finished</div>
                    );
                }

                const recurringEntity = new RecurringEntity(leaderboard);
                return (
                    <div>
                        <div>
                            {this.getStatus(leaderboard)}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>Start</div>
                            {formatDate(leaderboard.startTimestamp, 'LL')}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>End</div>
                            {formatDate(leaderboard.endTimestamp, 'LL')}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>Recurring</div>
                            {recurringEntity.getRecurringText()}
                        </div>
                    </div>
                );
        }
    };

    private getStatus = (leaderboard: Entities.LeaderBoard) => {
        if (isActive(leaderboard.startTimestamp, leaderboard.endTimestamp)) {
            return <div style={{color: 'green', fontWeight: 500}}>In Progress</div>;
        }
        if (isEnded(leaderboard.endTimestamp)) {
            return <div style={{color: 'orange', fontWeight: 500}}>Finished</div>;
        }
        return <div style={{color: 'grey', fontWeight: 500}}>Scheduled</div>;
    };

    private closeDialog = () => {
        this.setState({ openDialog: null });
    };

    private openResetDialog = (selected: Entities.LeaderBoard) => this.setState({openDialog: 'Reset', selected});
    private renderConfirmResetDialog = () => {
        const { openDialog} = this.state;
        return (
            <WarningDialog
                open={openDialog === 'Reset'}
                title={'Confirm Reset'}
                TitleIcon={NewspaperIcon}
                content="Do you want to reset the leaderboard."
                onClose={this.closeDialog}
                onSubmit={this.reset}
                maxWidth={'xs'}
            />
        );
    };
    private reset = async () => {
        const { selected } = this.state;
        this.closeDialog();
        if (!selected) {
            return;
        }
        await actions.leaderboard.reset(selected.name);
    };

    private openDeleteDialog = (selected: Entities.LeaderBoard) => this.setState({openDialog: 'Delete', selected});
    private renderConfirmDeleteDialog = () => {
        const { openDialog} = this.state;
        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={'Confirm Delete'}
                TitleIcon={NewspaperIcon}
                content="Do you want to delete the leaderboard."
                onClose={this.closeDialog}
                onSubmit={this.remove}
                maxWidth={'xs'}
            />
        );
    };
    private remove = async () => {
        const { selected } = this.state;
        this.closeDialog();
        if (!selected) {
            return;
        }
        await actions.leaderboard.remove(selected.name);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
