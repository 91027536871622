import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogProps,
} from '@mui/material';
import { Button } from '../input';
import { getThemeMode } from '../../../reducers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styleComponents } from '../../../utils';

export interface BaseDialogProps extends DialogProps {
    readonly title: any;
    readonly TitleIcon?: any;
    readonly TitleIconObject?: any;
    readonly onClose: () => void;
    readonly fullWidth?: boolean;
    readonly maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export interface BaseDialogState {
}

export abstract class BaseDialog<P extends BaseDialogProps = BaseDialogProps, S extends BaseDialogState = BaseDialogState> extends React.Component<P, S> {
    render() {
        const { fullWidth, maxWidth } = this.props;

        const themeMode = getThemeMode();

        const theme = createTheme({
            components: {
                ...styleComponents(themeMode),
                MuiDialog: {
                    styleOverrides: {
                        root: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            height: 28,
                            '&.Mui-disabled': {
                                backgroundColor: themeMode === 'light' ? '#f2f2f2' : '#313131',
                                color: themeMode === 'light' ? '#b8b8b8' : '#717171',
                            }
                        },
                    }
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            color: themeMode === 'light' ? '#404040' : '#adadad',
                            padding: '10px !important',
                            '&::-webkit-scrollbar': {
                                width: 10,
                                backgroundColor: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: themeMode === 'light' ? '#babec9' : '#8a9bb2',
                                borderRadius: 4,
                                border: themeMode === 'light' ? '2px solid #b2b9c9' : '2px solid #262f3d',
                            }
                        },
                    },
                },
                MuiDivider: {
                    styleOverrides: {
                        root: ({ theme }) => ({
                            backgroundColor: themeMode === 'light' ? '#e5e7e9' : '#5d636d',
                            borderColor: themeMode === 'light' ? '#e5e7e9' : '#5d636d',
                            [theme.breakpoints.only('xs')]: {
                                width: '100%'
                            },
                        }),
                        vertical: ({ theme }) => ({
                            backgroundColor: themeMode === 'light' ? '#e5e7e9' : '#5d636d',
                            borderColor: themeMode === 'light' ? '#e5e7e9' : '#5d636d',
                            [theme.breakpoints.only('xs')]: {
                                width: 0,
                            },
                        }),
                    },
                },
            }
        });

        return (
            <ThemeProvider theme={theme}>
                <Dialog
                    open={this.props.open}
                    onClose={this.onClose}
                    fullWidth={fullWidth ? fullWidth : true}
                    maxWidth={maxWidth ? maxWidth : 'md'}
                >
                    <DialogTitle>
                        {this.renderTitle()}
                    </DialogTitle>
                    <DialogContent>
                        {this.renderContent()}
                    </DialogContent>
                    <DialogActions >
                        <Button text="Cancel" onClick={this.onClose} variant="text" />
                        {this.renderActions()}
                    </DialogActions>
                </Dialog>
                {this.renderExtraDialogs()}
            </ThemeProvider>
        );
    }

    protected renderTitle(): React.JSX.Element {
        const { title, TitleIcon, TitleIconObject } = this.props;
        return (
            <div style={{
                display: 'grid',
                gridAutoFlow: 'column',
                gridColumnGap: 10,
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                {TitleIcon && (
                    <TitleIcon style={{ width: 25, height: 25, verticalAlign: 'middle' }} />
                )}
                {TitleIconObject && TitleIconObject}
                <div style={{
                    margin: 'auto 0px',
                    fontSize: 15,
                    fontWeight: 500,
                    lineHeight: '25px',
                    letterSpacing: '-0.014rem',
                }}>
                    {title}
                </div>
            </div>
        );
    }

    protected renderContent(): React.JSX.Element {
        return <></>;
    }

    protected renderActions(): React.JSX.Element {
        return <></>;
    }

    protected renderExtraDialogs(): React.JSX.Element {
        return <></>;
    }

    protected onClose = () => {
        this.setState(this.initState());
        this.props.onClose();
    };

    protected initState(): BaseDialogState {
        return {};
    }
}
