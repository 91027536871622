import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer,
    BaseElement,
    CreateNewLeaderBoardEventComponent,
    CreateNewRegularEventComponent,
} from '../components';
import {
    AggregationMethod,
    EventType,
    LeaderBoardType,
    ListEventFilterStateType,
    RecurringResetTimerType,
    TimerType,
} from '../enums';
import actions from '../actions';
import { Card, CardActionArea, CardActionAreaProps, CardContent, Grid2 as Grid } from '@mui/material';
import { BarChartOutlined, CalendarTodayOutlined as CalendarTodayIcon, EventOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import { styled } from '@mui/system';

interface Props extends ContainerContext.Props {
    readonly list?: Entities.LiveEvent[];
}

interface State {
    eventType: EventType | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        eventType: null,
    }

    async componentDidMount() {
        await actions.liveEvent.list(ListEventFilterStateType.ActiveAndComing);
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Live Event',
                to: `/liveEvent/all`,
            },
            {
                text: 'Create',
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Create Live Event"}
                TitleIcon = {CalendarTodayIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { list, app } = this.props;
        if (!list) {
            return;
        }

        const { eventType } = this.state;
        const { themeMode } = app;
        if (eventType === null) {
            return (
                <Grid container={true} spacing={3} style={{ width: '90%', margin: 'auto' }}>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Card
                            style={{
                                boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                                    : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                                borderRadius: 8,
                                backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                                width: '100%',
                                padding: 0,
                            }}
                        >
                            <StyledCardActionArea themeMode={themeMode} onClick={this.onSelectRegular} >
                                <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <EventOutlined style={{width: 36, height: 36, marginBottom: 10}}/>
                                    Regular Live Event
                                </CardContent>
                            </StyledCardActionArea>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Card
                            style={{
                                boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                                    : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                                borderRadius: 8,
                                backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                                width: '100%',
                                padding: 0,
                            }}
                        >
                            <StyledCardActionArea themeMode={themeMode} onClick={this.onSelectLeaderBoard} >
                                <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <BarChartOutlined style={{width: 36, height: 36, marginBottom: 10}}/>
                                    Leaderboard Live Event
                                </CardContent>
                            </StyledCardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            )
        }

        switch (eventType) {
            case EventType.Regular:
                return (
                    <CreateNewRegularEventComponent
                        themeMode={themeMode}
                        eventType={EventType.Regular}
                        type='Create'
                        liveEvents={list}
                        onCreate={this.onCreateRegular}
                    />
                );
            case EventType.LeaderBoard:
                return (
                    <CreateNewLeaderBoardEventComponent
                        themeMode={themeMode}
                        eventType={EventType.LeaderBoard}
                        type='Create'
                        liveEvents={list}
                        onCreate={this.onCreateLeaderBoard}
                    />
                );
        }
    }

    private onSelectRegular = () => this.setState({ eventType: EventType.Regular });
    private onSelectLeaderBoard = () => this.setState({ eventType: EventType.LeaderBoard });

    private onCreateRegular = async (
        name: string,
        gameData: string,
        scheduleColor: string,
        developerOnly: boolean,
        finishActions: Entities.AutomaticPlayerAction[],
        startTimestamp: number | null,
        endTimestamp: number | null,
        timerType: TimerType,
        recurringResetTimerType: RecurringResetTimerType | null,
        recurringStart: number | null,
        recurringDuration: number | null,
        recurringDailyDays: number[] | null,
        recurringWeeklyDayStart: number | null,
    ): Promise<void> => {
        await actions.liveEvent.createEventRegular(
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart
        );
        this.toLiveEvent();
    };

    private onCreateLeaderBoard = async (
        name: string,
        gameData: string,
        scheduleColor: string,
        developerOnly: boolean,
        finishActions: Entities.AutomaticPlayerAction[],
        startTimestamp: number | null,
        endTimestamp: number | null,
        timerType: TimerType,
        recurringResetTimerType: RecurringResetTimerType | null,
        recurringStart: number | null,
        recurringDuration: number | null,
        recurringDailyDays: number[] | null,
        recurringWeeklyDayStart: number | null,
        ranks: Entities.EntityLeaderBoardRank[],
        maxUsersPerInstance: number,
        leaderBoardType: LeaderBoardType,
        includeGroupLeaderBoard: boolean,
        groupLeaderBoardAggregationMethod: AggregationMethod,
    ): Promise<void> => {
        await actions.liveEvent.createEventLeaderBoard(
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
            ranks,
            maxUsersPerInstance,
            leaderBoardType,
            includeGroupLeaderBoard,
            groupLeaderBoardAggregationMethod,
        );
        this.toLiveEvent();
    };

    private toLiveEvent = () => this.toLink(`/liveEvent/all`);
}

interface StyledCardActionAreaProps extends CardActionAreaProps, ThemeModeProps {
}
const StyledCardActionArea = styled((props: StyledCardActionAreaProps) => (
    <CardActionArea
        {...props}
        sx={{
            '&.MuiCardActionArea-root': {
                padding: '0px !important',
                height: 126,
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
                '&$selected': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
            }
        }}
    >
        {props.children}
    </CardActionArea>
))(() => ({}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    list: state.liveEvent.list,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
