import {
    AggregationMethod,
    LeaderboardType,
    TimerType,
    RecurringResetTimerType,
    LeaderBoardActionType,
} from '../enums';
import { Request } from '../utils';
import { dispatch } from '../reducers';

export const get = async (
    name: string,
): Promise<void> => {
    const leaderboard = await Request.get(`leaderboard/${name}/get`);
    dispatch({
        type: LeaderBoardActionType.Update,
        payload: {
            leaderboard,
        },
    });
};

export const create = async (
    name: string,
    leaderboardType: LeaderboardType,
    aggregationMethod: AggregationMethod,
    startTimestamp: number | null,
    endTimestamp: number | null,
    timerType: TimerType,
    recurringResetTimerType: RecurringResetTimerType | null,
    recurringStart: number | null,
    recurringDuration: number | null,
    recurringDailyDays: number[] | null,
    recurringWeeklyDayStart: number | null,
): Promise<void> => {
    const data = {
        name,
        leaderboardType,
        aggregationMethod,
        startTimestamp,
        endTimestamp,
        timerType,
        recurringResetTimerType,
        recurringStart,
        recurringDuration,
        recurringDailyDays,
        recurringWeeklyDayStart
    };
    console.log(data);
    await Request.post(`leaderboard/create`, data);
};

export const reset = async (name: string): Promise<void> => {
    await Request.post(`leaderboard/reset`, { name });
};

export const remove = async (name: string): Promise<void> => {
    await Request.post(`leaderboard/delete`, { name });
};
