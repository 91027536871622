import * as React from 'react';
import { default as moment, Moment } from 'moment';
import {
    colorMenus,
    getColorTitleByIndex,
    getDurationMenus,
    renderColorSelect,
} from '../../utils';
import {
    AutomatePlayerAction,
    Button,
    Calendar,
    Dropzone,
    Metadata,
    TextField,
    ScheduledEntityComponent,
    RecurringEntityComponent,
} from '../common';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import {
    Grid2 as Grid,
    Hidden,
    InputLabel,
    Tab,
    Tabs,
    Typography,
    DialogContentText,
} from '@mui/material';
import { EventType, TimerType, RecurringResetTimerType } from '../../enums';
import { styled } from '@mui/styles';
import { TabContext, TabPanel } from '@mui/lab';

export interface CreateLiveEventProps {
    readonly themeMode: ThemeMode;
    readonly type: 'Create' | 'Edit';
    readonly eventType: EventType;
    readonly liveEvents: Entities.LiveEvent[];
    readonly oldLiveEvent?: Entities.LiveEvent;
}

type Step = 'Detail' | 'Schedule' | 'Rewards' | 'FinishActions' | 'Confirm';
export interface CreateLiveEventState {
    readonly step: Step;
    readonly name: string;
    readonly start: Moment;
    readonly end: Moment;
    readonly color: number;
    readonly gameData: string;
    readonly developerOnly: boolean;
    readonly file: File | null;
    readonly finishActions: Entities.AutomaticPlayerAction[];
    readonly timerType: TimerType;
    readonly recurringResetTimerType: RecurringResetTimerType;
    readonly recurringStart: Moment;
    readonly recurringDuration: number;
    readonly recurringDailyDays: number[] | null;
    readonly recurringWeeklyDayStart: number;
}

export class BaseCreateNewEventComponent<P extends CreateLiveEventProps = CreateLiveEventProps, S extends CreateLiveEventState = CreateLiveEventState> extends React.Component<P, S> {

    render() {
        return (
            <></>
        );
    }

    componentDidMount() {
        this.updateFromOld();
    }

    componentDidUpdate(prevProps: CreateLiveEventProps) {
        if (this.props.oldLiveEvent === prevProps.oldLiveEvent) {
            return;
        }
        this.updateFromOld();
    }

    protected updateFromOld() {
    }

    protected renderDetailContent(): React.JSX.Element {
        return <></>;
    }

    protected renderDetailButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onScheduleStepSet} variant="contained"/>
            </>
        );
    }

    protected renderScheduleContent(): React.JSX.Element {
        const { liveEvents, oldLiveEvent } = this.props;
        const {
            name,
            color,
            timerType,
            start,
            end,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringWeeklyDayStart,
        } = this.state;

        const events: any[] = liveEvents
            .filter(e => {
                if(!oldLiveEvent) {
                    return true;
                }
                return  e.eventId !== oldLiveEvent.eventId;
            })
            .map(e => {
                    return {
                        id: e.eventId,
                        title: e.name,
                        start: new Date(e.startTimestamp),
                        end: new Date(e.endTimestamp),
                        backgroundColor: e.scheduleColor,
                        borderColor: e.scheduleColor,
                        textColor: 'white',
                        editable: false,
                    };
                }
            );

        switch (timerType) {
            case TimerType.Scheduled:
                events.push({
                    title: name,
                    start: new Date(start.valueOf()),
                    end: new Date(end.valueOf()),
                    backgroundColor: getColorTitleByIndex(color),
                    borderColor: getColorTitleByIndex(color),
                    textColor: 'white',
                    editable: false,
                });
                break;
            case TimerType.Recurring:
                switch (recurringResetTimerType) {
                    case RecurringResetTimerType.Daily:
                        {
                            const endOfMonth = start.clone().endOf('month');
                            let startDate = recurringStart.clone();
                            while (startDate.isBefore(endOfMonth)) {
                                events.push({
                                    title: name,
                                    start: new Date(startDate.valueOf()),
                                    end: new Date(startDate.add(recurringDuration, 'millisecond').valueOf()),
                                    backgroundColor: getColorTitleByIndex(color),
                                    borderColor: getColorTitleByIndex(color),
                                    textColor: 'white',
                                    editable: false,
                                });
                                startDate.add(1, 'day');
                            }
                        }
                        break;
                    case RecurringResetTimerType.Weekly: {
                        const endOfMonth = start.clone().endOf('month');
                        let startDate = recurringStart.clone();
                        while (startDate.isBefore(endOfMonth)) {
                            if (recurringWeeklyDayStart === startDate.day()) {
                                events.push({
                                    title: name,
                                    start: new Date(startDate.valueOf()),
                                    end: new Date(startDate.add(recurringDuration, 'millisecond').valueOf()),
                                    backgroundColor: getColorTitleByIndex(color),
                                    borderColor: getColorTitleByIndex(color),
                                    textColor: 'white',
                                    editable: false,
                                });
                            }
                            startDate.add(1, 'day');
                        }
                    }
                        break;
                }
                break;
        }

        return (
            <Grid container={true} spacing={3} >
                <Grid size={{ xs: 12, sm: 12, md: 4}}>
                    <Grid container={true} spacing={0} style={{marginTop: 10}}>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Color</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            {renderColorSelect(this.handleColorChange, color)}
                        </Grid>
                        <Grid size={{ xs: 12 }} style={{ marginTop: 10 }}>
                            {this.renderTimerCreation()}
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdDown={true}>
                    <Grid size={{ xs: 12, sm: 12, md: 8}}>
                        <Calendar
                            headerToolbar = {{
                                left: 'title',
                                center: '',
                                right: 'today prev,next'
                            }}
                            weekends={true}
                            height={'parent'}
                            contentHeight={'auto'}
                            handleWindowResize={true}
                            events={events}
                        />
                    </Grid>
                </Hidden>
            </Grid>
        );
    }

    protected renderScheduleButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onRewardsStepSet} variant="contained"/>
            </>
        );
    }

    protected renderRewardsContent(): React.JSX.Element {
        return <></>;
    }

    protected renderRewardsButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onScheduleStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onFinishActionsStepSet} variant="contained"/>
            </>
        );
    }

    protected renderFinishActionsContent(): React.JSX.Element {
        const { finishActions } = this.state;
        return (
            <Grid container={true} spacing={2}>
                <Grid size={{ xs: 12 }}>
                    <Typography variant="caption">
                        List here the actions that Nucleo will be automatically trigger at the moment to end the event.
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <AutomatePlayerAction
                        actions={finishActions}
                        onUpdateActions={this.onUpdateActions}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderFinishActionsButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onRewardsStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained"/>
            </>
        );
    }

    protected renderConfirmContent(): React.JSX.Element {
        return <></>;
    }

    protected renderConfirmButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onFinishActionsStepSet} variant="contained" color="secondary" />
                <Button text="Confirm" onClick={this.onCreate} variant="contained"/>
            </>
        );
    }

    protected renderTimerCreation = () => {
        const { themeMode } = this.props;
        const {
            timerType,
            start,
            end,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringWeeklyDayStart,
            recurringDailyDays
        } = this.state;

        return (
            <TabContext value={timerType.toString()}>
                <Tabs
                    value={timerType.toString()}
                    onChange={(event, value) => this.setState({ timerType: parseInt(value) })}
                >
                    <Tab label="Never" value={"0"}/>
                    <Tab label="Scheduled" value={"1"}/>
                    <Tab label="Recurring" value={"2"}/>
                </Tabs>
                <StyledTabPanel value={"0"}>
                    <DialogContentText>
                        The leaderboard will start immediately after you complete the setup and never reset.
                    </DialogContentText>
                </StyledTabPanel>
                <StyledTabPanel value={"1"}>
                    <ScheduledEntityComponent
                        title={'Scheduled leaderboards only run once at the selected date and time in the future.'}
                        themeMode={themeMode}
                        start={start}
                        handleStartChange={this.handleStartChange}
                        end={end}
                        handleEndChange={this.handleEndChange}
                    />
                </StyledTabPanel>
                <StyledTabPanel value={"2"}>
                    <RecurringEntityComponent
                        title={'Recurring leaderboards will continue to run until you stop them or a specific end date you set is reached.'}
                        themeMode={themeMode}
                        start={start}
                        handleStartChange={this.handleStartChange}
                        end={end}
                        handleEndChange={this.handleEndChange}
                        recurringResetTimerType={recurringResetTimerType}
                        handleRecurringResetTimerTypeChange={this.handleRecurringResetTimerTypeChange}
                        recurringStart={recurringStart}
                        handleRecurringStartChange={this.handleRecurringStartChange}
                        recurringDuration={recurringDuration}
                        handleRecurringDurationChange={this.handleRecurringDurationChange}
                        recurringDailyDays={recurringDailyDays}
                        handleRecurringDailyDaysChange={this.handleRecurringDailyDaysChange}
                        recurringWeeklyDayStart={recurringWeeklyDayStart}
                        handleRecurringWeeklyDayStartChange={this.handleRecurringWeeklyDayStartChange}
                    />
                </StyledTabPanel>
            </TabContext>
        );
    };

    protected onCreate = () => {
    }

    protected initState(): CreateLiveEventState {
        return {
            step: 'Detail',
            name: '',
            color: colorMenus.BlueViolet.value,
            gameData: '',
            file: null,
            developerOnly: false,
            finishActions: [],
            start: moment.parseZone(),
            end: moment.parseZone().add(1, 'day'),
            timerType: TimerType.None,
            recurringResetTimerType: RecurringResetTimerType.Daily,
            recurringStart: moment.parseZone(),
            recurringDuration: getDurationMenus()['1hour'].value,
            recurringDailyDays: null,
            recurringWeeklyDayStart: 0,
        };
    }

    protected handleColorChange = (color: number) => this.setState({ color });

    private handleStartChange = (start: Moment) => this.setState({ start });

    private handleEndChange = (end: Moment) => this.setState({ end });

    private handleRecurringDurationChange = (recurringDuration: number) => this.setState({ recurringDuration });

    private handleRecurringResetTimerTypeChange = (recurringResetTimerType: number) => this.setState({ recurringResetTimerType });

    private handleRecurringDailyDaysChange = (recurringDailyDays: number[] | null) => this.setState({ recurringDailyDays });

    private handleRecurringWeeklyDayStartChange = (recurringWeeklyDayStart: number) => this.setState({ recurringWeeklyDayStart });

    private handleRecurringStartChange = (recurringStart: Moment) => this.setState({ recurringStart });

    protected handleGameDataChange = (gameData: string) => this.setState({ gameData });

    protected handleDrop = (file: File) => this.setState({file});

    protected handleDeveloperOnlyChange = (developerOnly: boolean) => this.setState({developerOnly});

    protected renderNameField = () => {
        const { name } = this.state;
        return (
            <>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <TextField
                        label=""
                        value={name}
                        onChange={this.updateName}
                        fullWidth={true}
                        required={true}
                        variant="outlined"
                    />
                </Grid>
            </>
        );
    };

    protected renderImageField = () => {
        return (
            <>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Image</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Dropzone
                        accept={{
                            "image/jpeg": ['.jpeg'],
                            "image/jpg": ['.jpg'],
                            "image/png": ['.png'],
                        }}
                        text={'No file chosen'}
                        description={'Upload image (200x200px - JPEG, JPG or PNG)'}
                        onDropFile={this.handleDrop}
                    />
                </Grid>
            </>
        );
    };

    protected renderGameDataField = (readOnly: boolean = false) => {
        const { gameData } = this.state;
        return (
            <>
                <Grid size={{ xs: 12 }}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Metadata
                        text={gameData}
                        readOnly={readOnly}
                        onChange={this.handleGameDataChange}
                    />
                </Grid>
            </>
        );
    };

    protected updateName = (name: string) => this.setState({ name });

    protected onUpdateActions = (finishActions: Entities.AutomaticPlayerAction[]) => this.setState({finishActions});

    protected onDetailStepSet = () => this.setState({ step: 'Detail'});

    protected onRewardsStepSet = () => this.setState({ step: 'Rewards'});

    protected onScheduleStepSet = () => this.setState({ step: 'Schedule'});

    protected onFinishActionsStepSet = () => this.setState({ step: 'FinishActions'});

    protected onConfirmStepSet = () => this.setState({ step: 'Confirm'});
}

const StyledTabPanel = styled(TabPanel)(({
    '&.MuiTabPanel-root': {
        padding: '10px 0px',
    }
}));
