import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import {
    WarningDialog,
    BasePlayerContainer,
    CreatePlayerCompensationDialog,
    Table,
    BaseElement,
    UpdatePlayerCompensationDialog,
    HeaderButton,
} from '../components';
import { ContainerContext, mapProps } from './index';
import { formatDate } from '../utils';
import { AddOutlined } from '@mui/icons-material';
import { UserTitleRole } from '../enums';
import { Grid2 as Grid, InputLabel } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
}

type DialogType = 'Create' | 'Update' | 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selected: Entities.PlayerCompensation | null;
}

class Container extends BaseElement<Props, State> {
    state = {
        openDialog: null,
        selected: null,
    };

    async componentDidMount() {
        await actions.costumerSupport.listCompensationPreDefineItems();
    }

    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Create', icon: AddOutlined, onClick: this.openCreateDialog},
        ];
        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Compensations"}
                playerId = {playerId}
                showSubTabs = {true}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderUpdateDialog()}
                {this.renderDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;

        const columns = [
            { title: 'Id', field: 'id'},
            { title: 'Name', field: 'name'},
            { title: 'Description', field: 'description'},
            { title: 'Created', field: 'created'},
            { title: 'Schedule', field: 'schedule'},
            { title: 'Collected', field: 'collected'},
        ];

        return (
            <Table
                columns={columns}
                data={this.getData}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                    actionsColumnIndex: -1
                }}
                detailPanel={this.showItems}
                actions ={
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                hidden: rowData.collected === 'TRUE',
                                icon: 'edit',
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openUpdateDialog(rowData.entity)
                            }),
                            rowData => ({
                                hidden: rowData.collected === 'TRUE',
                                icon: 'delete',
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openDeleteDialog(rowData.entity)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { playerId } = this.props;
        if (!playerId) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`player/${playerId}/compensations`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.PlayerCompensation) => {
            return {
                entity: e,
                id: e.id,
                name: e.name,
                description: e.description,
                created: formatDate(e.created),
                schedule: e.schedule ? formatDate(e.schedule) : '',
                collected: (e.collected) ? 'TRUE' : 'FALSE',
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private showItems = (rowData: any) => {
        const items = rowData.entity.items;
        const columns: any[] = [
            { title: 'Key', field: 'key'},
            { title: 'Amount', field: 'amount'},
        ];

        return (
            <Grid container={true} spacing={1} style={{padding: 5}}>
                <Grid size={12}>
                    <InputLabel>ITEMS ({items.length})</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Table
                        data={items}
                        columns={columns}
                        options={{
                            toolbar: false,
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: true,
                            draggable: false,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });
    private renderCreateDialog = () => {
        const { preDefinedItems } = this.props;
        const { openDialog } = this.state;
        return (
            <CreatePlayerCompensationDialog
                open={openDialog === 'Create'}
                title={'Create Compensation'}
                preDefinedItems={preDefinedItems}
                onClose={this.closeDialog}
                onCreate={this.onCreate}
            />
        );
    };
    private onCreate = async (data: any) => {
        const { playerId } = this.props;
        await actions.player.createCompensation(playerId, data);
    };

    private openUpdateDialog = (item: Entities.PlayerCompensation) => this.setState({ openDialog: 'Update', selected: item });
    private renderUpdateDialog = () => {
        const selected = this.state.selected;
        const { preDefinedItems } = this.props;
        const { openDialog } = this.state;
        if (!selected) {
            return;
        }

        return (
            <UpdatePlayerCompensationDialog
                open={openDialog === 'Update'}
                title={'Update Compensation'}
                selected={selected}
                preDefinedItems={preDefinedItems}
                onClose={this.closeDialog}
                onUpdate={this.onUpdate}
            />
        );
    };
    private onUpdate = async (id: string, data: any) => {
        const { playerId } = this.props;
        this.closeDialog();
        await actions.player.updateCompensation(playerId, id, data);
    };

    private openDeleteDialog = (item: Entities.PlayerCompensation) => this.setState({ openDialog: 'Delete', selected: item });
    private renderDeleteDialog = () => {
        const { openDialog, selected } = this.state;
        if (!selected) {
            return;
        }

        return (
            <WarningDialog
                themeMode={this.props.app.themeMode}
                open={openDialog === 'Delete'}
                title={'Delete Compensation'}
                content="This will permanently delete the item."
                onClose={this.closeDialog}
                onSubmit={this.onDelete}
                maxWidth={'xs'}
            />
        );
    };
    private onDelete = async () => {
        const selected = this.state.selected;
        const { playerId } = this.props;
        this.closeDialog();
        if (!selected) {
            return;
        }

        // @ts-ignore
        const id = selected.id;
        await actions.player.deleteCompensation(playerId, id);
    };

    private closeDialog = () => this.setState({ openDialog: null, selected: null });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: '',
    preDefinedItems: state.costumerSupport.preDefinedItems,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
