import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import {
    BaseContainer,
    BaseElement,
    JsonDialog,
    Metadata,
    Table,
} from '../components';
import {
    formatDate,
    formatNumber,
    getLeaderBoardLink,
    getPlayerLink,
    isActive,
    isEnded,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    parseJson,
    getGroupLink,
    Link,
    RecurringEntity,
} from '../utils';
import { ContainerContext, mapProps } from './index';
import { EventType, TimerType } from '../enums';
import { Grid2 as Grid, Switch, CardMedia, Typography } from '@mui/material';
import {
    BarChartOutlined,
    CalendarTodayOutlined as CalendarTodayIcon,
    Circle,
    EventOutlined,
    ExpandMore,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly liveEventId: string;
    readonly details?: Entities.LiveEvent;
    readonly currentVersion?: number;
    readonly playersCount?: number;
    readonly leaderBoardDefinition?: Entities.EntityLeaderBoard;
}

type DialogType = 'CollectEventRewards' | 'ExtraData';
interface State {
    readonly version: number | null;
    readonly openDialog: DialogType | null;
    readonly countOfLeaderBoards: number;
    readonly leaderBoardView: boolean;
    readonly extraDataSelected: string;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        version: null,
        openDialog: null,
        countOfLeaderBoards: 0,
        leaderBoardView: false,
        extraDataSelected: '',
    };

    private tableRef: any = React.createRef();

    async componentDidMount() {
        const { liveEventId } = this.props;
        await actions.liveEvent.get(liveEventId);
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Live Event',
                to: `/liveEvent/all`,
            },
            {
                text: this.props.liveEventId,
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {'Live Event Details'}
                TitleIcon = {CalendarTodayIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderExtraDataDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        if (details.timerType !== TimerType.Recurring) {
            return (
                <Grid container={true} justifyContent="center" spacing={4}>
                    <Grid size={12}>
                        {this.renderDetails()}
                    </Grid>
                    <Grid size={12}>
                        {this.renderGameData()}
                    </Grid>
                    <Grid size={12}>
                        {this.renderTable()}
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    {this.renderDetails()}
                </Grid>
                <Grid size={12}>
                    {this.renderGameData()}
                </Grid>
                <Grid size={12}>
                    <Grid container={true} justifyContent="center" spacing={2}>
                        <Grid size={{ xs:12, sm:8 }}>
                            {this.renderTable()}
                        </Grid>
                        <Grid size={{ xs:12, sm:4 }}>
                            {this.renderVersionsTable()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private selectVersion = (version: number) => {
        this.setState({ version });
        this.tableRef.current.onSearchChangeDebounce();
    }

    private renderDetails = () => {
        const { details, leaderBoardDefinition } = this.props;
        if (!details) {
            return <></>;
        }

        const recurringEntity = new RecurringEntity(details);

        let start: string = '';
        let end: string = '';

        switch (details.timerType) {
            case TimerType.None:
                break;
            case TimerType.Scheduled:
                start = formatDate(details.startTimestamp);
                end = formatDate(details.endTimestamp);
                break;
            case TimerType.Recurring:
                start = formatDate(details.startTimestamp, 'LL');
                end = formatDate(details.endTimestamp, 'LL');
                break;
        }

        let type: React.JSX.Element = (<></>);
        switch (details.eventType) {
            case EventType.Regular:
                type = <> <EventOutlined style={{width: 20, height: 20}}/> Regular </>;
                break;
            case EventType.LeaderBoard:
                type = <> <BarChartOutlined style={{width: 20, height: 20}}/> LeaderBoard</>;
                break;
            default:
                break;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Details
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Grid container={true} justifyContent="center" spacing={1}>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Type</strong>
                        </Grid>
                        <Grid size={{ xs:12, sm:9 }} style={{ display: 'flex', alignItems: 'center'}}>
                            {type}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Status</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {this.getStatus(details)}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Name</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {details.name}
                        </Grid>
                        {details.startTimestamp && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Start at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {start}
                                </Grid>
                            </>
                        )}
                        {details.endTimestamp && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>End at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {end}
                                </Grid>
                            </>
                        )}
                        {details.timerType === TimerType.Recurring && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Recurring</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {recurringEntity.getRecurringText()}
                                </Grid>
                            </>
                        )}
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Edited by</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {details.username}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Edited at</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {formatDate(details.lastUpdatedTimestamp)}
                        </Grid>
                        {leaderBoardDefinition && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Max Users Per Instance</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {leaderBoardDefinition.maxUsersPerInstance}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderGameData = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Metadata
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Metadata
                        text={details.gameData}
                        readOnly={true}
                        style={{ width: '100%' }}
                        keyValueContainerStyle={{ boxShadow: 'none' }}
                        showBox={false}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderTable = () => {
        const { details } = this.props;
        if (!details) {
            return;
        }

        switch (details.eventType) {
            case EventType.Regular:
                return this.showRegularTable();
            case EventType.LeaderBoard:
                return this.showLeaderBoardTypeView();
            default:
                return;
        }
    };

    private renderVersionsTable = () => {
        const { app, details, currentVersion }  = this.props;
        const version = this.state.version || currentVersion || 1;
        const columns = [
            { title: 'Version', field: 'version'},
            { title: 'Start', field: 'start'},
            { title: 'End', field: 'end'},
        ];

        const onRowClick = (event: any, rowData: any) => this.selectVersion(rowData.entity.version);

        return (
            <Table
                title={
                    <Typography variant="subtitle1" style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', fontSize: '1rem'}}>
                        Versions
                    </Typography>
                }
                data={this.getVersions}
                onRowClick={onRowClick}
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: false,
                    sorting: false,
                    draggable: false,
                    emptyRowsWhenPaging: false,
                    rowStyle: ((rowData, index, level) => {
                        if (rowData.entity.version === version) {
                            return {
                                backgroundColor: app.themeMode === 'light' ? '#f1faff' : '#3f3f3f'
                            };
                        }
                        return {
                            backgroundColor: 'transparent',
                        };
                    }),
                }}
            />
        );
    };

    private getVersions = async (query: any): Promise<QueryResult<any>> => {
        const { liveEventId } = this.props;
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`liveEvent/${liveEventId}/versions`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.entities.map((entity: Entities.LeaderBoardVersion) => ({
                version: (
                    <Link to={`/leaderBoard/${entity.name}/details`} onClick={() => this.selectVersion(entity.version)}>
                        {entity.version}
                    </Link>
                ),
                start: entity.start && formatDate(entity.start),
                end: entity.end && formatDate(entity.end),
                entity
            })
        );

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private showRegularTable = () => {
        const { details } = this.props;
        if (!details) {
            return;
        }

        const columns = [
            { title: 'Player', field: 'player', render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => this.getPlayerView(rowData)},
            { title: 'Joined', field: 'joined', render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => formatDate(rowData.joined) },
            { title: 'Value', field: 'statValue', render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => formatNumber(rowData.statValue) },
            { title: 'Stat', field: 'statName'},
            { title: 'Extra Data', field: 'extraData', sorting: false, render: (rowData: Entities.LiveEventPlayerLeaderBoardEntry) => parseJson(rowData.extraData, this.onExtraDataSelected)},
        ];
        const ended = isEnded(details.endTimestamp);
        if(ended) {
            columns.push({ title: 'Finished', field: 'finished', render: (rowData: any) => formatDate(rowData.finished)});
            columns.push({ title: 'Rewards Collected', field: 'rewardCollected', render: (rowData: any) => formatDate(rowData.rewardCollected)});
        }

        return (
            <Table
                tableRef={this.tableRef}
                title={'Players'}
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                }}
                data={this.getEventRegularPlayers}
            />
        );
    };

    private changeLeaderBoardView = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ leaderBoardView: event.target.checked });
    };

    private showLeaderBoardTypeView = () => {
        const { details } = this.props;
        if (!details) {
            return;
        }
        const { leaderBoardView } = this.state;

        return (
            <Grid container={true} justifyContent="center" spacing={1}>
                <Grid size={12}>
                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: details.timerType !== TimerType.Recurring ? 'flex-end' : 'flex-start'}}>
                        <Typography variant="caption" style={{margin: 'auto'}}>Players View</Typography>
                        <Switch
                            checked={leaderBoardView}
                            onChange={this.changeLeaderBoardView}
                            color="primary"
                            name="checkedLeaderBoardView"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" style={{margin: 'auto'}}>LeaderBoards View</Typography>
                    </div>
                </Grid>
                <Grid size={12}>
                    <div style={{ margin: 0, width: '100%' }}>
                        {leaderBoardView && this.showLeaderBoardTable()}
                        {!leaderBoardView && this.showRegularTable()}
                    </div>
                </Grid>
            </Grid>
        );
    };

    private showLeaderBoardTable = () => {
        const { details } = this.props;
        if (!details) {
            return;
        }

        return (
            <>
                <Table
                    title="LeaderBoards"
                    tableRef={this.tableRef}
                    data={this.getLeaderBoardEntities}
                    columns={[
                        { title: 'Name', field: 'statisticName'},
                        { title: 'Players Count', field: 'playersCount', sorting: false}
                    ]}
                    options={{
                        selection: false,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 30, 50],
                        emptyRowsWhenPaging: false,
                        search: false,
                        sorting: true,
                        draggable: false,
                    }}
                    detailPanel={this.getLeaderBoardEntitiesPanel}
                />
                {details.groupLeaderBoardName && (
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Group LeaderBoard
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Table
                                columns={[
                                    { title: 'Position', field: 'position'},
                                    { title: 'Record', field: 'record'},
                                    { title: 'Value', field: 'value'},
                                ]}
                                showBox={false}
                                options={{
                                    showTitle: false,
                                    selection: false,
                                    paging: true,
                                    pageSize: 10,
                                    pageSizeOptions: [5, 10, 30, 50],
                                    emptyRowsWhenPaging: false,
                                    search: false,
                                    sorting: false,
                                    draggable: false,
                                }}
                                data={this.getGroupLeaderBoardMembers}
                            />
                        </StyledAccordionDetails>
                    </StyledAccordion>
                )}
            </>
        );
    };

    private getEventRegularPlayers = async (query: any) => {
        const { liveEventId } = this.props;
        const { details, currentVersion } = this.props;
        if (!details) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { page, pageSize, orderBy, orderDirection } = query;
        const version = this.state.version || currentVersion || 1;
        const result = await ActionRequest.get(
            `liveEvent/${liveEventId}/getRegularPlayers`,
            {
                version,
                page: page + 1,
                perPage: pageSize,
                orderBy: orderBy ? orderBy.field : null,
                orderDirection: orderDirection !== "" ? orderDirection : null,
            },
        );
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        return {
            data: result.players,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getLeaderBoardEntities = async (query: any) => {
        const { leaderBoardDefinition, currentVersion } = this.props;
        const { liveEventId } = this.props;
        if (!leaderBoardDefinition) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { search, page, pageSize, orderBy, orderDirection } = query;
        const version = this.state.version || currentVersion || 1;
        const result = await ActionRequest.get(
            `liveEvent/${liveEventId}/getLeaderBoardEntities`,
            {
                version,
                filter: search,
                page: page + 1,
                perPage: pageSize,
                orderBy: orderBy ? orderBy.field : null,
                orderDirection: orderDirection !== "" ? orderDirection : null,
            },
        );
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        this.setState({ countOfLeaderBoards: result.totalCount });

        const maxUsersPerInstance = leaderBoardDefinition ? leaderBoardDefinition.maxUsersPerInstance : 0;
        const data = result.statistics.map( (statistic: any) => {
            return {
                statisticName: getLeaderBoardLink(statistic.name),
                statistic,
                playersCount: `${statistic.playersCount}/${maxUsersPerInstance}`,
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getLeaderBoardEntitiesPanel = (rowData: any) => {
        const columns = [
            { title: 'Position', field: 'position'},
            { title: 'Player', field: 'player'},
            { title: 'Value', field: 'value'},
        ];

        const getData = (query: any) => this.getLeaderBoardRanking(rowData.statistic.name, query);

        return (
            <Table
                tableRef={this.tableRef}
                title={
                    <Typography variant="subtitle1" style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', fontSize: '1rem'}}>
                        Rankings
                    </Typography>
                }
                backgroundColor={'secondary'}
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: false,
                    sorting: false,
                    draggable: false,
                }}
                data={getData}
            />
        );
    };

    private getLeaderBoardRanking = async (name: string, query: any) => {
        const { page, pageSize } = query;

        const splitNames = name.replace('live_event_', '').split('_');
        const eventId = splitNames[0];
        const result = await ActionRequest.get<Entities.LeaderBoardListByPageResponse>(`liveEvent/${eventId}/getLeaderBoardRanking`, { statisticName: name, page: page + 1, perPage: pageSize });

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.records.map((record: any) => {
            return {
                position: `#${record.position+1}`,
                player: this.getPlayerView(record),
                value: formatNumber(record.value),
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getPlayerView = (leaderBoardInfo: Entities.LiveEventPlayerLeaderBoardEntry) => {
        return (
            <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                {leaderBoardInfo.profile.AvatarUrl === '' && (
                    <>
                        <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                            <Circle style={{ color: leaderBoardInfo.profile.Online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                            {getPlayerLink(leaderBoardInfo.profile.PlayerId)}
                        </Grid>
                        <Grid size={12}>
                            {leaderBoardInfo.profile.DisplayName}
                        </Grid>
                    </>
                )}
                {leaderBoardInfo.profile.AvatarUrl !== '' && (
                    <>
                        <Grid size={2}>
                            <CardMedia image={leaderBoardInfo.profile.AvatarUrl} style={{width: 40, height: 40, borderRadius: 0, border: `1px solid #c3cfdd`}}/>
                        </Grid>
                        <Grid size={10}>
                            <Grid container={true} justifyContent="center" spacing={0}>
                                <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                                    <Circle style={{ color: leaderBoardInfo.profile.Online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                                    {getPlayerLink(leaderBoardInfo.profile.PlayerId)}
                                </Grid>
                                <Grid size={12}>
                                    {leaderBoardInfo.profile.DisplayName}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private getGroupLeaderBoardMembers = async (query: any) => {
        const { details } = this.props;
        const { page, pageSize } = query;

        if (!details) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const result: Entities.LeaderBoardListByPageResponse | null = await ActionRequest.get(`leaderboard/${details.groupLeaderBoardName}/1/members`, { page: page + 1, perPage: pageSize });

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.records.map((record: any) => {
            return {
                position: `#${record.position+1}`,
                record: (
                    <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                        <Grid size={12}>
                            {getGroupLink(record.groupId)}
                        </Grid>
                        <Grid size={12}>
                            {record.groupName}
                        </Grid>
                    </Grid>
                ),
                value: formatNumber(record.value),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getStatus = (liveEvent: Entities.LiveEvent) => {
        if (isActive(liveEvent.startTimestamp, liveEvent.endTimestamp)) {
            return <div style={{color: 'green'}}>In Progress</div>;
        }
        if (isEnded(liveEvent.endTimestamp)) {
            return <div style={{color: 'orange'}}>Completed</div>;
        }
        return <div style={{color: 'grey'}}>Scheduled</div>;
    };

    private onExtraDataSelected = (extraDataSelected: string) => {
        if (extraDataSelected !== '') {
            this.setState({ openDialog: 'ExtraData', extraDataSelected });
        }
    };

    private renderExtraDataDialog = () => {
        const { openDialog, extraDataSelected } = this.state;
        return (
            <JsonDialog
                open={openDialog === 'ExtraData'}
                title="Extra Data"
                value={extraDataSelected}
                onClose={this.closeDialog}
            />
        );
    };

    private closeDialog = () =>
        this.setState({
            openDialog: null,
            extraDataSelected: '',
        });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    liveEventId: '',
    details: state.liveEvent.details,
    currentVersion: state.liveEvent.currentVersion,
    leaderBoardDefinition: state.liveEvent.leaderBoardDefinition,
    playersCount: state.liveEvent.playersCount,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const liveEventId = params.liveEventId || '';
    return (<Container {...props} navigate={navigate} liveEventId={liveEventId}/>);
};
export default connect(mapStateToProps)(AppContainer);
