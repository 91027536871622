import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import { CardMedia, Grid2 as Grid, Switch, Typography } from '@mui/material';
import {
    formatDate,
    formatNumber,
    getLeaderBoardLink,
    getPlayerLink,
    isActive,
    isEnded,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    parseJson,
} from '../utils';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { BaseContainer, BaseElement, JsonDialog, Metadata, Table } from '../components';
import { SeasonType } from '../enums';
import {
    BarChartOutlined,
    Circle,
    EventOutlined,
    ExpandMore,
    GamesOutlined as GamesIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly seasonId: string;
    readonly season?: Entities.Season;
    readonly leaderBoardDefinition?: Entities.EntityLeaderBoard;
}

type DialogType = 'ExtraData';
interface State {
    readonly openDialog: DialogType | null;
    readonly extraDataSelected: string;
    readonly players: Entities.SeasonPlayer[];
    readonly leaderBoardView: boolean;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        extraDataSelected: '',
        players: [],
        leaderBoardView: false,
    };

    async componentDidMount() {
        const { seasonId } = this.props;
        await actions.season.get(seasonId);
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Season',
                to: `/season/all`,
            },
            {
                text: this.props.seasonId,
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {'Season Details'}
                TitleIcon = {GamesIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderExtraDataDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { season } = this.props;
        if (!season) {
            return <></>;
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    {this.renderDetails()}
                </Grid>
                <Grid size={12}>
                    {this.renderMetadata()}
                </Grid>
                <Grid size={12}>
                    {this.renderTiers()}
                </Grid>
                <Grid size={12}>
                    {this.playersTable()}
                </Grid>
            </Grid>
        );
    };

    private renderDetails = () => {
        const { season, leaderBoardDefinition } = this.props;
        if (!season) {
            return <></>;
        }

        let type: React.JSX.Element = (<></>);
        switch (season.type) {
            case SeasonType.Regular:
                type = <> <EventOutlined style={{width: 20, height: 20}}/> <div>Regular </div> </>;
                break;
            case SeasonType.LeaderBoard:
                type = <> <BarChartOutlined style={{width: 20, height: 20}}/> <div>LeaderBoard</div> </>;
                break;
            default:
                break;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Details
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Grid container={true} justifyContent="center" spacing={1}>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Type</strong>
                        </Grid>
                        <Grid size={{ xs:12, sm:9 }} style={{ display: 'flex'}}>
                            {type}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Status</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {this.getStatus(season)}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Name</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {season.name}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Start at</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {formatDate(season.startTimestamp)}
                        </Grid>

                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>End at</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            <div>{formatDate(season.endTimestamp)}</div>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Edited by</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {season.username}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Edited at</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {formatDate(season.updated)}
                        </Grid>
                        {leaderBoardDefinition && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Max Users Per Instance</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {leaderBoardDefinition.maxUsersPerInstance}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderMetadata = () => {
        const { season } = this.props;
        if (!season) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={false}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Metadata
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Metadata
                        text={season.metadata}
                        readOnly={true}
                        style={{ width: '100%' }}
                        keyValueContainerStyle={{ boxShadow: 'none' }}
                        showBox={false}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    }

    private renderTiers = () => {
        const { season } = this.props;
        if (!season) {
            return;
        }

        const columns: any[] = [
            { title: 'Id', field: 'id'},
            { title: 'Name', field: 'name'},
            { title: 'Target', field: 'target'},
        ];
        return (
            <StyledAccordion defaultExpanded={false}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Tiers
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table
                        columns = {columns}
                        options = {{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: false,
                            draggable: false,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            exportButton: true,
                            exportAllData: true,
                            exportFileName: `season_${season.id}_tiers`,
                        }}
                        data = {this.getTiersData}
                        detailPanel={this.tierDetailPanel}
                        showBox={false}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getTiersData = async (query: any): Promise<QueryResult<any>> => {
        const { season } = this.props;
        if (!season) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : 'asc',
        };
        const result = await ActionRequest.get(`season/${season.id}/getTiers`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        return {
            data: result.entities,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private tierDetailPanel = (rowData: any) => {
        const columns: any[] = [
            { title: 'Key', field: 'key'},
            { title: 'Amount', field: 'amount'},
        ];

        return (
            <Grid container={true} justifyContent="center" spacing={2} style={{padding: '10px 20px'}}>
                {rowData.metadata !== '' && (
                    <Grid size={12}>
                        <StyledAccordion square={true} defaultExpanded={false}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />} >
                                Metadata
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Metadata
                                    text={rowData.metadata}
                                    showBox={false}
                                    readOnly={true}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
                {rowData.rewards.length > 0 && (
                    <Grid size={12}>
                        <StyledAccordion square={true} defaultExpanded={false}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />} >
                                {`Rewards (${rowData.rewards.length})`}
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Table
                                    columns = {columns}
                                    showBox={false}
                                    options = {{
                                        showTitle: false,
                                        selection: false,
                                        paging: false,
                                        emptyRowsWhenPaging: false,
                                        sorting: false,
                                        draggable: false,
                                        search: false,
                                    }}
                                    data = {rowData.rewards}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
            </Grid>
        );
    }

    private playersTable = () => {
        const { season } = this.props;
        if (!season) {
            return;
        }

        switch (season.type) {
            case SeasonType.Regular:
                return this.showRegularTable();
            case SeasonType.LeaderBoard:
                return this.showLeaderBoardTypeView();
            default:
                return;
        }
    };

    private showRegularTable = () => {
        const { season } = this.props;
        if (!season) {
            return;
        }

        const columns: any[] = [
            { title: 'Player', field: 'player', render: (leaderBoard: Entities.SeasonPlayerLeaderBoardEntry) => this.getPlayerView(leaderBoard)},
            { title: 'Joined', field: 'joined', render: (rowData: Entities.SeasonPlayerLeaderBoardEntry) => formatDate(rowData.joined) },
            { title: 'Value', field: 'statValue', render: (rowData: Entities.SeasonPlayerLeaderBoardEntry) => formatNumber(rowData.statValue) },
            { title: 'Stat', field: 'statName'},
            { title: 'Completed', field: 'completed', render: (rowData: Entities.SeasonPlayerLeaderBoardEntry) => formatDate(rowData.completed) },
            { title: 'Extra Data', field: 'extraData', sorting: false, render: (rowData: Entities.SeasonPlayerLeaderBoardEntry) => parseJson(rowData.extraData, this.onExtraDataSelected)},
        ];
        const ended = isEnded(season.endTimestamp);
        if(ended) {
            columns.push({ title: 'Finished', field: 'finished', render: (rowData: any) => formatDate(rowData.finished)});
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Players
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table
                        columns = {columns}
                        showBox={false}
                        options = {{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: true,
                            draggable: false,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                        }}
                        data = {this.getSeasonPlayers}
                        detailPanel={rowData => rowData.tiers.length > 0 ? this.getSeasonPlayerDetailPanel(rowData) : undefined}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getSeasonPlayers = async (query: any) => {
        const { seasonId } = this.props;
        const { page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get<Entities.GetSeasonPlayersResponse>(`season/${seasonId}/getRegularPlayers`, {
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        return {
            data: result.players,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getSeasonPlayerDetailPanel = (rowData: any) => {
        const { season } = this.props;
        if (!season) {
            return;
        }

        const columns: any[] = [
            { title: 'Tier Name', field: 'tierName'},
            { title: 'Progress', field: 'progress'},
            { title: 'Started', field: 'started'},
            { title: 'Completed', field: 'completed'},
            { title: 'RewardCollected', field: 'rewardCollected'},
        ];

        const data = rowData.tiers.map((t: any) => {
            const tierName = t.seasonTier.name
            const order = t.seasonTier.order;
            const target = t.seasonTier.target;
            return {
                tierName,
                order,
                progress: `${t.amount}/${target}`,
                started: t.startedTimestamp ? formatDate(t.startedTimestamp) : '',
                completedTimestamp: t.completedTimestamp ? formatDate(t.completedTimestamp) : '',
                rewardCollectedTimestamp: t.rewardCollectedTimestamp ? formatDate(t.rewardCollectedTimestamp) : '',
            };
        }).sort((t1: any, t2: any) => t1.order - t2.order);

        return (
            <div style={{ margin: 10 }}>
                <Table
                    data={data}
                    columns={columns}
                    options={{
                        toolbar: false,
                        selection: false,
                        paging: true,
                        emptyRowsWhenPaging: false,
                        search: false,
                        sorting: true,
                        draggable: false,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 30, 50],
                    }}
                />
            </div>
        );
    }

    private showLeaderBoardTypeView = () => {
        const { leaderBoardView } = this.state;

        return (
            <Grid container={true} justifyContent="center" spacing={1}>
                <Grid size={12}>
                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                        <Typography variant="caption" style={{margin: 'auto'}}>Players View</Typography>
                        <Switch
                            checked={leaderBoardView}
                            onChange={this.changeLeaderBoardView}
                            color="primary"
                            name="checkedLeaderBoardView"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" style={{margin: 'auto'}}>LeaderBoards View</Typography>
                    </div>
                </Grid>
                <Grid size={12}>
                    <>
                        {leaderBoardView && this.showLeaderBoardTable()}
                        {!leaderBoardView && this.showRegularTable()}
                    </>
                </Grid>
            </Grid>
        );
    };

    private showLeaderBoardTable = () => {
        const columns = [
            { title: 'Name', field: 'statisticName'},
            { title: 'Players Count', field: 'playersCount', sorting: false}
        ];

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    LeaderBoards
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table
                        data={this.getLeaderBoardEntities}
                        columns={columns}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: true,
                            draggable: false,
                        }}
                        detailPanel={this.getLeaderBoardEntitiesPanel}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getLeaderBoardEntities = async (query: any) => {
        const { season, leaderBoardDefinition } = this.props;
        const { seasonId } = this.props;
        if (!season || leaderBoardDefinition === null) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { search, page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get(
            `season/${seasonId}/getLeaderBoardEntities`,
            {
                filter: search,
                page: page + 1,
                perPage: pageSize,
                orderBy: orderBy ? orderBy.field : null,
                orderDirection: orderDirection !== "" ? orderDirection : null,
            },
        );

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        this.setState({ players: result.players });

        const maxUsersPerInstance = leaderBoardDefinition ? leaderBoardDefinition.maxUsersPerInstance : 0;
        const data = result.statistics.map( (statistic: any) => {
            return {
                statisticName: getLeaderBoardLink(statistic.name),
                playersCount: `${statistic.playersCount}/${maxUsersPerInstance}`,
                statistic,
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getLeaderBoardEntitiesPanel = (rowData: any) => {
        const columns = [
            { title: 'Position', field: 'position'},
            { title: 'Player', field: 'player'},
            { title: 'Value', field: 'value'},
        ];

        const getData = (query: any) => this.getLeaderBoardRanking(rowData.statistic.name, query);

        return (
            <div style={{ margin: 10 }}>
                <Table
                    title={
                        <Typography variant="subtitle1" style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', fontSize: '1rem'}}>
                            Rankings
                        </Typography>
                    }
                    backgroundColor={'secondary'}
                    columns={columns}
                    options={{
                        selection: false,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 30, 50],
                        emptyRowsWhenPaging: false,
                        search: false,
                        sorting: false,
                        draggable: false,
                    }}
                    data={getData}
                />
            </div>
        );
    };

    private getLeaderBoardRanking = async (name: any, query: any) => {
        const { page, pageSize } = query;

        const splitNames = name.replace('season_', '').split('_');
        const seasonId = splitNames[0];
        const result = await ActionRequest.get<Entities.LeaderBoardListByPageResponse>(`season/${seasonId}/getLeaderBoardRanking`, { statisticName: name, page: page + 1, perPage: pageSize });

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.records.map((record: any) => {
            return {
                position: `#${record.position+1}`,
                player: this.getPlayerView(record),
                value: formatNumber(record.value),
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private changeLeaderBoardView = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ leaderBoardView: event.target.checked });
    };

    private getStatus = (season: Entities.Season) => {
        if (isActive(season.startTimestamp, season.endTimestamp)) {
            return <div style={{color: 'green'}}>In Progress</div>;
        }
        if (isEnded(season.endTimestamp)) {
            return <div style={{color: 'orange'}}>Completed</div>;
        }
        return <div style={{color: 'grey'}}>Scheduled</div>;
    };

    private getPlayerView = (leaderBoardInfo: Entities.SeasonPlayerLeaderBoardEntry) => {
        return (
            <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                {leaderBoardInfo.profile.AvatarUrl === '' && (
                    <>
                        <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                            <Circle style={{ color: leaderBoardInfo.online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                            {getPlayerLink(leaderBoardInfo.profile.PlayerId)}
                        </Grid>
                        <Grid size={12}>
                            {leaderBoardInfo.profile.DisplayName}
                        </Grid>
                    </>
                )}
                {leaderBoardInfo.profile.AvatarUrl !== '' && (
                    <>
                        <Grid size={2}>
                            <CardMedia image={leaderBoardInfo.profile.AvatarUrl} style={{width: 40, height: 40, borderRadius: 0, border: `1px solid #c3cfdd`}}/>
                        </Grid>
                        <Grid size={10}>
                            <Grid container={true} justifyContent="center" spacing={0}>
                                <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                                    <Circle style={{ color: leaderBoardInfo.online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                                    {getPlayerLink(leaderBoardInfo.profile.PlayerId)}
                                </Grid>
                                <Grid size={12}>
                                    {leaderBoardInfo.profile.DisplayName}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private onExtraDataSelected = (extraDataSelected: string) => {
        if (extraDataSelected !== '') {
            this.setState({ openDialog: 'ExtraData', extraDataSelected });
        }
    };

    private renderExtraDataDialog = () => {
        const { openDialog, extraDataSelected } = this.state;
        return (
            <JsonDialog
                open={openDialog === 'ExtraData'}
                title="Extra Data"
                value={extraDataSelected}
                onClose={this.closeDialog}
            />
        );
    };

    private closeDialog = () =>
        this.setState({
            openDialog: null,
            extraDataSelected: '',
        });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    seasonId: '',
    season: state.season.season,
    leaderBoardDefinition: state.season.leaderBoardDefinition,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const seasonId = params.seasonId || '';
    return (<Container {...props} navigate={navigate} seasonId={seasonId}/>);
};
export default connect(mapStateToProps)(AppContainer);
