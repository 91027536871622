import { Action } from './index';
import { TitleActionType, AppActionType, LeaderBoardActionType } from '../enums';

export interface LeaderBoardState {
    readonly leaderboard?: Entities.LeaderBoard;
}

export type LeaderBoardAction = Action<Partial<LeaderBoardState>>;

const initState: LeaderBoardState = {
};

const reducer = (
    state: LeaderBoardState = initState,
    action: LeaderBoardAction,
): LeaderBoardState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case LeaderBoardActionType.Update:
            return { ...state, ...action.payload };
        case TitleActionType.Reset:
            return { ...state };
    }
    return state;
};
export default reducer;