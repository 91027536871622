import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { ContainerContext, mapProps } from './';
import {
    WarningDialog,
    CreateCompensationBundleDialog,
    Table,
    UpdateCompensationBundleDialog,
    BaseCostumerSupportCompensationContainer,
    BaseElement,
    HeaderButton,
} from '../components';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    LoyaltyOutlined as LoyaltyIcon,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { formatDate } from '../utils';
import { UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
}

type DialogType = 'Create' | 'Edit' |'ConfirmDelete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedCompensationData: Entities.CompensationData | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedCompensationData: null
    };

    async componentDidMount() {
        await actions.costumerSupport.listCompensationPreDefineItems();
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Create', icon: AddOutlined, onClick: this.openNewDialog},
        ];
        return (
            <BaseCostumerSupportCompensationContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                buttons = {buttons}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BaseCostumerSupportCompensationContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderNewDialog()}
                {this.renderDescriptionDialog()}
                {this.renderConfirmDeleteDialog()}
            </>
        );
    }

    private renderDescriptionDialog() {
        const { preDefinedItems } = this.props;
        const { openDialog, selectedCompensationData } = this.state;
        if (!selectedCompensationData) {
            return;
        }
        return (
            <UpdateCompensationBundleDialog
                open={openDialog === 'Edit'}
                title={'Update Compensation Bundle'}
                TitleIcon={LoyaltyIcon}
                selectedCompensationData={selectedCompensationData}
                preDefinedItems={preDefinedItems}
                onClose={this.closeDialog}
                onUpdate={this.onUpdateCompensationData}
            />
        );
    }

    private onUpdateCompensationData = async (title: string, description: string, from: number, to:number, allUsers:boolean, userList: string, itemList: string) => {
        const { selectedCompensationData } = this.state;
        
        if (!selectedCompensationData) {
            return;
        }
        
        await actions.compensationData.update(selectedCompensationData.compensationId, title, description, from, to, allUsers, userList, itemList);
        this.closeDialog();

    };
    
    private renderNewDialog = () => {
        const { preDefinedItems } = this.props;
        const { openDialog } = this.state;
        return <CreateCompensationBundleDialog
                open={openDialog === 'Create'}
                title={'Create Compensation Bundle'}
                TitleIcon={LoyaltyIcon}
                preDefinedItems={preDefinedItems}
                onClose={this.closeDialog}
                onCreate={this.onCreateCompensationData}
            />;
    };
    
    private closeDialog = () => {
        this.setState({
            openDialog: null,
            selectedCompensationData: null,
        });
    };

    private onCreateCompensationData = async (title: string, description: string, from: number, to:number, allUsers:boolean, userList: string, itemList: string) => {
        await actions.compensationData.create(title, description, from, to, allUsers, userList, itemList);
        this.closeDialog();
    };

    private openNewDialog = () => {
        this.setState({ openDialog: 'Create' });
    };

    private editTitleNew = (compensationData: Entities.CompensationData) => this.setState({openDialog: 'Edit', selectedCompensationData: compensationData});
    
    private openDeleteDialog = (compensationData: Entities.CompensationData) => this.setState({openDialog: 'ConfirmDelete', selectedCompensationData: compensationData});

    private renderConfirmDeleteDialog = () => {
        const { openDialog } = this.state;
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDelete'}
                title={'Confirm Delete'}
                TitleIcon={LoyaltyIcon}
                content="This will permanently delete the conpensation."
                onClose={this.closeDialog}
                onSubmit={this.deleteCompensationData}
                maxWidth={'xs'}
            />
        );
    };

    private deleteCompensationData = async () => {
        const { selectedCompensationData } = this.state;

        this.closeDialog();
        if (!selectedCompensationData) {
            return;
        }

        await actions.compensationData.remove(selectedCompensationData.compensationId);
    };

    private renderContent = () => {
        const columns = [
            { title: 'Title', field: 'title'},
            { title: 'from', field: 'from'},
            { title: 'to', field: 'to'},
            { title: 'All Users', field: 'allUsers'},
        ];

        return (
            <Table
                columns={columns}
                data={this.getData}
                options={{
                    showTitle: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                }}
                actions={[
                    rowData => ({
                        icon: ModeEditOutlineOutlined,
                        tooltip: 'Edit',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.editTitleNew(rowData.compensationData)
                    }),
                    rowData => ({
                        icon: DeleteOutlineOutlined,
                        tooltip: 'Delete',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.openDeleteDialog(rowData.compensationData)
                    }),
                ]}
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`compensation/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.CompensationData) => {
            return {
                title: e.title,
                from: formatDate(e.from),
                to: formatDate(e.to),
                allUsers: e.allUsers,
                compensationData: e
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    preDefinedItems: state.costumerSupport.preDefinedItems,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
