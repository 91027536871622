import * as React from 'react';
import { connect } from 'react-redux';
import { BaseContainer, BaseElement, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import { Grid2 as Grid } from '@mui/material';
import moment from 'moment';
import { ActionRequest } from '../actions';
import { localeCurrencyNumber, localeNumber } from '../utils';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly selected?: Entities.UserTitleData;
}

class Container extends BaseElement<Props> {
    state = {
    };

    protected renderContainer(): React.JSX.Element {
        return (
            <BaseContainer
                themeMode={this.props.app.themeMode}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        return (
            <Grid container={true} justifyContent="center" spacing={2} style={{ margin: '20px auto' }}>
                <Grid size={12}>
                    {this.showReports()}
                </Grid>
            </Grid>
        );
    };

    private showReports = () => {
        const { themeMode } = this.props.app;
        return (
            <Table
                columns={[
                    {
                        title: 'Report',
                        field: 'title',
                        headerStyle: {
                            textTransform: 'uppercase',
                        },
                        cellStyle: {
                            fontWeight: 500,
                        }
                    },
                    { title: 'TODAY', field: 'today' },
                    { title: 'YESTERDAY', field: 'yesterday' },
                ]}
                options={{
                    selection: false,
                    paging: false,
                    emptyRowsWhenPaging: false,
                    search: false,
                    sorting: false,
                    draggable: false,
                    showTitle: false,
                    toolbar: false,
                    headerStyle: {
                        borderBottom: themeMode === 'light' ? `1px solid #e9ebee` : '1px solid #323337',
                    }
                }}
                data={this.getReports}
                cellStyle={{
                    padding: '16px 10px',
                    fontSize: 14,
                    borderBottom: 'none',
                    textAlign: 'center',
                }}
            />
        );
    };

    private getReports = async (query: any) => {
        const today = moment().startOf('day').valueOf();
        const yesterdayStart = moment().subtract(1, 'day').startOf('day').valueOf();
        const yesterdayEnd = moment().subtract(1, 'day').endOf('day').valueOf();
        const result = await ActionRequest.get<Entities.GetOverviewReportsResponse>(`titleConfig/getOverviewReports`, {
            today,
            yesterdayStart,
            yesterdayEnd,
        });

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = [
            {
                title: 'Unique Users',
                today: localeNumber(result.todayReport.uniqueUsers), yesterday: localeNumber(result.yesterdayReport.uniqueUsers) },
            { title: 'New Users', today: localeNumber(result.todayReport.newUsers), yesterday: localeNumber(result.yesterdayReport.newUsers) },
            { title: 'Purchases', today: localeCurrencyNumber(result.todayReport.purchases), yesterday: localeCurrencyNumber(result.yesterdayReport.purchases) },
            { title: 'Spenders', today: localeNumber(result.todayReport.spenders), yesterday: localeNumber(result.yesterdayReport.spenders) },
            { title: 'ARPU', today: localeCurrencyNumber(result.todayReport.arpu), yesterday: localeCurrencyNumber(result.yesterdayReport.arpu) },
            { title: 'ARPPU', today: localeCurrencyNumber(result.todayReport.arppu), yesterday: localeCurrencyNumber(result.yesterdayReport.arppu) },
        ];
        return {
            data,
            page: 0,
            totalCount: 0,
        };
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    selected: state.title.selectedTitle,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate} />);
};
export default connect(mapStateToProps)(AppContainer);
