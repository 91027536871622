import * as React from 'react';
import { default as moment } from 'moment';
import { formatMomentDate, getDurationValueFromTimeStamp, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../utils';
import { Chip, Grid2 as Grid, InputLabel } from '@mui/material';
import { Metadata, LabelCheckBox, TextField, NumberField } from '../common';
import { SeasonTiers } from './SeasonTiers';
import { BaseCreateSeasonComponent, CreateSeasonProps } from './BaseCreateSeasonComponent';
import { DeveloperModeOutlined, ExpandMore } from '@mui/icons-material';

interface Props extends CreateSeasonProps {
    readonly onCreate: (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], maxAmountPerReport: number) => void;
}

export class CreateSeasonRegularComponent extends BaseCreateSeasonComponent<Props> {
    state = this.initState();

    protected updateFromOld() {
        const { oldSeason, type} = this.props;
        if (!oldSeason) {
            return;
        }

        const { name, startTimestamp, endTimestamp, metadata, tiers, developerOnly } = oldSeason;
        if (type === 'Create') {
            this.setState({
                name: `${name} (Copy)`,
                metadata,
                tiers: tiers ?? [],
                developerOnly,
            });
            return;
        }
        this.setState({
            name,
            start: moment(startTimestamp),
            duration: getDurationValueFromTimeStamp(startTimestamp, endTimestamp),
            metadata,
            tiers: tiers ?? [],
            developerOnly,
        });
    }

    protected renderDetailContent(): React.JSX.Element {
        const { name, metadata, maxAmountPerReport, developerOnly } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={8} style={{ margin: 'auto' }}>
                    {developerOnly && (
                        <Chip
                            icon={<DeveloperModeOutlined />}
                            size='medium'
                            label='Developer Only'
                            style={{ backgroundColor: '#21BA47', color: 'white' }}
                        />
                    )}
                </Grid>
                <Grid size={4} alignContent={'end'} >
                    <LabelCheckBox
                        label="Developer Only"
                        labelSize={'14px'}
                        icon={DeveloperModeOutlined}
                        labelPlacement={'start'}
                        checked={developerOnly}
                        justifyContent={'flex-end'}
                        onChange={this.handleChangeDeveloperOnly}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="live-event-name"
                        label=""
                        value={name}
                        onChange={this.onUpdateName}
                        fullWidth={true}
                        required={true}
                        variant="outlined"
                    />
                </Grid>
                {this.renderTimerCreation()}
                <Grid size={12}>
                    <InputLabel>Max Amount Per Report</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        label=""
                        value={maxAmountPerReport}
                        onChange={this.handleMaxAmountPerReport}
                        fullWidth={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Metadata
                        text={metadata}
                        onChange={this.handleChangeMetadata}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderConfirmContent(): React.JSX.Element {
        const {
            name,
            start,
            duration,
            metadata,
            tiers,
            useDuration,
            developerOnly
        } = this.state;
        let { end } = this.state;
        if (useDuration) {
            end = start.clone().add(duration, 'minutes');
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                {developerOnly && (
                                    <Grid size={12}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<DeveloperModeOutlined />}
                                            size='small'
                                            label='Developer Only'
                                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                                        />
                                    </Grid>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Start</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatMomentDate(start)}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>End</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatMomentDate(end)}
                                </Grid>
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {metadata !== '' && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Metadata
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Metadata
                                    text={metadata}
                                    readOnly={true}
                                    style={{ width: '100%' }}
                                    keyValueContainerStyle={{ boxShadow: 'none' }}
                                    showBox={false}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
                {tiers.length > 0 && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={false}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Tiers ({tiers.length})
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <SeasonTiers
                                    tiers={tiers}
                                    readOnly={true}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
            </Grid>
        );
    }

    protected onCreate = () => {
        const { name, start, end, duration, metadata, developerOnly, tiers, useDuration, maxAmountPerReport } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        if (useDuration) {
            this.props.onCreate(name, start.utc().valueOf(), start.add(duration, 'minutes').utc().valueOf(), metadata, developerOnly, tiers, maxAmountPerReport);
        } else {
            this.props.onCreate(name, start.utc().valueOf(), end.utc().valueOf(), metadata, developerOnly, tiers, maxAmountPerReport);
        }
    };
}
