import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid } from '@mui/material';
import { Button, LabelCheckBox, Table } from '../common';
import * as React from 'react';
import { ActionRequest } from '../../actions';
import { formatDate, getPlayerDetails, localeCurrencyNumber } from '../../utils';

interface Props extends BaseDialogProps {
    readonly onCopyPlayer: (
        player: Entities.Player,
        copyPlayerData: boolean,
        copyInventory: boolean,
        copyWallet: boolean,
        copyStats: boolean,
        copyLiveEvents: boolean,
        copySeasons: boolean,
        copyMatchmakingProperties: boolean,
        copyAchievements: boolean,
        copyPurchases: boolean
    ) => void;
}

interface State extends BaseDialogState {
    readonly player: Entities.Player | null;
    readonly copyPlayerData: boolean;
    readonly copyInventory: boolean;
    readonly copyWallet: boolean;
    readonly copyStats: boolean;
    readonly copyLiveEvents: boolean;
    readonly copySeasons: boolean;
    readonly copyMatchmakingProperties: boolean;
    readonly copyAchievements: boolean;
    readonly copyPurchases: boolean;
}

export class CopyPlayerDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const tableRef = React.createRef();
        const columns = [
            { title: 'Details', field: 'details', sorting: false },
            { title: 'Last Login', field: 'lastLogin', render: (rowData: any) => formatDate(rowData.lastLogin) },
            { title: 'Client Version', field: 'clientVersion' },
            { title: 'VTD', field: 'valueToDateUSD', render: (rowData: any) => localeCurrencyNumber(rowData.valueToDateUSD, 'USD') },
        ];

        const {
            player,
            copyPlayerData,
            copyInventory,
            copyWallet,
            copyStats,
            copyLiveEvents,
            copySeasons,
            copyMatchmakingProperties,
            copyAchievements,
            copyPurchases,
        } = this.state;

        const allSelected = copyPlayerData && copyInventory && copyWallet && copyStats &&
            copyLiveEvents && copySeasons && copyMatchmakingProperties && copyAchievements && copyPurchases;

        return (
            <Grid container={true} spacing={1}>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Select All"
                        checked={allSelected}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleSelectAll}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Player Data"
                        checked={copyPlayerData}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyPlayerData}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Inventory"
                        checked={copyInventory}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyInventory}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Wallet"
                        checked={copyWallet}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyWallet}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Copy Stats"
                        checked={copyStats}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyStats}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Live Events"
                        checked={copyLiveEvents}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyLiveEvents}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Seasons"
                        checked={copySeasons}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopySeasons}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Matchmaking Properties"
                        checked={copyMatchmakingProperties}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyMatchmakingProperties}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Achievements"
                        checked={copyAchievements}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyAchievements}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:4, md:3 }}>
                    <LabelCheckBox
                        label="Purchases"
                        checked={copyPurchases}
                        labelPlacement={'end'}
                        justifyContent={'flex-start'}
                        onChange={this.handleCopyPurchases}
                    />
                </Grid>
                <Grid size={12}>
                    <Table
                        tableRef={tableRef}
                        columns={columns}
                        onRowClick={this.onRowClick}
                        showBox={false}
                        options={{
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: true,
                            draggable: false,
                            showTitle: false,
                            rowStyle: rowData => ({
                                backgroundColor: (player !== null && player.playerId === rowData.playerId) ? '#0000000a' : 'transparent'
                            })
                        }}
                        data={this.getPlayers}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Copy From Player" onClick={this.onSubmit} variant="contained" disabled={!this.validate()} />
        );
    }

    private onRowClick = (event: any, rowData: any) => {
        this.setState({ player: rowData });
    };

    protected handleSelectAll = (selected: boolean) => this.setState({
        copyPlayerData: selected,
        copyInventory: selected,
        copyWallet: selected,
        copyStats: selected,
        copyLiveEvents: selected,
        copySeasons: selected,
        copyMatchmakingProperties: selected,
        copyAchievements: selected,
        copyPurchases: selected,
    });

    protected handleCopyPlayerData = (copyPlayerData: boolean) => this.setState({ copyPlayerData });

    protected handleCopyInventory = (copyInventory: boolean) => this.setState({ copyInventory });

    protected handleCopyWallet = (copyWallet: boolean) => this.setState({ copyWallet });

    protected handleCopyStats = (copyStats: boolean) => this.setState({ copyStats });

    protected handleCopyLiveEvents = (copyLiveEvents: boolean) => this.setState({ copyLiveEvents });

    protected handleCopySeasons = (copySeasons: boolean) => this.setState({ copySeasons });

    protected handleCopyMatchmakingProperties = (copyMatchmakingProperties: boolean) => this.setState({ copyMatchmakingProperties });

    protected handleCopyAchievements = (copyAchievements: boolean) => this.setState({ copyAchievements });

    protected handleCopyPurchases = (copyPurchases: boolean) => this.setState({ copyPurchases });

    private onSubmit = () => {
        const {
            player,
            copyPlayerData,
            copyInventory,
            copyWallet,
            copyStats,
            copyLiveEvents,
            copySeasons,
            copyMatchmakingProperties,
            copyAchievements,
            copyPurchases,
        } = this.state;
        if (player === null) {
            return;
        }
        this.onClose();
        this.props.onCopyPlayer(
            player,
            copyPlayerData,
            copyInventory,
            copyWallet,
            copyStats,
            copyLiveEvents,
            copySeasons,
            copyMatchmakingProperties,
            copyAchievements,
            copyPurchases,
        );
    }

    private validate(): boolean {
        return this.state.player !== null;
    }

    private getPlayers = async (query: any) => {
        const { search, page, pageSize, orderBy, orderDirection } = query;

        const result = await ActionRequest.get(`players`, {
            name: search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.players.map((p: any) => {
            return {
                details: getPlayerDetails(p),
                ...p,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    protected initState(): State {
        return {
            player: null,
            copyPlayerData: true,
            copyInventory: true,
            copyWallet: true,
            copyStats: true,
            copyLiveEvents: true,
            copySeasons: true,
            copyMatchmakingProperties: true,
            copyAchievements: true,
            copyPurchases: true,
        };
    }
}
