import { RecurringResetTimerType } from '../enums';
import moment from 'moment';
import { daysMenus, DaysMenuType, formatDuration, formatMomentDate } from './index';

export class RecurringEntity {
    recurringEntity: Entities.TimerEntity;

    constructor(recurringEntity: Entities.TimerEntity) {
        this.recurringEntity = recurringEntity;
    }

    public getStartText(): string {
        const { recurringStart } = this.recurringEntity;
        const timerRecurringStart = recurringStart !== null ? moment.utc().startOf('day').add(recurringStart, 'milliseconds').local() : moment(0);
        return formatMomentDate(timerRecurringStart, 'LT')
    }

    public getDurationText(): string {
        const { recurringDuration } = this.recurringEntity;
        return recurringDuration ? formatDuration(recurringDuration, true) : '';
    }

    public getRecurringText(): string {
        const { recurringWeeklyDayStart, recurringResetTimerType } = this.recurringEntity;
        let dayName = '';
        Object.keys(daysMenus).forEach(key => {
            const dayMenuType = key as DaysMenuType;
            if (daysMenus[dayMenuType].value === recurringWeeklyDayStart) {
                dayName = daysMenus[dayMenuType].title;
            }
        });

        if (recurringResetTimerType === RecurringResetTimerType.Daily) {
            return `Daily at ${this.getStartText()} for ${this.getDurationText()}`;
        }
        if(recurringResetTimerType === RecurringResetTimerType.Weekly) {
            return `Weekly ${dayName} at ${this.getStartText()} for ${this.getDurationText()}`;
        }
        return '';
    }
}