import * as React from 'react';
import {
    formatDate,
    formatNumber,
    getGroupLink,
    getPlayerLink,
    Link,
    RecurringEntity,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../utils';
import { ContainerContext, mapProps } from './index';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import { BaseContainer, BaseElement, Table } from '../components';
import { CardMedia, Grid2 as Grid, Typography } from '@mui/material';
import { ExpandMore, PollOutlined as PollIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';
import { LeaderboardType, TimerType } from '../enums';

interface Props extends ContainerContext.Props {
    readonly name: string;
    readonly leaderboard?: Entities.LeaderBoard;
}

interface State {
    readonly version: number | null;
}

class Container extends BaseElement<Props, State> {
    state = {
        version: null,
    }

    private membersTableRef: any = React.createRef();

    async componentDidMount() {
        const { name } = this.props;
        if (name.includes('live_event_') || name.includes('season_')) {
            return;
        }
        await actions.leaderboard.get(name);
    }

    protected renderContainer(): React.JSX.Element {
        const { name } = this.props;
        const headlines = [
            {
                text: 'LeaderBoard',
                to: `/leaderBoard/all`,
            },
            {
                text: name,
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title={"LeaderBoard Detail"}
                TitleIcon = {PollIcon}
                headlines={headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { name, leaderboard } = this.props;
        if (name.includes('live_event_') || name.includes('season_')) {
            return this.renderMembersTable();
        }

        if (!leaderboard) {
            return;
        }

        if (leaderboard.timerType !== TimerType.Recurring) {
            return (
                <Grid container={true} justifyContent="center" spacing={3}>
                    <Grid size={12}>
                        {this.renderDetails()}
                    </Grid>
                    <Grid size={12}>
                        {this.renderMembersTable()}
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container={true} justifyContent="center" spacing={3}>
                <Grid size={12}>
                    {this.renderDetails()}
                </Grid>
                <Grid size={{ xs:12, sm:7 }}>
                    {this.renderMembersTable()}
                </Grid>
                <Grid size={{ xs:12, sm:5 }}>
                    {this.renderVersionsTable()}
                </Grid>
            </Grid>
        );
    };

    private renderDetails = () => {
        const { leaderboard } = this.props;
        if (!leaderboard) {
            return <></>;
        }

        const recurringEntity = new RecurringEntity(leaderboard);

        let start: string = '';
        let end: string = '';

        switch (leaderboard.timerType) {
            case TimerType.None:
                break;
            case TimerType.Scheduled:
                start = formatDate(leaderboard.startTimestamp);
                end = formatDate(leaderboard.endTimestamp);
                break;
            case TimerType.Recurring:
                start = formatDate(leaderboard.startTimestamp, 'LL');
                end = formatDate(leaderboard.endTimestamp, 'LL');
                break;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Details
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Grid container={true} justifyContent="center" spacing={1}>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Type</strong>
                        </Grid>
                        <Grid size={{ xs:12, sm:9 }} style={{ display: 'flex', alignItems: 'center'}}>
                            {leaderboard.type}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Name</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {leaderboard.name}
                        </Grid>
                        {leaderboard.startTimestamp && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Start at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {start}
                                </Grid>
                            </>
                        )}
                        {leaderboard.endTimestamp && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>End at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {end}
                                </Grid>
                            </>
                        )}
                        {leaderboard.timerType === TimerType.Recurring && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Recurring</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {recurringEntity.getRecurringText()}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    }

    private renderVersionsTable = () => {
        const { app, leaderboard }  = this.props;
        const version = this.state.version || leaderboard?.currentVersion || 1;
        const columns = [
            { title: 'Version', field: 'version'},
            { title: 'Start', field: 'start'},
            { title: 'End', field: 'end'},
        ];

        const onRowClick = (event: any, rowData: any) => this.selectVersion(rowData.entity.version);

        return (
            <Table
                title={
                    <Typography variant="subtitle1" style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', fontSize: '1rem'}}>
                        Versions
                    </Typography>
                }
                data={this.getVersions}
                onRowClick={onRowClick}
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: false,
                    sorting: false,
                    draggable: false,
                    rowStyle: ((rowData, index, level) => {
                        if (rowData.entity.version === version) {
                            return {
                                backgroundColor: app.themeMode === 'light' ? '#f1faff' : '#3f3f3f'
                            };
                        }
                        return {
                            backgroundColor: 'transparent',
                        };
                    }),
                }}
            />
        );
    };

    private getVersions = async (query: any): Promise<QueryResult<any>> => {
        const { name } = this.props;
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`leaderboard/${name}/versions`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.entities.map((entity: Entities.LeaderBoardVersion) => ({
                version: (
                    <Link to={`/leaderBoard/${entity.name}/details`} onClick={() => this.selectVersion(entity.version)}>
                        {entity.version}
                    </Link>
                ),
                start: entity.start && formatDate(entity.start),
                end: entity.end && formatDate(entity.end),
                entity
            })
        );

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private selectVersion = (version: number) => {
        this.setState({ version });
        this.membersTableRef.current.onSearchChangeDebounce();
    }

    private renderMembersTable = () => {
        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Members
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Table
                        tableRef={this.membersTableRef}
                        columns={[
                            { title: 'Position', field: 'position'},
                            { title: 'Record', field: 'record'},
                            { title: 'Value', field: 'value'},
                        ]}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: false,
                            draggable: false,
                        }}
                        data={this.getMembers}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getMembers = async (query: any) => {
        const { name, leaderboard } = this.props;
        const version = this.state.version || leaderboard?.currentVersion || 1;
        const { page, pageSize } = query;

        let result: Entities.LeaderBoardListByPageResponse | null = null;
        if (name.includes('live_event_')) {
            const splitNames = name.replace('live_event_', '').split('_');
            const eventId = splitNames[0];
            result = await ActionRequest.get(`liveEvent/${eventId}/getLeaderBoardRanking`, { statisticName: name, page: page + 1, perPage: pageSize });
        } else if (name.includes('season_')) {
            const splitNames = name.replace('season_', '').split('_');
            const seasonId = splitNames[0];
            result = await ActionRequest.get(`season/${seasonId}/getLeaderBoardRanking`, { statisticName: name, page: page + 1, perPage: pageSize });
        } else {
            result = await ActionRequest.get(`leaderboard/${name}/${version}/members`, { page: page + 1, perPage: pageSize });
        }

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        let data: any;
        switch (result.leaderboard.type) {
            case LeaderboardType.Player:
                data = result.records.map((record: any) => {
                    return {
                        position: `#${record.position+1}`,
                        record: (
                            <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                                {(!record.profile.AvatarUrl || record.profile.AvatarUrl === '') && (
                                    <>
                                        <Grid size={12}>
                                            {getPlayerLink(record.playerId)}
                                        </Grid>
                                        <Grid size={12}>
                                            {record.displayName}
                                        </Grid>
                                    </>
                                )}
                                {(record.profile.AvatarUrl && record.profile.AvatarUrl !== '') && (
                                    <>
                                        <Grid size={2}>
                                            <CardMedia image={record.profile.AvatarUrl} style={{width: 40, height: 40, borderRadius: 0, border: `1px solid #c3cfdd`}}/>
                                        </Grid>
                                        <Grid size={10}>
                                            <Grid container={true} justifyContent="center" spacing={0}>
                                                <Grid size={12}>
                                                    {getPlayerLink(record.playerId)}
                                                </Grid>
                                                <Grid size={12}>
                                                    {record.displayName}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        ),
                        value: formatNumber(record.value),
                    };
                });
                break;
            case LeaderboardType.Group:
                data = result.records.map((record: any) => {
                    return {
                        position: `#${record.position+1}`,
                        record: (
                            <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                                <Grid size={12}>
                                    {getGroupLink(record.groupId)}
                                </Grid>
                                <Grid size={12}>
                                    {record.groupName}
                                </Grid>
                            </Grid>
                        ),
                        value: formatNumber(record.value),
                    };
                });
                break;
        }

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    name: '',
    leaderboard: state.leaderboard.leaderboard,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const name = params.name || '';
    return (<Container {...props} navigate={navigate} name={name}/>);
};
export default connect(mapStateToProps)(AppContainer);
