import * as React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, CardActionAreaProps } from '@mui/material';
import { CasinoOutlined, DomainOutlined } from '@mui/icons-material';
import { Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { ListItemTextProps } from '@mui/material/ListItemText/ListItemText';

interface Props extends ThemeModeProps {
    readonly title: Entities.UserTitleData;
    readonly onClick: (title: Entities.UserTitleData) => any;
}

export class TitleCard extends React.PureComponent<Props> {
    render() {
        const { themeMode, title } = this.props;
        const { id, name, imageUrl, organization } = title;

        return (
            <Card
                key={`title_${id}`}
                style={{
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                    borderRadius: 8,
                    backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                    width: '100%',
                    height: '100%',
                    padding: 0,
                }}
            >
                <StyledCardActionArea themeMode={themeMode} onClick={this.onCardClick}>
                    <CardContent>
                        <Grid container={true} alignItems="center" spacing={2}>
                            <Grid size={12} style={{ display: 'grid', gridColumnGap: 10, gridAutoFlow: 'column', justifyContent: 'flex-start'}}>
                                {!imageUrl && (
                                    <CasinoOutlined style={{width: 50, height: 50, borderRadius: 5, color: themeMode === 'light' ? '#1a1a1a' : '#ececec'}}/>
                                )}
                                {imageUrl && (
                                    <CardMedia image={imageUrl} style={{width: 50, height: 50, borderRadius: 5}}/>
                                )}
                                <Grid container={true} alignItems="center" spacing={0}>
                                    <Grid size={12} style={{ display: 'grid', textAlign: 'left' }}>
                                        <Typography variant="h5" style={{ color: themeMode === 'light' ? '#1a1a1a' : '#ececec', fontSize: '16px', fontWeight: 500 }}>
                                            {name}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontSize: '14px', color: themeMode === 'light' ? '#737373' : '#aeaeae' }}>
                                            {id}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <DomainOutlined style={{width: 20, height: 20, borderRadius: 5, color: 'grey'}} />
                                <Typography variant="subtitle2" style={{ color: themeMode === 'light' ? '#737373' : '#aeaeae', fontSize: 12, marginLeft: 2, lineHeight: '15px' }}>
                                    {organization}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </StyledCardActionArea>
            </Card>
        );
    }

    private onCardClick = () => {
        const { title, onClick } = this.props;
        onClick(title);
    };
}

interface StyledCardActionAreaProps extends CardActionAreaProps, ThemeModeProps {
}
const StyledCardActionArea = styled((props: StyledCardActionAreaProps) => (
    <CardActionArea
        {...props}
        sx={{
            '&.MuiCardActionArea-root': {
                padding: '0px !important',
                height: 126,
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
                '&$selected': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
            }
        }}
    >
        {props.children}
    </CardActionArea>
))(() => ({}));
