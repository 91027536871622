import * as React from 'react';
import { DialogContentText, Grid2 as Grid, InputLabel, Switch, Typography } from '@mui/material';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';
import { NumberSelect } from './input';
import { Moment } from 'moment/moment';
import { DurationMenuType, getDurationMenus } from '../../utils';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

interface Props extends ThemeModeProps {
    readonly title: string;
    readonly start: Moment;
    readonly handleStartChange: (start: Moment) => void;
    readonly end: Moment;
    readonly handleEndChange: (start: Moment) => void;
}

interface State {
    readonly duration: number;
    readonly useDuration: boolean;
}

export class ScheduledEntityComponent extends React.Component<Props, State> {
    state: State = {
        duration: getDurationMenus()['1day'].value,
        useDuration: true,
    };

    render() {
        const { title, start, end} = this.props;
        const { duration, useDuration } = this.state;
        const timesMenus = getDurationMenus();

        return (
            <Grid container={true} spacing={0}>
                <Grid size={{ xs: 12 }}>
                    <DialogContentText>
                        {title}
                    </DialogContentText>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Start Date</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <DatePicker
                        value={start}
                        onChange={this.handleStartChange}
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Start Time</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <TimePicker
                        value={start}
                        onChange={this.handleStartChange}
                        ampm={false}
                    />
                </Grid>
                {useDuration && (
                    <>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Duration</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <NumberSelect
                                value={duration}
                                onChange={this.handleDurationChange}
                            >
                                {Object.keys(timesMenus).map(key => {
                                    const durationMenuType = key as DurationMenuType;
                                    const {title, value} = timesMenus[durationMenuType];
                                    return (
                                        <option key={key} value={value}>
                                            {title}
                                        </option>
                                    );
                                })}
                            </NumberSelect>
                        </Grid>
                    </>
                )}
                {!useDuration && (
                    <>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>End Date</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <DatePicker
                                value={end}
                                onChange={this.handleEndChange}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>End Time</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <TimePicker
                                value={end}
                                onChange={this.handleEndChange}
                                ampm={false}
                            />
                        </Grid>
                    </>
                )}
                <Grid size={{ xs: 12 }}>
                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Set Time</Typography>
                        <Switch
                            checked={useDuration}
                            onChange={this.endTimeModeChange}
                            color="primary"
                            name="checkedLeaderBoardView"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Duration</Typography>
                    </div>
                </Grid>
            </Grid>
        );
    }

    private handleStartChange = (start: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (start === null) {
            return;
        }
        this.props.handleStartChange(start)
    }

    private handleEndChange = (end: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (end === null) {
            return;
        }
        this.props.handleEndChange(end)
    }

    private endTimeModeChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ useDuration: event.target.checked });

    private handleDurationChange = (duration: number) => {
        this.setState({ duration });
        const { start } = this.props;
        this.props.handleEndChange(start.clone().add(duration, 'minutes'))
    }
}