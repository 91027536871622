import * as React from 'react';
import {
    Checkbox, Theme,
    Typography,
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { getThemeMode } from '../../../reducers';

interface Props {
    readonly label: string;
    readonly checked: boolean;
    readonly height?: number;
    readonly icon?: SvgIconComponent;
    readonly iconSize?: number;
    readonly labelPlacement?: 'start' | 'end';
    readonly labelSize?: string;
    readonly readOnly?: boolean;
    readonly disabled?: boolean;
    readonly color?: string;
    readonly iconColor?: string;
    readonly checkBoxColor?: 'primary' | 'secondary' | 'default';
    readonly onChange?: (checked: boolean) => any;
    readonly justifyContent?: 'flex-start' | 'center' | 'flex-end';
}

export class LabelCheckBox extends React.PureComponent<Props> {
    render() {
        const {
            label,
            height,
            icon: Icon,
            checked,
            readOnly,
            disabled,
            labelPlacement,
            labelSize,
            iconSize,
            iconColor,
            checkBoxColor,
            justifyContent,
        } = this.props;

        const checkbox = (
            <Checkbox
                color={checkBoxColor}
                inputProps={{ readOnly }}
                checked={checked}
                disabled={disabled}
                onChange={this.onChange}
            />
        );

        const themeMode = getThemeMode();
        const color = this.props.color || (themeMode === 'light' ? '#737373' : '#ababab');
        if(!labelPlacement || labelPlacement === 'start') {
            return (
                <div style={{ ...styles.checkbox, height: height ? height : '100%', justifyContent: justifyContent ? justifyContent : 'flex-start' }} >
                    {Icon &&
                    <Icon style={{ width: iconSize || 20, height: iconSize || 20, margin: 2, color: iconColor }}/>
                    }
                    <Typography style={{ color, fontSize: labelSize ? labelSize : 'inherit' }}>{label}</Typography>
                    {checkbox}
                </div>
            );
        }

        return (
            <div style={{ ...styles.checkbox, height: height ? height : '100%', justifyContent: justifyContent ? justifyContent : 'flex-start' }} >
                {checkbox}
                {Icon &&
                <Icon style={{  width: iconSize || 20, height: iconSize || 20, margin: 2, color: iconColor }}/>
                }
                <Typography style={{ color, fontSize: labelSize ? labelSize : 'inherit' }}>{label}</Typography>
            </div>
        );
    }

    private onChange = (event: any) => {
        const { checked } = event.target;
        const { onChange } = this.props;
        if (onChange) {
            onChange(checked);
        }
    };
}

const styles = {
    checkbox: {
        display: 'flex',
        alignItems: 'center',
    },
};
