import React from 'react';
import {
    Grid2 as Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { Button, NumberField, TextField, DateTimePicker } from '../common';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { SegmentRuleOperator, SegmentRuleType, SegmentRuleValueOperator } from '../../enums';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { default as moment, Moment } from 'moment';
import { styled } from '@mui/styles';

interface Props {
    readonly rules?: Entities.SegmentRule[];
    readonly onUpdateRules: (rules: Entities.SegmentRule[]) => void;
}

interface State {
    readonly rules: Entities.SegmentRule[];
}

export class SegmentRules extends React.Component<Props, State> {
    state: State = {
        rules: [],
    };

    async componentDidMount() {
        const { rules } = this.props;
        if (rules) {
            this.setState({rules});
        } else {
            this.addGroup();
        }
    }

    render() {
        const { rules } = this.state;
        const ruleElements: React.JSX.Element[] = [];
        rules.forEach((r, i) => {
            switch (r.ruleType) {
                case SegmentRuleType.PlayerSpecific:
                    ruleElements.push(this.renderPlayerSpecificRule(i));
                    break;
                case SegmentRuleType.IsDeveloper:
                    ruleElements.push(this.renderIsDeveloperRule(i));
                    break;
                case SegmentRuleType.FirstLogin:
                    ruleElements.push(this.renderDateRule(i));
                    break;
                case SegmentRuleType.LatLogin:
                    ruleElements.push(this.renderDateRule(i));
                    break;
                case SegmentRuleType.ValueToDateUSD:
                    ruleElements.push(this.renderNumberRule(i));
                    break;
            }

            if (rules[i].ruleOperator === SegmentRuleOperator.OR) {
                ruleElements.push(this.renderAddFilter(i));
            }
            if (rules.length > i+1) {
                switch (rules[i].ruleOperator) {
                    case SegmentRuleOperator.AND:
                        ruleElements.push(this.renderAnd());
                        break;
                    case SegmentRuleOperator.OR:
                        ruleElements.push(this.renderOr());
                        break;
                }
            }
        });

        return (
            <Grid container={true} justifyContent="center" spacing={1}>
                <Grid size={12}>
                    <InputLabel>Rules</InputLabel>
                </Grid>
                <Grid size={12}>
                    <div style={{width: 'calc(100% - 32px), margin: 0'}}>
                        {ruleElements}
                    </div>
                </Grid>
                <Grid size={12}>
                    <StyledButton text="Add Group" icon={AddOutlined} onClick={this.addGroup} />
                </Grid>
            </Grid>
        );
    }

    private onChangeRules = (rules: Entities.SegmentRule[]) => {
        const { onUpdateRules } = this.props;
        this.setState({ rules });
        onUpdateRules(rules);
    };

    private renderRuleType = (index: number, value: SegmentRuleType) => {
        const { rules } = this.state;
        const onChangeMethod = (event: SelectChangeEvent<number>) => {
            const ruleType =  event.target.value as SegmentRuleType;
            rules[index].ruleType = ruleType;
            switch (ruleType) {
                case SegmentRuleType.PlayerSpecific:
                    rules[index].ruleValueOperator = null;
                    rules[index].stringValue = '';
                    rules[index].numberValue = null;
                    rules[index].booleanValue = null;
                    break;
                case SegmentRuleType.IsDeveloper:
                    rules[index].ruleValueOperator = null;
                    rules[index].stringValue = null;
                    rules[index].numberValue = null;
                    rules[index].booleanValue = null;
                    break;
                case SegmentRuleType.FirstLogin:
                    rules[index].ruleValueOperator = SegmentRuleValueOperator.IsGraterThan;
                    rules[index].stringValue = null;
                    rules[index].numberValue = 0;
                    rules[index].booleanValue = null;
                    break;
                case SegmentRuleType.LatLogin:
                    rules[index].ruleValueOperator = SegmentRuleValueOperator.IsGraterThan;
                    rules[index].stringValue = null;
                    rules[index].numberValue = 0;
                    rules[index].booleanValue = null;
                    break;
                case SegmentRuleType.ValueToDateUSD:
                    rules[index].ruleValueOperator = SegmentRuleValueOperator.IsGraterThan;
                    rules[index].stringValue = null;
                    rules[index].numberValue = 0;
                    rules[index].booleanValue = null;
                    break;

            }
            this.onChangeRules(rules);
        };

        return (
            <Select
                value={value}
                onChange={onChangeMethod}
                variant={'outlined'}
                fullWidth={true}
            >
                <MenuItem key={SegmentRuleType.PlayerSpecific} value={SegmentRuleType.PlayerSpecific}>
                    Player Specific
                </MenuItem>
                <MenuItem key={SegmentRuleType.IsDeveloper} value={SegmentRuleType.IsDeveloper}>
                    Is Developer
                </MenuItem>
                <MenuItem key={SegmentRuleType.FirstLogin} value={SegmentRuleType.FirstLogin}>
                    First Login
                </MenuItem>
                <MenuItem key={SegmentRuleType.LatLogin} value={SegmentRuleType.LatLogin}>
                    Lat Login
                </MenuItem>
                <MenuItem key={SegmentRuleType.ValueToDateUSD} value={SegmentRuleType.ValueToDateUSD}>
                    Value To Date USD
                </MenuItem>

            </Select>
        );
    };

    private renderAnd = () => {
        return (
            <Grid container={true} spacing={1} style={{width: 'calc(100% - 32px)', margin: 'auto'}}>
                <Grid size={12}>
                    AND
                </Grid>
            </Grid>
        );
    };

    private renderOr = () => {
        return (
            <Grid container={true} spacing={1} style={{margin: 0, width: '100%'}}>
                <Grid size={12}>
                    OR
                </Grid>
            </Grid>
        );
    };

    private renderAddFilter = (index: number) => {
        const onClick = () => this.addFilter(index);

        return (
            <Grid container={true} spacing={1} style={{width: 'calc(100% - 32px)', margin: 'auto'}}>
                <Grid size={12}>
                    <StyledButton text="Add Filter" icon={AddOutlined} onClick={onClick} />
                </Grid>
            </Grid>
        );
    };

    private renderPlayerSpecificRule = (index: number) => {
        const { rules } = this.state;
        const rule = rules[index];

        const onRemove = () => this.removeRule(index);

        const onStringValueChange = (stringValue: string) => {
            rule.stringValue = stringValue;
            this.onChangeRules(rules);
        };

        return (
            <Grid container={true} spacing={1} style={{width: 'calc(100% - 32px)', margin: 'auto'}}>
                <Grid size={{ xs:12, sm:3 }}>
                    {this.renderRuleType(index, rule.ruleType)}
                </Grid>
                <Grid size={{ xs:12, sm:3 }}>
                    <TextField
                        fullWidth={true}
                        label=""
                        variant="outlined"
                        value={rule.stringValue || ''}
                        onChange={onStringValueChange}
                    />
                </Grid>
                <Grid size={{ xs:12, sm:3 }}>
                    <StyledButton text="Remove" icon={DeleteOutlined} onClick={onRemove} />
                </Grid>
                <Grid size={{ xs:12, sm:3 }}/>
            </Grid>
        );
    };

    private renderIsDeveloperRule = (index: number) => {
        const { rules } = this.state;
        const rule = rules[index];

        const onRemove = () => this.removeRule(index);

        return (
            <Grid container={true} spacing={1} style={{width: 'calc(100% - 32px)', margin: 'auto'}}>
                <Grid size={{ xs: 12, sm: 3 }}>
                    {this.renderRuleType(index, rule.ruleType)}
                </Grid>
                <Grid size={{ xs: 12, sm: 3 }}>
                    <StyledButton text="Remove" icon={DeleteOutlined} onClick={onRemove} />
                </Grid>
                <Grid size={{ xs:12, sm:3 }} />
                <Grid size={{ xs:12, sm:3 }} />
            </Grid>
        );
    };

    private renderNumberRule = (index: number) => {
        const { rules } = this.state;
        const rule = rules[index];

        const onRemove = () => this.removeRule(index);
        const onValueChange = (numberValue: number) => {
            rule.numberValue = numberValue;
            this.onChangeRules(rules);
        };

        const onChangeRuleValueOperator = (event: SelectChangeEvent<number>) => {
            rule.ruleValueOperator = event.target.value as SegmentRuleValueOperator;
            this.onChangeRules(rules);
        };

        return (
            <Grid container={true} spacing={1} style={{width: 'calc(100% - 32px)', margin: 'auto'}}>
                <Grid size={{ xs: 12, sm: 3 }}>
                    {this.renderRuleType(index, rule.ruleType)}
                </Grid>
                {rule.ruleValueOperator != undefined && (
                    <Grid size={{ xs: 12, sm: 3 }}>
                        <Select
                            value={rule.ruleValueOperator}
                            onChange={onChangeRuleValueOperator}
                            variant={'outlined'}
                            fullWidth={true}
                        >
                            <MenuItem key={SegmentRuleValueOperator.IsGraterThan} value={SegmentRuleValueOperator.IsGraterThan}>is grater than</MenuItem>
                            <MenuItem key={SegmentRuleValueOperator.IsGrateThanOrEqualTo} value={SegmentRuleValueOperator.IsGrateThanOrEqualTo}>is grater than or equal to</MenuItem>
                            <MenuItem key={SegmentRuleValueOperator.IsLessThan} value={SegmentRuleValueOperator.IsLessThan}>is less than</MenuItem>
                            <MenuItem key={SegmentRuleValueOperator.IsLessThanOrEqualTo} value={SegmentRuleValueOperator.IsLessThanOrEqualTo}>is less than or equal to</MenuItem>
                        </Select>
                    </Grid>
                )}
                <Grid size={{ xs: 12, sm: 3 }}>
                    <NumberField
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label=""
                        variant="outlined"
                        value={rule.numberValue || 0}
                        onChange={onValueChange}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 3 }}>
                    <StyledButton text="Remove" icon={DeleteOutlined} onClick={onRemove} />
                </Grid>
            </Grid>
        );
    }

    private renderDateRule = (index: number) => {
        const { rules } = this.state;
        const rule = rules[index];

        const dateValue = (!rule.numberValue || rule.numberValue === 0) ? moment.parseZone() : moment(rule.numberValue);

        const onChangeRuleValueOperator = (event: SelectChangeEvent<number>) => {
            rule.ruleValueOperator = event.target.value as SegmentRuleValueOperator;
            this.onChangeRules(rules);
        };

        const onChangeDate = (date: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
            if (date == null) {
                return;
            }
            rule.numberValue = date.utc().valueOf();
            this.onChangeRules(rules);
        };

        const onRemove = () => this.removeRule(index);

        return (
            <Grid container={true} spacing={1} style={{width: 'calc(100% - 32px)', margin: 'auto'}}>
                <Grid size={{ xs: 12, sm: 3 }}>
                    {this.renderRuleType(index, rule.ruleType)}
                </Grid>
                {rule.ruleValueOperator != undefined && (
                    <Grid size={{ xs: 12, sm: 3 }}>
                        <Select
                            value={rule.ruleValueOperator}
                            onChange={onChangeRuleValueOperator}
                            variant={'outlined'}
                            fullWidth={true}
                        >
                            <MenuItem key={SegmentRuleValueOperator.IsGraterThan} value={SegmentRuleValueOperator.IsGraterThan}>is grater than</MenuItem>
                            <MenuItem key={SegmentRuleValueOperator.IsGrateThanOrEqualTo} value={SegmentRuleValueOperator.IsGrateThanOrEqualTo}>is grater than or equal to</MenuItem>
                            <MenuItem key={SegmentRuleValueOperator.IsLessThan} value={SegmentRuleValueOperator.IsLessThan}>is less than</MenuItem>
                            <MenuItem key={SegmentRuleValueOperator.IsLessThanOrEqualTo} value={SegmentRuleValueOperator.IsLessThanOrEqualTo}>is less than or equal to</MenuItem>
                        </Select>
                    </Grid>
                )}
                <Grid size={{ xs: 12, sm: 3 }}>
                    <DateTimePicker
                        value={dateValue}
                        onChange={onChangeDate}
                        disablePast={false}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 3 }}>
                    <StyledButton text="Remove" icon={DeleteOutlined} onClick={onRemove} />
                </Grid>
            </Grid>
        );
    };

    private addGroup = () => {
        const { rules } = this.state;
        const rule: Entities.SegmentRule = {
            id: '',
            segmentId: '',
            ruleType: SegmentRuleType.PlayerSpecific,
            ruleOperator: SegmentRuleOperator.OR,
            ruleValueOperator: null,
            stringValue: null,
            numberValue: null,
            booleanValue: null,
        };
        rules.push(rule);
        this.onChangeRules(rules);
    }

    private addFilter = (index: number) => {
        const { rules } = this.state;
        rules[index].ruleOperator = SegmentRuleOperator.AND;
        const rule: Entities.SegmentRule = {
            id: '',
            segmentId: '',
            ruleType: SegmentRuleType.PlayerSpecific,
            ruleOperator: SegmentRuleOperator.OR,
            ruleValueOperator: null,
            stringValue: null,
            numberValue: null,
            booleanValue: null,
        };
        rules.push(rule);
        this.onChangeRules(rules);
    }

    private removeRule = (index: number) => {
        const { rules } = this.state;
        this.onChangeRules(rules.filter((v, i) => i !== index));
    }
}

const StyledButton = styled(Button)(({
    color: '#1172c6',
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    }
}));
