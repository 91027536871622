import { Action } from './index';
import { AppActionType } from '../enums';

export interface AppState {
    readonly current?: Entities.App;
    readonly loading: boolean;
    readonly error: Error | null;
    readonly lastPath: string;
    readonly showDrawer: boolean;
    readonly smDrawerOpen: boolean;
    readonly themeMode: ThemeMode;
}

export type AppAction = Action<Partial<AppState>>;

const initState: AppState = {
    loading: false,
    showDrawer: true,
    smDrawerOpen: false,
    error: null,
    lastPath: '',
    themeMode: 'light',
};

const reducer = (state: AppState = initState, action: AppAction): AppState => {
    switch (action.type) {
        case AppActionType.Init:
            return { ...state, ...action.payload };
        case AppActionType.Update:
            return { ...state, ...action.payload };
    }
    return state;
};
export default reducer;
