import * as React from 'react';
import actions from '../actions';
import { connect } from 'react-redux';
import { AddOutlined, AttachMoneyOutlined as AttachMoneyIcon } from '@mui/icons-material';
import { ContainerContext, mapProps } from './';
import {
    CreateCurrencyDialog,
    Table,
    BaseElement,
    HeaderButton,
    BaseEconomyContainer,
} from '../components';
import * as _ from 'lodash';
import { UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly currencies: Entities.Currency[];
}

type DialogType = 'Create';
interface State {
    readonly openDialog: DialogType | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
    };

    async componentDidMount() {
        await actions.economy.listCurrencies();
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Create', icon: AddOutlined, onClick: this.openCreateDialog}
        ];
        return (
            <BaseEconomyContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = "Currencies"
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseEconomyContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { currencies } = this.props;
        const columns = [
            { title: 'Code', field: 'code'},
            { title: 'Display Name', field: 'displayName'},
        ];
        const data = _.map(
            currencies,
            currency => {
                return {
                    code: currency.code,
                    displayName: currency.name,
                };
            }
        );
        return (
            <Table
                data={data}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                }}
            />
        );
    };

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });
    private renderCreateDialog = () => {
        return (
            <CreateCurrencyDialog
                open={this.state.openDialog === 'Create'}
                title={'Create Currency'}
                TitleIcon = {AttachMoneyIcon}
                onClose={this.closeDialog}
                onCreate={this.onCreate}
            />
        );
    };

    private onCreate = async (code: string, name: string) => {
        this.closeDialog();
        await actions.economy.createCurrency(code, name);
    };

    private closeDialog = () => this.setState({ openDialog: null });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    currencies: state.economy.currencies,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
