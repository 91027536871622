import * as React from 'react';
import { CircularProgress } from '@mui/material';

interface Props extends ThemeModeProps {
    size?: number;
}

export class Loading extends React.Component<Props> {
    render() {
        const { themeMode, size } = this.props;
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
                <CircularProgress thickness={4.2} style={{width: size ?? 40, height: size ?? 40, color: themeMode === 'light' ? '#5791f5' : '#5a87d6'}}/>
            </div>
        );
    }
}
