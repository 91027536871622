import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer, BaseElement,
    CreateNewLeaderBoardEventComponent,
    CreateNewRegularEventComponent,
} from '../components';
import {
    AggregationMethod,
    EventType,
    LeaderBoardType,
    ListEventFilterStateType,
    RecurringResetTimerType,
    TimerType,
} from '../enums';
import actions from '../actions';
import { connect } from 'react-redux';
import { CalendarTodayOutlined as CalendarTodayIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly liveEventId: string;
    readonly details?: Entities.LiveEvent;
    readonly list?: Entities.LiveEvent[];
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        const { liveEventId } = this.props;
        await Promise.all([
            actions.liveEvent.get(liveEventId),
            actions.liveEvent.list(ListEventFilterStateType.ActiveAndComing)
        ]);
    }

    protected renderContainer(): React.JSX.Element {
        const { details, liveEventId } = this.props;
        let headlineText = '';
        if (details) {
            switch (details.eventType) {
                case EventType.Regular:
                    headlineText = 'Edit Regular';
                    break;
                case EventType.LeaderBoard:
                    headlineText = 'Edit Leaderboard';
                    break;
                default:
                    break;
            }
        }

        const headlines = [
            {
                text: 'Live Event',
                to: `/liveEvent/all`,
            },
            {
                text: headlineText,
            },
            {
                text: liveEventId,
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Edit Live Event"}
                TitleIcon = {CalendarTodayIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { details, list, app } = this.props;
        if (!details || !list) {
            return;
        }
        switch (details.eventType) {
            case EventType.Regular:
                return (
                    <CreateNewRegularEventComponent
                        themeMode={app.themeMode}
                        eventType={EventType.Regular}
                        type='Edit'
                        liveEvents={list}
                        oldLiveEvent={details}
                        onCreate={this.onUpdateRegularEvent}
                    />
                );
            case EventType.LeaderBoard:
                return (
                    <CreateNewLeaderBoardEventComponent
                        themeMode={app.themeMode}
                        eventType={EventType.LeaderBoard}
                        type='Edit'
                        liveEvents={list}
                        oldLiveEvent={details}
                        onCreate={this.onUpdateLeaderBoardEvent}
                    />
                );
            default:
                return;
        }
    }

    private onUpdateRegularEvent = async (
        name: string,
        gameData: string,
        scheduleColor: string,
        developerOnly: boolean,
        finishActions: Entities.AutomaticPlayerAction[],
        startTimestamp: number | null,
        endTimestamp: number | null,
        timerType: TimerType,
        recurringResetTimerType: RecurringResetTimerType | null,
        recurringStart: number | null,
        recurringDuration: number | null,
        recurringDailyDays: number[] | null,
        recurringWeeklyDayStart: number | null,
    ) => {
        const { liveEventId } = this.props;
        await actions.liveEvent.updateEventRegular(
            liveEventId,
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
        );
        this.toLiveEvent();
    };

    private onUpdateLeaderBoardEvent = async (
        name: string,
        gameData: string,
        scheduleColor: string,
        developerOnly: boolean,
        finishActions: Entities.AutomaticPlayerAction[],
        startTimestamp: number | null,
        endTimestamp: number | null,
        timerType: TimerType,
        recurringResetTimerType: RecurringResetTimerType | null,
        recurringStart: number | null,
        recurringDuration: number | null,
        recurringDailyDays: number[] | null,
        recurringWeeklyDayStart: number | null,
        ranks: Entities.EntityLeaderBoardRank[],
        maxUsersPerInstance: number,
        leaderBoardType: LeaderBoardType,
        includeGroupLeaderBoard: boolean,
        groupLeaderBoardAggregationMethod: AggregationMethod,
    ) => {
        const { liveEventId } = this.props;
        await actions.liveEvent.updateEventLeaderBoard(
            liveEventId,
            name,
            gameData,
            scheduleColor,
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
            ranks,
            maxUsersPerInstance,
            leaderBoardType,
            includeGroupLeaderBoard,
            groupLeaderBoardAggregationMethod,
        );
        this.toLiveEvent();
    };

    private toLiveEvent = () => this.toLink(`/liveEvent/all`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    liveEventId: '',
    details: state.liveEvent.details,
    list: state.liveEvent.list,
});

const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const liveEventId = params.liveEventId || '';
    return (<Container {...props} navigate={navigate} liveEventId={liveEventId}/>);
};
export default connect(mapStateToProps)(AppContainer);
