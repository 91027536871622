import React, { CSSProperties } from 'react';
import { Link, styleComponents } from '../../utils';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
    CardMedia,
    Collapse,
    Divider,
    Grid2 as Grid,
    Tooltip,
    Typography,
    ListItemIcon,
    ListItemText,
    Drawer as DrawerCore,
    List,
    Theme,
    ListItemButton,
    ListProps,
} from '@mui/material';
import {
    CalendarTodayOutlined as CalendarTodayIcon,
    CategoryOutlined as CategoryIcon,
    CodeOutlined as CodeIcon,
    PaidOutlined as PaidIcon,
    DescriptionOutlined as DescriptionIcon,
    EmojiEventsOutlined as EmojiEventsIcon,
    ExpandLess,
    ExpandMore,
    NewspaperOutlined as NewspaperIcon,
    GamesOutlined as GamesIcon,
    GroupWorkOutlined as GroupWorkIcon,
    HomeOutlined as HomeIcon,
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
    NotesOutlined as NotesIcon,
    GroupOutlined as GroupIcon,
    PollOutlined as PollIcon,
    PublicOutlined as PublicIcon,
    SettingsOutlined as SettingsIcon,
    SvgIconComponent,
    TimelineOutlined as TimelineIcon,
    CardGiftcardOutlined as CardGiftcardIcon,
    Apple as AppleIcon,
    BuildOutlined as BuildIcon,
    LoyaltyOutlined as LoyaltyIcon,
    LanguageOutlined as LanguageIcon,
} from '@mui/icons-material';
import { Button } from './input';
import { UserTitleRole } from '../../enums';
import { styled } from '@mui/styles';
import { ListItemTextProps } from '@mui/material/ListItemText/ListItemText';
import { SxProps } from '@mui/system';
import actions from '../../actions';
import { ListItemButtonProps } from '@mui/material/ListItemButton/ListItemButton';
import { Components as StyleComponents } from '@mui/material/styles/components';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface DrawerProps extends ThemeModeProps {
    readonly variant: 'permanent' | 'temporary';
    readonly openDrawer: boolean;
    readonly expanded: boolean;
    readonly userTitleRole: UserTitleRole;
    readonly sx?: SxProps<Theme>;
}

interface State {
    readonly anchorEl: HTMLElement | undefined;
    readonly buildMenuOpen: boolean;
    readonly liveOpsMenuOpen: boolean;
    readonly csMenuOpen: boolean;
    readonly devOpsMenuOpen: boolean;
    readonly marketingMenuOpen: boolean;
}

export class Drawer extends React.Component<DrawerProps, State> {

    state = {
        anchorEl: undefined,
        buildMenuOpen: window.location.href.match(/\/players\/|\/segments\/|\/clientVersion\//g) !== null,
        liveOpsMenuOpen: window.location.href.match(/\/group\/|\/cloudCode\/|\/economy\/|\/currency\/|\/titleData\/|\/titleNews\/|\/files\/|\/achievements\/|\/liveEvent\/|\/season\/|\/leaderBoard\/|\/localization/g) !== null,
        csMenuOpen: window.location.href.match(/\/analyzePlayersData\/|\/compensation\/|\/appleSupport\//g) !== null,
        devOpsMenuOpen: window.location.href.match(/\/cloudBuild\//g) !== null,
        marketingMenuOpen: window.location.href.match(/\/marketing\//g) !== null,
    };

    render() {
        const { variant, themeMode, openDrawer, expanded, sx } = this.props;

        return (
            <ThemeProvider theme={this.getTheme(themeMode)}>
                <DrawerCore
                    variant={variant}
                    open={openDrawer}
                    onClose={variant === 'permanent' ? undefined : this.onClickSmallDrawer}
                    sx={sx}
                >
                    <StyledTitleLink to={'/'} >
                        <Grid container={true} justifyContent="center" alignItems="center" spacing={0} onClick={ variant === 'permanent' ? undefined : this.onClickSmallDrawer } style={{ margin: 'auto'}}>
                            <Grid size={{ xs:12 }} sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gridColumnGap: 10}}>
                                <CardMedia
                                    sx={{ width: 50, height: 52, backgroundSize: 50, margin: '0px !important' }}
                                    image={`${process.env.PUBLIC_URL}/images/nucleo-logo.png`}
                                    title="Logo"
                                />
                                {expanded && (
                                    <Typography children={'Nucleo Portal'} variant="h1" color="inherit" sx={{ color: '#ecedee', fontSize: '18px', fontWeight: 600}}/>
                                )}
                            </Grid>
                        </Grid>
                    </StyledTitleLink>
                    <Divider/>
                    {expanded ? this.renderWideList() : this.renderNarrowList()}
                    <Divider/>
                    <div style={{
                            display: 'flex',
                            padding: '8px 5px',
                            backgroundColor: 'transparent',
                            width: 'calc(100% - 10px)',
                            justifyContent: expanded ? 'flex-end' : 'center',
                        }}
                    >
                        <ArrowButton
                            icon={ expanded ? KeyboardArrowLeftOutlined : KeyboardArrowRightOutlined }
                            iconSize={24}
                            variant='text'
                            onClick={ variant === 'permanent' ? this.onClickDrawerIcon : this.onClickSmallDrawer }
                            style={{
                                color: themeMode === 'light' ? '#00000099' : '#ffffff99'
                            }}
                        />
                    </div>
                </DrawerCore>
            </ThemeProvider>
        );
    }

    private getTheme(themeMode: ThemeMode): Theme {
        const tableComponents: StyleComponents<Omit<Theme, 'components'>> = {
            ...styleComponents(themeMode),
            MuiModal: {
                styleOverrides: {
                    backdrop: {
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    }
                }
            },
        };
        return createTheme({ components: tableComponents });
    }

    private renderWideList = () => {
        const { themeMode } = this.props;
        const { buildMenuOpen, liveOpsMenuOpen, csMenuOpen, devOpsMenuOpen, marketingMenuOpen } = this.state;

        return (
            <DivStyled>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0px' }}>
                    {this.renderDrawerItem('Project Overview', `/dashboard`, HomeIcon, undefined, undefined, { minWidth: 200, height: 40, margin: '0px 2px' })}
                    <Divider orientation="vertical" style={{ height: 20 }}/>
                    {this.renderDrawerItem('', `/settings/general`, SettingsIcon, 'settings/', 'Settings', { width: 40, height: 40, margin: '0 2px', padding: '0px 10px' })}
                </div>
                <Divider/>
                <WideStyledList>
                    <StyledListItem open={buildMenuOpen} onClick={this.handleClickBuild} style={{ borderRadius: 0}}>
                        <StyledListItemText primary="Build" themeMode={themeMode}/>
                        {buildMenuOpen ? (<StyledExpandLess themeMode={themeMode}/>) : (<StyledExpandMore themeMode={themeMode}/> )}
                    </StyledListItem>
                    <Collapse in={buildMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Players', `/players/all`, GroupIcon, 'players/')}
                            {this.renderDrawerItem('Segments', `/segments/all`, CategoryIcon, 'segments/')}
                            {this.renderDrawerItem('Client Versions', `/clientVersion/all`, NotesIcon, 'clientVersion/')}
                        </ListNested>
                    </Collapse>
                    <StyledListItem open={liveOpsMenuOpen} onClick={this.handleClickLiveOps}>
                        <StyledListItemText primary="Live Ops" themeMode={themeMode}/>
                        {liveOpsMenuOpen ? (<StyledExpandLess themeMode={themeMode}/>) : (<StyledExpandMore themeMode={themeMode}/> )}
                    </StyledListItem>
                    <Collapse in={liveOpsMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('CloudCode', `/cloudCode/all`, CodeIcon, 'cloudCode/')}
                            {this.renderDrawerItem('Groups', `/group/all`, GroupWorkIcon, 'group/')}
                            {this.renderDrawerItem('Economy', `/economy/items`, PaidIcon, 'economy/')}
                            {this.renderDrawerItem('Title Data', `/titleData/default`, DescriptionIcon, 'titleData/')}
                            {this.renderDrawerItem('Title News', `/titleNews/all`, NewspaperIcon, 'titleNews/')}
                            {this.renderDrawerItem('Cloud Content Delivery', `/files/default`, PublicIcon, 'files/')}
                            {this.renderDrawerItem('Achievements', `/achievements/all`, EmojiEventsIcon, 'achievements/')}
                            {this.renderDrawerItem('Live Events', `/liveEvent/all`, CalendarTodayIcon, 'liveEvent/')}
                            {this.renderDrawerItem('Seasons', `/season/all`, GamesIcon, 'season/')}
                            {this.renderDrawerItem('LeaderBoard', `/leaderBoard/all`, PollIcon, 'leaderBoard/')}
                            {this.renderDrawerItem('Localization', `/localization`, LanguageIcon, 'localization')}
                        </ListNested>
                    </Collapse>
                    <StyledListItem open={csMenuOpen} onClick={this.handleClickCostumerSupport}>
                        <StyledListItemText primary="Costumer Support" themeMode={themeMode}/>
                        {csMenuOpen ? ( <StyledExpandLess themeMode={themeMode}/>) : (<StyledExpandMore themeMode={themeMode}/> )}
                    </StyledListItem>
                    <Collapse in={csMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Analyze Players Data', `/analyzePlayersData/achievements`, TimelineIcon, 'analyzePlayersData/')}
                            {this.renderDrawerItem('Compensations', `/compensation/preDefinedItems`, LoyaltyIcon, 'compensation/')}
                            {this.renderDrawerItem('Apple Support', `/appleSupport/all`, AppleIcon, 'appleSupport/')}
                        </ListNested>
                    </Collapse>
                    <StyledListItem open={devOpsMenuOpen} onClick={this.handleClickDevOps} style={{ borderRadius: 0}}>
                        <StyledListItemText primary="Dev Ops" themeMode={themeMode}/>
                        {devOpsMenuOpen ? (<StyledExpandLess themeMode={themeMode}/>) : (<StyledExpandMore themeMode={themeMode}/> )}
                    </StyledListItem>
                    <Collapse in={devOpsMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Cloud Build', `/cloudBuild/history`, BuildIcon, 'cloudBuild/')}
                        </ListNested>
                    </Collapse>
                    <StyledListItem open={marketingMenuOpen} onClick={this.handleClickMarketing} style={{ borderRadius: 0}}>
                        <StyledListItemText primary="Marketing" themeMode={themeMode}/>
                        {marketingMenuOpen ? (<StyledExpandLess themeMode={themeMode}/>) : (<StyledExpandMore themeMode={themeMode}/> )}
                    </StyledListItem>
                    <Collapse in={marketingMenuOpen} timeout="auto" unmountOnExit={true}>
                        <ListNested disablePadding={true}>
                            {this.renderDrawerItem('Promo Campaigns', `/marketing/promoCampaigns`, CardGiftcardIcon, 'marketing/')}
                        </ListNested>
                    </Collapse>
                </WideStyledList>
            </DivStyled>
        );
    };

    private renderNarrowList = () => {
        const { themeMode } = this.props;
        return (
            <NarrowStyledList themeMode={themeMode}>
                <Grid container={true} justifyContent="center" alignItems="center" spacing={0}>
                    <Grid size={12}>
                        {this.renderDrawerItem('', `/dashboard`, HomeIcon, undefined, 'Project Overview')}
                        {this.renderDrawerItem('', `/settings/general`, SettingsIcon, 'settings/', 'Settings')}
                    </Grid>
                    <Grid size={12}>
                        <Divider/>
                    </Grid>
                    <Grid size={12}>
                        {this.renderDrawerItem('', `/players/all`, GroupIcon, 'players/', 'Players')}
                        {this.renderDrawerItem('', `/segments/all`, CategoryIcon, 'segments/', 'Segments')}
                        {this.renderDrawerItem('', `/clientVersion/all`, NotesIcon, 'clientVersion/', 'Client Versions')}
                    </Grid>
                    <Grid size={12}>
                        <Divider/>
                    </Grid>
                    <Grid size={12}>
                        {this.renderDrawerItem('', `/cloudCode/all`, CodeIcon, 'cloudCode/', 'CloudCode')}
                        {this.renderDrawerItem('', `/group/all`, GroupWorkIcon, 'group/', 'Groups')}
                        {this.renderDrawerItem('', `/economy/items`, PaidIcon, 'economy/', 'Economy')}
                        {this.renderDrawerItem('', `/titleData/default`, DescriptionIcon, 'titleData/', 'Title Data')}
                        {this.renderDrawerItem('', `/titleNews/all`, NewspaperIcon, 'titleNews/', 'Title News')}
                        {this.renderDrawerItem('', `/files/default`, PublicIcon, 'files', 'CDN')}
                        {this.renderDrawerItem('', `/achievements/all`, EmojiEventsIcon, 'achievements/', 'Achievements')}
                        {this.renderDrawerItem('', `/liveEvent/all`, CalendarTodayIcon, 'liveEvent/', 'Live Events')}
                        {this.renderDrawerItem('', `/season/all`, GamesIcon, 'season/', 'Seasons')}
                        {this.renderDrawerItem('', `/leaderBoard/all`, PollIcon, 'leaderBoard/', 'LeaderBoard')}
                        {this.renderDrawerItem('', `/localization`, LanguageIcon, 'localization/', 'Localization')}
                    </Grid>
                    <Grid size={12}>
                        <Divider/>
                    </Grid>
                    <Grid size={12}>
                        {this.renderDrawerItem('', `/analyzePlayersData/achievements`, TimelineIcon, `analyzePlayersData/`, 'Analyze Player Data')}
                        {this.renderDrawerItem('', `/compensation/preDefinedItems`, LoyaltyIcon, 'compensation/', 'Compensations')}
                        {this.renderDrawerItem('', `/appleSupport/all`, AppleIcon, 'appleSupport/', 'Apple')}
                    </Grid>
                    <Grid size={12}>
                        <Divider/>
                    </Grid>
                    <Grid size={12}>
                        {this.renderDrawerItem('', `/cloudBuild/history`, BuildIcon, 'cloudBuild', 'Cloud Build')}
                    </Grid>
                    <Grid size={12}>
                        <Divider/>
                    </Grid>
                    <Grid size={12}>
                        {this.renderDrawerItem('', `/marketing/promoCampaigns`, CardGiftcardIcon, 'marketing', 'Promo Campaign')}
                    </Grid>
                    <Grid size={12}>
                        <Divider/>
                    </Grid>
                </Grid>
            </NarrowStyledList>
        );
    };

    private renderDrawerItem(title: string, to: string, Icon: SvgIconComponent, base?: string, tooltip?: string, style?: CSSProperties | undefined) {
        const { expanded, themeMode, variant } = this.props;
        const selected = window.location.href.includes(base || to);

        const onClick = () => {
            if (variant !== 'permanent') {
                this.onClickSmallDrawer();
            }
        };


        return (
            <Link to={to} style={{ textDecoration: 'none', color: 'black' }}>
                <MainListItemButton
                    selected={selected}
                    onClick={onClick}
                    expanded={expanded}
                    themeMode={themeMode}
                    style={ style }
                >
                    <Tooltip title={tooltip || ''} placement="right">
                        <ListItemIcon
                            sx={{
                                margin: 0,
                                minWidth: 34,
                                color: 'inherit',
                            }}
                        >
                            <Icon style={{ height: 18, width: 18 }} />
                        </ListItemIcon>
                    </Tooltip>
                    {title !== '' && (
                        <ListItemText
                            inset={true}
                            primary={title}
                            disableTypography={true}
                            sx={{
                                padding: 0,
                                fontSize: '13px',
                                fontWeight: 500,
                                lineHeight: '20px',
                            }}
                        />
                    )}
                </MainListItemButton>
            </Link>
        );
    }

    private onClickDrawerIcon = () => actions.app.changeShowDrawer();
    private onClickSmallDrawer = () => actions.app.changeSmDrawerOpen();

    private handleClickBuild = () => this.setState({ buildMenuOpen: !this.state.buildMenuOpen });
    private handleClickLiveOps = () => this.setState({ liveOpsMenuOpen: !this.state.liveOpsMenuOpen });
    private handleClickCostumerSupport = () => this.setState({ csMenuOpen: !this.state.csMenuOpen });
    private handleClickDevOps = () => this.setState({ devOpsMenuOpen: !this.state.devOpsMenuOpen });
    private handleClickMarketing = () => this.setState({ marketingMenuOpen: !this.state.marketingMenuOpen });
}

interface MainListItemProps extends ListItemButtonProps, ThemeModeProps {
    readonly expanded: boolean;
    readonly style?: CSSProperties | undefined;
}
const MainListItemButton = styled((props: MainListItemProps) => (
    <ListItemButton
        {...props}
        sx={{
            textDecoration: 'none',
            padding: props.expanded ? '4px 18px' : '8px 24px',
            margin: props.expanded ? '2px' : '2px 0px',
            '&.MuiListItemButton-root': {
                backgroundColor: 'transparent',
                color: props.themeMode === 'light' ? '#6f6f6f' : '#ababab',
                "&:hover": {
                    backgroundColor: props.themeMode === 'light' ? '#0b57d014' : '#7cacf814',
                    borderRadius: 16,
                },
            },
            '&.Mui-selected': {
                backgroundColor: props.themeMode === 'light' ? '#d3e3fd' : '#1047a6',
                color: props.themeMode === 'light' ? '#13161b' : '#dfe5f2',
                borderRadius: 16,
                "&:hover": {
                    backgroundColor: props.themeMode === 'light' ? '#d3e3fd' : '#1047a6',
                    borderRadius: 16,
                },
            },
        }}
    >
        {props.children}
    </ListItemButton>
))(() => ({}));

const StyledTitleLink = styled(ReactRouterLink)(({
    display: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#F2F2F2',
    padding: '10px 0',
    backgroundColor: '#ff6e45',
    boxShadow: 'none',
    borderRadius: 0,
    '&:hover': {
        backgroundColor: '#ff6e45',
    },
}));

const DivStyled = styled('div')(({
    backgroundColor: 'transparent',
    height: 'calc(100% - 52px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        width: 6,
        backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babec9',
        borderRadius: 4,
        border: '2px solid #b2b9c9',
    }
}));

const WideStyledList = styled(List)(({
    backgroundColor: 'transparent',
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
}));

interface NarrowStyledListProps extends ListProps, ThemeModeProps {}
const NarrowStyledList = styled((props: NarrowStyledListProps) => (
    <List
        {...props}
        sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            margin: 0,
            height: 'calc(100% - 52px)',
            paddingTop: 0,
            backgroundColor: 'transparent',
            '&::-webkit-scrollbar': {
                width: 6,
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: props.themeMode === 'light' ? '#babec9' : '#8a9bb2',
                borderRadius: 4,
                border: props.themeMode === 'light' ? '2px solid #b2b9c9' : '2px solid #262f3d',
            }
        }}
    >
        {props.children}
    </List>
))(() => ({}));

const ListNested = styled((props: ListProps) => (
    <List
        {...props}
        sx={{
            backgroundColor: 'transparent',
            overflowY: 'hidden',
            overflowX: 'hidden',
            margin: 0,
            padding: '5px 0px',
        }}
    >
        {props.children}
    </List>
))(() => ({}));

const ArrowButton = styled(Button)(({
    minWidth: 'auto',
    backgroundColor: 'transparent',
    color: '#0009',
    '&:hover': {
        backgroundColor: 'transparent'
    },
}));

const StyledExpandLess = styled((props: ThemeModeProps) => (
    <ExpandLess
        sx={{
            margin: 0,
            minWidth: 36,
            color: props.themeMode === 'light' ? '#0009' : 'rgba(255, 255, 255, 0.6)',
        }}
    />
))(() => ({}));

const StyledExpandMore = styled((props: ThemeModeProps) => (
    <ExpandMore
        sx={{
            margin: 0,
            minWidth: 36,
            color: props.themeMode === 'light' ? '#0009' : 'rgba(255, 255, 255, 0.6)',
        }}
    />
))(() => ({}));

interface StyledListItemProps extends ListItemButtonProps {
    readonly open: boolean;
    style?: React.CSSProperties;
    onClick?: any;
}
const StyledListItem = styled((props: StyledListItemProps) => (
    <ListItemButton
        {...props}
        onClick={props.onClick}
        sx={{
            '&.MuiListItemButton-root': {
                textDecoration: 'none',
                paddingLeft: '20px',
                paddingRight: '5px',
                height: 40,
                margin: '2px 0px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        }}
    >
        {props.children}
    </ListItemButton>
))(() => ({}));

interface StyledListItemTextProps extends ListItemTextProps, ThemeModeProps {}
const StyledListItemText = styled((props: StyledListItemTextProps) => (
    <ListItemText
        {...props}
        primary={
            <div
                style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: props.themeMode === 'light' ? '#6b6b6b' : '#aeaeae',
                }}
            >
                {props.primary}
            </div>
        }
        sx={{
            '&.MuiListItemText-root': {
                padding: 0,
                textTransform: 'uppercase',
                lineHeight: 20,
                '&:hover': {
                    backgroundColor: 'transparent'
                },
            },
        }}
    />
))(() => ({}));
