import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer,
    BaseElement,
    CreateSeasonLeaderBoardComponent,
    CreateSeasonRegularComponent,
} from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { BarChartOutlined, EventOutlined, GamesOutlined as GamesIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { LeaderBoardType, SeasonType } from '../enums';
import { StateMap } from '../reducers';
import { Card, CardActionArea, CardActionAreaProps, CardContent, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';

interface Props extends ContainerContext.Props {
}

interface State {
    seasonType: SeasonType | null;
}

class Container extends BaseElement<Props> {
    state: State = {
        seasonType: null,
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Season',
                to: `/season/all`,
            },
            {
                text: 'Create',
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Create Season"}
                TitleIcon = {GamesIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { seasonType } = this.state;
        const { themeMode } = this.props.app;
        if (seasonType === null) {
            return (
                <Grid container={true} spacing={3} style={{ width: '90%', margin: 'auto' }}>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Card
                            style={{
                                boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                                    : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                                borderRadius: 8,
                                backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                                width: '100%',
                                padding: 0,
                            }}
                        >
                            <StyledCardActionArea themeMode={themeMode} onClick={this.onSelectRegular} >
                                <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <EventOutlined style={{width: 36, height: 36, marginBottom: 10}}/>
                                    Regular Live Event
                                </CardContent>
                            </StyledCardActionArea>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <Card
                            style={{
                                boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                                    : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                                borderRadius: 8,
                                backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                                width: '100%',
                                padding: 0,
                            }}
                        >
                            <StyledCardActionArea themeMode={themeMode} onClick={this.onSelectLeaderBoard} >
                                <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <BarChartOutlined style={{width: 36, height: 36, marginBottom: 10}}/>
                                    Leaderboard Live Event
                                </CardContent>
                            </StyledCardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            )
        }

        switch (seasonType) {
            case SeasonType.Regular:
                return (
                    <CreateSeasonRegularComponent
                        type='Create'
                        onCreate={this.onCreateRegular}
                    />
                );
            case SeasonType.LeaderBoard:
                return (
                    <CreateSeasonLeaderBoardComponent
                        type='Create'
                        onCreate={this.onCreateLeaderBoard}
                    />
                );
        }
    }

    private onSelectRegular = () => this.setState({ seasonType: SeasonType.Regular });
    private onSelectLeaderBoard = () => this.setState({ seasonType: SeasonType.LeaderBoard });

    private onCreateRegular = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], maxAmountPerReport: number) => {
        await actions.season.createRegular(name, start, end, metadata, developerOnly, tiers, maxAmountPerReport);
        this.toSeason();
    };

    private onCreateLeaderBoard = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], ranks: Entities.EntityLeaderBoardRank[], maxUsersPerInstance: number, maxAmountPerReport: number, leaderBoardType: LeaderBoardType) => {
        await actions.season.createLeaderBoard(name, start, end, metadata, developerOnly, tiers, ranks, maxUsersPerInstance, maxAmountPerReport, leaderBoardType);
        this.toSeason();
    };

    private toSeason = () => this.toLink( `/season/all`);
}

interface StyledCardActionAreaProps extends CardActionAreaProps, ThemeModeProps {
}
const StyledCardActionArea = styled((props: StyledCardActionAreaProps) => (
    <CardActionArea
        {...props}
        sx={{
            '&.MuiCardActionArea-root': {
                padding: '0px !important',
                height: 126,
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
                '&$selected': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
            }
        }}
    >
        {props.children}
    </CardActionArea>
))(() => ({}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
