import * as FileSaver from 'file-saver';
import { PlayerActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState, PlayerAction, PlayerState } from '../reducers';
import axios from 'axios';

export const expireMultiplePlayerSessions = async (startTimeSpam: number, endTimeSpam: number): Promise<void> => {
    await Request.post(`player/expireMultiplePlayerSessions`, {startTimeSpam, endTimeSpam});
};

export const getPlayerDetail = async (playerId: string): Promise<void> => {
    const detail: Entities.PlayerDetail | null = await Request.get(`player/${playerId}/details`);
    if (detail) {
        await getGeolocation(detail.player.ip);
    }

    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { ...detail },
    };
    dispatch(action);
};

export const deletePlayer = async (playerId: string): Promise<void> => {
    await Request.post(`player/${playerId}/delete`);
    const state: PlayerState = getState().player;
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayers,
        payload: {
            ...state,
            player: undefined,
            wallet: undefined,
            loginInfo: undefined,
        },
    };
    dispatch(action);
};

export const copyFromPlayer = async (
    playerId: string,
    originPlayerId: string,
    copyPlayerData: boolean,
    copyInventory: boolean,
    copyWallet: boolean,
    copyStats: boolean,
    copyLiveEvents: boolean,
    copySeasons: boolean,
    copyMatchmakingProperties: boolean,
    copyAchievements: boolean,
    copyPurchases: boolean,
): Promise<void> => {
    await Request.post(`player/${playerId}/copyFromPlayer`, {
        originPlayerId,
        copyPlayerData,
        copyInventory,
        copyWallet,
        copyStats,
        copyLiveEvents,
        copySeasons,
        copyMatchmakingProperties,
        copyAchievements,
        copyPurchases,
    });
    const player = getState().player.player;
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { player },
    };
    dispatch(action);
};

export const updateWallet = async (playerId: string, wallet: Record<string, number>): Promise<void> => {
    await Request.post(`player/${playerId}/wallet`, { balances: wallet });
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { wallet },
    };
    dispatch(action);
};

export const setBanned = async (playerId: string, banned: boolean): Promise<void> => {
    await Request.post(`player/${playerId}/setAsBanned`, { banned });
    const player = getState().player.player;
    if (player) {
        player.banned = banned;
    }
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { player },
    };
    dispatch(action);
};

export const setCheater = async (playerId: string, cheater: boolean): Promise<void> => {
    await Request.post(`player/${playerId}/setAsCheater`, { cheater });
    const player = getState().player.player;
    if (player) {
        player.cheater = cheater;
    }
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { player },
    };
    dispatch(action);
};

export const setDeveloper = async (playerId: string, developer: boolean): Promise<void> => {
    await Request.post(`player/${playerId}/setAsDeveloper`, { developer });
    const player = getState().player.player;
    if (player) {
        player.developer = developer;
    }
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { player },
    };
    dispatch(action);
};

export const expireSession = async (playerId: string): Promise<void> => {
    await Request.post(`player/${playerId}/expireSession`);
    const player = getState().player.player;
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { player },
    };
    dispatch(action);
};

export const updatePlayerData = async (playerId: string, key: string, value: string): Promise<void> => {
    await Request.post(`player/${playerId}/playerData/update`, { key, value });
};

export const deletePlayerData = async (playerId: string, key: string): Promise<void> => {
    await Request.post(`player/${playerId}/playerData/delete`, { key });
};

export const deleteMultiplePlayerData = async (playerId: string, keys: string[]): Promise<void> => {
    await Request.post(`player/${playerId}/playerData/deleteMultiple`, { keys });
};

export const deleteAllPlayerData = async (playerId: string): Promise<void> => {
    await Request.post(`player/${playerId}/playerData/deleteAll`);
};

export const exportPlayerData = async (playerId: string): Promise<void> => {
    const exportedData = await Request.post(`player/${playerId}/playerData/export`);
    const filename = `${playerId}_player_data.json`;
    const file = new File([JSON.stringify(exportedData, null, 2)], filename, { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(file);
};

export const importPlayerData = async (playerId: string, data: any): Promise<void> => {
    await Request.post(`player/${playerId}/playerData/import`, data);
};

export const getInventory = async (playerId: string): Promise<void> => {
    const { stackables, nonStackables } = await Request.get(`player/${playerId}/items`);
    const action: PlayerAction = {
        type: PlayerActionType.UpdatePlayerData,
        payload: { stackables, nonStackables },
    };
    dispatch(action);
};

export const grantItem = async (playerId: string, itemId: string, quantity: number): Promise<void> => {
    await Request.post(`player/${playerId}/item`, { playerId, itemId, quantity });
    // TODO: use response and update the state
    await getInventory(playerId);
};

export const sendPushNotification = async (playerId: string, title: string, body: string): Promise<void> => {
    await Request.post(`player/${playerId}/sendPushNotification`, { title, body });
};

export const unlinkId = async (playerId: string, type: string, id: string): Promise<void> => {
    await Request.post(`player/${playerId}/unlink/${type}/${id}`);
    await getPlayerDetail(playerId);
};

export const changeLinkAccounts = async (playerId: string, toPlayerId: string): Promise<void> => {
    await Request.post(`player/${playerId}/changeLinkAccounts`, { toPlayerId });
    await getPlayerDetail(playerId);
};

export const createCompensation = async (playerId: string, data: any): Promise<void> => {
    await Request.post(`player/${playerId}/compensations/create`, data);
};

export const updateCompensation = async (playerId: string, id: string, data: any): Promise<void> => {
    await Request.post(`player/${playerId}/compensations/update/${id}`, data);
};

export const deleteCompensation = async (playerId: string, id: string): Promise<void> => {
    await Request.post(`player/${playerId}/compensations/delete/${id}`);
};

export const resetLiveEvent = async (playerId: string, eventId: string, version: number): Promise<void> => {
    await Request.post(`player/${playerId}/liveEvents/${eventId}/reset`, { version });
};

export const resetSeason = async (playerId: string, seasonId: string): Promise<void> => {
    await Request.post(`player/${playerId}/seasons/${seasonId}/reset`);
};

const GEOSPIFY_API_KEY = '79a27d1950c643039434e77b5e6ffb6d';
export const getGeolocation = async (ip: string): Promise<void> => {
    const url = `https://api.geoapify.com/v1/ipinfo?&ip=${ip}&apiKey=${GEOSPIFY_API_KEY}`;
    try {
        const { data } = await axios({
            method: 'GET',
            url,
        });
        const action: PlayerAction = {
            type: PlayerActionType.UpdatePlayerData,
            payload: { geolocation: data },
        };
        dispatch(action);
    } catch (err) {
        console.error('Request error!', err);
        return;
    }
};
