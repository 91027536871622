import * as React from 'react';
import {
    formatDate,
    formatNumber,
    getLeaderBoardLink,
    isActive,
    isEnded,
    Link,
    parseJson,
    truncateString,
} from '../utils';
import actions, { ActionRequest } from '../actions';
import { connect } from 'react-redux';
import { AlertDialog, BaseElement, BasePlayerContainer, JsonDialog, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import { SeasonType, UserTitleRole } from '../enums';
import {
    BarChartOutlined,
    DeveloperModeOutlined,
    EventOutlined,
    RestartAltOutlined,
} from '@mui/icons-material';
import { Chip, Hidden } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
}

type DialogType = 'ConfirmResetProgress' | 'ExtraData';
interface State {
    readonly openDialog: DialogType | null;
    readonly selected: Entities.SeasonPlayer | null;
    readonly extraDataSelected: string;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selected: null,
        extraDataSelected: '',
    };

    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;

        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Seasons"}
                playerId = {playerId}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderResetProgressDialog()}
                {this.renderExtraDataDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const columns = [
            { title: 'Details', field: 'details', sorting: false},
            { title: 'statisticName', field: 'statisticName'},
            { title: 'Position', field: 'position', sorting: false},
            { title: 'Amount', field: 'amount'},
            { title: 'Extra Data', field: 'extraData', sorting: false},
        ];

        return (
            <Table
                data={this.getData}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                    actionsColumnIndex: -1,
                }}
                detailPanel={[
                    rowData => ({
                        disabled: rowData.season.tiers.length === 0,
                        tooltip: 'Season Details',
                        render: this.detailPlayerSeasonPanel,
                    })
                ]}
                actions= {
                    userTitleRole > UserTitleRole.Viewer ?
                    [
                        rowData => ({
                            icon: RestartAltOutlined,
                            tooltip: 'Reset Progress',
                            iconProps: {
                                color: 'primary',
                            },
                            hidden: userTitleRole === UserTitleRole.Viewer,
                            onClick: () => this.openResetProgressDialog(rowData.season)
                        })
                    ]
                    : undefined
                }
            />
        );
    };

    private getData = async (query: any) => {
        const { playerId } = this.props;
        const { search, page, pageSize, orderBy, orderDirection } = query;

        const result = await ActionRequest.get(`player/${playerId}/seasons`, {
            name: search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.seasons.map((season: any) => {
            return {
                details: this.getDetails(season),
                amount: formatNumber(season.amount),
                statisticName: season.statisticName !== '' ? getLeaderBoardLink(season.statisticName) : '',
                position: season.position !== null ? `#${season.position+1}` : '',
                extraData: parseJson(season.extraData, this.onExtraDataSelected),
                season,
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getDetails = (seasonPlayer: Entities.SeasonPlayer) => {
        const season = seasonPlayer.season;
        const to = `/season/${season.id}/details`;
        let icon: React.JSX.Element = (<></>);
        switch (season.type) {
            case SeasonType.Regular:
                icon = <EventOutlined style={{width: 25, height: 25}}/>;
                break;
            case SeasonType.LeaderBoard:
                icon = <BarChartOutlined style={{width: 25, height: 25}}/>;
                break;
            default:
                break;
        }

        let status = <div style={{color: 'grey', fontWeight: 500}}>Scheduled</div>;
        if (isActive(season.startTimestamp, season.endTimestamp)) {
            status = <div style={{color: 'green', fontWeight: 500}}>In Progress</div>;
        }
        else if (isEnded(season.endTimestamp)) {
            status = <div style={{color: 'orange', fontWeight: 500}}>Completed</div>;
        }

        return (
            <div>
                {season.developerOnly && (
                    <Chip
                        color={'secondary'}
                        icon={<DeveloperModeOutlined />}
                        size='small'
                        label='Developer Only'
                        style={{ backgroundColor: '#21BA47', color: 'white', margin: '2px 8px' }}
                    />
                )}
                <div style={{ display: 'flex' }}>
                    <Hidden smDown={true}>
                        <div style={{
                            padding: 4,
                            display: 'grid',
                            justifyContent: 'flex-end',
                            gridAutoFlow: 'column',
                        }}>
                            {icon}
                        </div>
                    </Hidden>
                    <div style={{display: 'inline-block', marginLeft: 10, verticalAlign: 'top'}}>
                        <Link to={to}>
                            {season.name !== '' ? truncateString(season.name) : season.id}
                        </Link>
                        {status}
                        <div>
                            <div style={{ fontWeight: 500 }}>Last edited</div>
                            {formatDate(season.updated)}
                        </div>
                        {seasonPlayer.joinedTimestamp && (
                            <div>
                                <div style={{ fontWeight: 500 }}>Joined</div>
                                {formatDate(seasonPlayer.joinedTimestamp)}
                            </div>
                        )}
                        {seasonPlayer.completedTimestamp && (
                            <div>
                                <div style={{ fontWeight: 500 }}>Completed</div>
                                {formatDate(seasonPlayer.completedTimestamp)}
                            </div>
                        )}
                        {seasonPlayer.finishedTimestamp && (
                            <div>
                                <div style={{ fontWeight: 500 }}>Finished</div>
                                {formatDate(seasonPlayer.finishedTimestamp)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    private detailPlayerSeasonPanel = (rowData: any) => {
        if (rowData.season.tiers.length === 0) {
            return "";
        }

        const columns: any[] = [
            { title: 'Tier ID', field: 'seasonTierId'},
            { title: 'Progress', field: 'progress'},
            { title: 'Started', field: 'started'},
            { title: 'Completed', field: 'completed'},
            { title: 'RewardCollected', field: 'rewardCollected'},
        ];

        const data = rowData.season.tiers
            .sort((t1: any, t2: any) => t1.startedTimestamp - t2.startedTimestamp)
            .map((t: any) => {
            return {
                seasonTierId: t.seasonTierId,
                progress: `${t.amount}`,
                started: t.startedTimestamp ? formatDate(t.startedTimestamp) : '',
                completed: t.completedTimestamp ? formatDate(t.completedTimestamp) : '',
                rewardCollected: t.rewardCollectedTimestamp ? formatDate(t.rewardCollectedTimestamp) : '',
            };
        });
        return (
            <div style={{ padding: 5 }}>
                <Table
                    data={data}
                    columns={columns}
                    backgroundColor={'secondary'}
                    options={{
                        toolbar: false,
                        selection: false,
                        paging: true,
                        emptyRowsWhenPaging: false,
                        search: false,
                        sorting: true,
                        draggable: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                    }}
                />
            </div>
        );
    }

    private openResetProgressDialog = (season: Entities.SeasonPlayer) => this.setState({openDialog: 'ConfirmResetProgress', selected: season});
    private renderResetProgressDialog = () => {
        const { openDialog} = this.state;
        return (
            <AlertDialog
                open={openDialog === 'ConfirmResetProgress'}
                title={'Confirm Reset'}
                content="This will permanently reset all the season progress."
                onClose={this.closeDialog}
                submitButtonText={'Reset'}
                onSubmit={this.resetProgress}
            />
        );
    };
    private resetProgress = async () => {
        const { playerId } = this.props;
        const { selected } = this.state;
        this.closeDialog();
        if (!selected) {
            return;
        }
        await actions.player.resetSeason(playerId, selected.seasonId);
    };

    private onExtraDataSelected = (extraDataSelected: string) => {
        if (extraDataSelected !== '') {
            this.setState({ openDialog: 'ExtraData', extraDataSelected });
        }
    };
    private renderExtraDataDialog = () => {
        const { openDialog, extraDataSelected } = this.state;
        return (
            <JsonDialog
                open={openDialog === 'ExtraData'}
                title="Extra Data"
                value={extraDataSelected}
                onClose={this.closeDialog}
            />
        );
    };

    private closeDialog = () => {
        this.setState({
            openDialog: null,
            selected: null,
            extraDataSelected: '',
        });
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: ''
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
