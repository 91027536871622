import * as React from 'react';
import {
    LeaderBoardType,
    TimerType,
    RecurringResetTimerType,
    AggregationMethod,
} from '../../enums';
import {
    Grid2 as Grid,
    InputLabel,
    Step as MaterialStep,
    StepButton,
    Stepper,
    DialogContentText,
    CardMedia,
    Chip, Hidden,
} from '@mui/material';
import { DeveloperModeOutlined, ExpandMore } from '@mui/icons-material';
import {
    AggregationMethodMenuType,
    formatMomentDate, getAggregationMethodMenus,
    getColorIndexByTitle, getColorTitleByIndex,
    RecurringEntity,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../../utils';
import { BaseCreateNewEventComponent, CreateLiveEventProps, CreateLiveEventState } from './BaseCreateNewEventComponent';
import { AutomatePlayerAction, LabelCheckBox, Metadata, NumberField, NumberSelect } from '../common';
import { LeaderBoardRanks } from './LeaderBoardRanks';
import { default as moment } from 'moment';

interface Props extends CreateLiveEventProps {
    readonly maxUsersPerInstance?: number;
    readonly onCreate: (
        name: string,
        gameData: string,
        scheduleColor: string,
        developerOnly: boolean,
        finishActions: Entities.AutomaticPlayerAction[],
        startTimestamp: number | null,
        endTimestamp: number | null,
        timerType: TimerType,
        recurringResetTimerType: RecurringResetTimerType | null,
        recurringStart: number | null,
        recurringDuration: number | null,
        recurringDailyDays: number[] | null,
        recurringWeeklyDayStart: number | null,
        ranks: Entities.EntityLeaderBoardRank[],
        maxUsersPerInstance: number,
        leaderBoardType: LeaderBoardType,
        includeGroupLeaderBoard: boolean,
        groupLeaderBoardAggregationMethod: AggregationMethod,
    ) => void;
}

interface State extends CreateLiveEventState {
    readonly maxUsersPerInstance: number;
    readonly leaderBoardType: LeaderBoardType;
    readonly ranks: Entities.EntityLeaderBoardRank[];
    readonly includeGroupLeaderBoard: boolean;
    readonly groupLeaderBoardAggregationMethod: AggregationMethod;
}

export class CreateNewLeaderBoardEventComponent extends BaseCreateNewEventComponent<Props, State> {
    state = this.initState();

    render() {
        const { step } = this.state;

        let detailContent: React.JSX.Element;
        let detailButtons: React.JSX.Element;

        let activeStep;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Schedule':
                activeStep = 1;
                detailContent = this.renderScheduleContent();
                detailButtons = this.renderScheduleButtons();
                break;
            case 'Rewards':
                activeStep = 2;
                detailContent = this.renderRewardsContent();
                detailButtons = this.renderRewardsButtons();
                break;
            case 'FinishActions':
                activeStep = 3;
                detailContent = this.renderFinishActionsContent();
                detailButtons = this.renderFinishActionsButtons();
                break;
            case 'Confirm':
                activeStep = 4;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
            default:
                activeStep = -1;
                detailContent = <></>;
                detailButtons = <></>;
                break;
        }

        return (
            <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                <Hidden smDown={true}>
                    <Grid size={12}>
                        <Stepper
                            alternativeLabel={true}
                            nonLinear={true}
                            activeStep={activeStep}
                        >
                            <MaterialStep key={'Details'} completed={activeStep > 0}>
                                <StepButton onClick={this.onDetailStepSet} >
                                    {'Details'}
                                </StepButton>
                            </MaterialStep>
                            <MaterialStep key={'Schedule'} completed={activeStep > 1}>
                                <StepButton onClick={this.onScheduleStepSet} >
                                    {'Schedule'}
                                </StepButton>
                            </MaterialStep>
                            <MaterialStep key={'Rewards'} completed={activeStep > 2}>
                                <StepButton onClick={this.onRewardsStepSet} >
                                    {'Rewards'}
                                </StepButton>
                            </MaterialStep>
                            <MaterialStep key={'FinishActions'} completed={activeStep > 3}>
                                <StepButton onClick={this.onFinishActionsStepSet} >
                                    {'Finish Actions'}
                                </StepButton>
                            </MaterialStep>
                            <MaterialStep key={'Confirm'} completed={activeStep > 4}>
                                <StepButton onClick={this.onConfirmStepSet} >
                                    {'Confirm'}
                                </StepButton>
                            </MaterialStep>
                        </Stepper>
                    </Grid>
                </Hidden>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    {detailContent}
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <div style={{
                        display: 'grid',
                        justifyContent: 'flex-end',
                        gridAutoFlow: 'column',
                        gridColumnGap: 10,
                    }}>
                        {detailButtons}
                    </div>
                </Grid>
            </Grid>
        );
    }

    protected updateFromOld() {
        const { oldLiveEvent, maxUsersPerInstance, type } = this.props;
        if (!oldLiveEvent) {
            return;
        }

        const { name, startTimestamp, endTimestamp, gameData, finishActions, scheduleColor, developerOnly } = oldLiveEvent;
        let ranks: Entities.EntityLeaderBoardRank[] = [];
        let maxUsers = 200;
        if (maxUsersPerInstance) {
            maxUsers = maxUsersPerInstance;
        }
        if (oldLiveEvent.ranks) {
            ranks = oldLiveEvent.ranks;
        }

        if (type === 'Create') {
            this.setState({
                name: `${name} (Copy)`,
                gameData,
                maxUsersPerInstance: maxUsers,
                finishActions: finishActions ? finishActions : [],
                developerOnly,
                ranks,
            });
            return;
        }

        this.setState({
            name,
            start: moment(startTimestamp),
            color: getColorIndexByTitle(scheduleColor),
            gameData,
            maxUsersPerInstance: 200,
            finishActions: finishActions ? finishActions : [],
            developerOnly,
            ranks,
        });
    }

    protected renderDetailContent = () => {
        const { developerOnly } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={8} alignContent={'start'} style={{ margin: 'auto' }}>
                    {developerOnly && (
                        <Chip
                            icon={<DeveloperModeOutlined />}
                            size='medium'
                            label='Developer Only'
                            style={{ backgroundColor: '#21BA47', color: 'white' }}
                        />
                    )}
                </Grid>
                <Grid size={4} alignContent={'end'} >
                    <LabelCheckBox
                        label="Developer Only"
                        labelSize={'14px'}
                        icon={DeveloperModeOutlined}
                        labelPlacement={'start'}
                        checked={developerOnly}
                        justifyContent={'flex-end'}
                        onChange={this.handleDeveloperOnlyChange}
                    />
                </Grid>
                {this.renderNameField()}
                {this.renderMaxUsersPerInstanceField()}
                {/*this.renderLeaderBoardTypeField()*/}
                {this.renderImageField()}
                {this.renderGameDataField()}
                {this.renderIncludeGroupLeaderBoard()}
            </Grid>
        );
    };

    protected renderIncludeGroupLeaderBoard = () => {
        const { includeGroupLeaderBoard, groupLeaderBoardAggregationMethod } = this.state;
        const aggregationMethodMenus = getAggregationMethodMenus();

        return (
            <>
                <Grid size={{ xs: 12 }} style={{ padding: 10 }}/>
                <Grid size={{ xs: 12 }}>
                    <LabelCheckBox
                        label="Include Group LeaderBoard"
                        labelSize={'14px'}
                        labelPlacement={'end'}
                        checked={includeGroupLeaderBoard}
                        onChange={this.handleIncludeGroupLeaderBoardChange}
                    />
                </Grid>
                {includeGroupLeaderBoard && (
                    <>
                        <Grid size={12}>
                            <InputLabel>Aggregation method</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <NumberSelect
                                labelId={`select-aggregation-method`}
                                value={groupLeaderBoardAggregationMethod}
                                onChange={this.handleGroupLeaderBoardAggregationMethodChange}
                            >
                                {Object.keys(aggregationMethodMenus).map(key => {
                                    const aggregationMethodMenuType = key as AggregationMethodMenuType;
                                    const {title, value} = aggregationMethodMenus[aggregationMethodMenuType];
                                    return (
                                        <option key={key} value={value}>
                                            {title}
                                        </option>
                                    );
                                })}
                            </NumberSelect>
                        </Grid>
                    </>
                )}
            </>
        );
    };

    protected renderRewardsContent = () => {
        const { ranks } = this.state;
        return (
            <Grid container={true} spacing={2}>
                <Grid size={12}>
                    <DialogContentText>
                        List here the prizes ranks that Nucleo will be automatically calculate at the moment to end the event.
                    </DialogContentText>
                </Grid>
                <Grid size={12}>
                    <LeaderBoardRanks ranks={ranks} onUpdateRanks={this.updateRanks} />
                </Grid>
            </Grid>
        );
    };

    protected renderConfirmContent = () => {
        const {
            name,
            start,
            end,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringWeeklyDayStart,
            gameData,
            maxUsersPerInstance,
            leaderBoardType,
            finishActions,
            file,
            developerOnly,
        } = this.state;

        const recurringStartTimestamp = recurringStart.clone().utc().valueOf() - recurringStart.clone().utc().startOf('day').valueOf();;
        const recurringEntity = new RecurringEntity({
            createdTimestamp: 0,
            startTimestamp: 0,
            endTimestamp: 0,
            timerType,
            recurringResetTimerType,
            recurringStart: recurringStartTimestamp,
            recurringDuration: recurringDuration * 60 * 1000,
            recurringWeeklyDayStart,
        });
        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                {developerOnly && (
                                    <Grid size={12}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<DeveloperModeOutlined />}
                                            size='small'
                                            label='Developer Only'
                                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                                        />
                                    </Grid>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                {timerType === TimerType.Scheduled && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start)}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end)}
                                        </Grid>
                                    </>
                                )}
                                {timerType === TimerType.Recurring && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Repeat</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {recurringEntity.getRecurringText()}
                                        </Grid>
                                    </>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Max Users Per Instance</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {maxUsersPerInstance}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Leader Board Type</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {LeaderBoardType[leaderBoardType].replace(/([A-Z])/g, ' $1').trim()}
                                </Grid>
                                {file && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Image</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            <CardMedia image={URL.createObjectURL(file)} style={{width: 200, height: 200, margin: 10}}/>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {gameData !== '' && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Metadata
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Metadata
                                    text={gameData}
                                    readOnly={true}
                                    style={{ width: '100%' }}
                                    keyValueContainerStyle={{ boxShadow: 'none' }}
                                    showBox={false}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
                {finishActions.length > 0 && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Rewards
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <AutomatePlayerAction
                                    actions={finishActions}
                                    readOnly={true}
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 5,
                                        backgroundColor: 'white',
                                    }}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
            </Grid>
        );
    };

    protected initState(): State {
        const baseState = super.initState();

        return {
            ...baseState,
            maxUsersPerInstance: 200,
            leaderBoardType: LeaderBoardType.RandomOld,
            ranks: [],
            includeGroupLeaderBoard: false,
            groupLeaderBoardAggregationMethod: AggregationMethod.Sum,
        };
    }

    protected onCreate = () => {
        const {
            name,
            start,
            end,
            timerType,
            recurringStart,
            gameData,
            developerOnly,
            maxUsersPerInstance,
            leaderBoardType,
            finishActions,
            ranks,
            color,
            includeGroupLeaderBoard,
            groupLeaderBoardAggregationMethod,
        } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        recurringStart.set('seconds', 0);
        recurringStart.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        let startTimestamp: number | null = null;
        let endTimestamp: number | null = null;
        let recurringResetTimerType: number | null = null;
        let recurringStartTimestamp: number | null = null;
        let recurringDuration: number | null = null;
        let recurringDailyDays: number[] | null = null;
        let recurringWeeklyDayStart: number | null = null;
        switch (timerType) {
            case TimerType.Scheduled:
                startTimestamp = start.clone().utc().valueOf();
                endTimestamp = end.clone().utc().valueOf();
                break;
            case TimerType.Recurring:
                startTimestamp = start.clone().utc().startOf('day').valueOf();
                endTimestamp = end.clone().utc().endOf('day').valueOf();
                recurringResetTimerType = this.state.recurringResetTimerType;
                recurringStartTimestamp = recurringStart.clone().utc().valueOf() - recurringStart.clone().utc().startOf('day').valueOf();
                recurringDuration = this.state.recurringDuration * 60 * 1000;
                switch (this.state.recurringResetTimerType) {
                    case RecurringResetTimerType.Daily:
                        recurringDailyDays = this.state.recurringDailyDays;
                        break;
                    case RecurringResetTimerType.Weekly:
                        recurringWeeklyDayStart = this.state.recurringWeeklyDayStart;
                        break;
                }
                break;
        }

        this.props.onCreate(
            name,
            gameData,
            getColorTitleByIndex(color),
            developerOnly,
            finishActions,
            startTimestamp,
            endTimestamp,
            timerType,
            recurringResetTimerType,
            recurringStartTimestamp,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
            ranks,
            maxUsersPerInstance,
            leaderBoardType,
            includeGroupLeaderBoard,
            groupLeaderBoardAggregationMethod,
        );
    };

    private updateRanks = (ranks: Entities.EntityLeaderBoardRank[]) => this.setState({ ranks });

    private handleMaxUsersPerInstanceChange = (maxUsersPerInstance: number) => this.setState({maxUsersPerInstance});

    private renderMaxUsersPerInstanceField = (disabled: boolean = false) => {
        const { maxUsersPerInstance } = this.state;

        return (
            <>
                <Grid size={12}>
                    <InputLabel>Max Users Per Instance</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        label=""
                        value={maxUsersPerInstance}
                        onChange={this.handleMaxUsersPerInstanceChange}
                        fullWidth={true}
                        disabled={disabled}
                        variant="outlined"
                    />
                </Grid>
            </>
        );
    };

    /*
    private renderLeaderBoardTypeField = () => {
        const { leaderBoardType } = this.state;

        const onChangeMethod = (event: SelectChangeEvent<number>) => {
            const type =  event.target.value as LeaderBoardType;
            this.setState({ leaderBoardType: type })
        }

        const leaderBoardTypeKeys = Object.keys(LeaderBoardType)
            .filter((key) => Number.isNaN(+key))
            .map((key: string, value: number) => ({
                key,
                value,
            }));
        return (
            <>
                <Grid size={12}>
                    <InputLabel>LeaderBoard Type</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={leaderBoardType}
                        onChange={onChangeMethod}
                        variant={'outlined'}
                        style={{ width: '100%', backgroundColor: 'white' }}
                    >
                        {leaderBoardTypeKeys.map(leaderBoardType => (
                            <MenuItem key={leaderBoardType.key} value={leaderBoardType.value}>
                                {`${leaderBoardType.key.replace(/([A-Z])/g, ' $1').trim()}`}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </>
        );
    };
    */

    private handleIncludeGroupLeaderBoardChange = (includeGroupLeaderBoard: boolean) => this.setState({includeGroupLeaderBoard});

    private handleGroupLeaderBoardAggregationMethodChange = (groupLeaderBoardAggregationMethod: number) => this.setState({ groupLeaderBoardAggregationMethod });
}
