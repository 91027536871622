import _ from 'lodash';
import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid2 as Grid,
    InputLabel,
    Typography,
} from '@mui/material';
import {
    AlertDialog,
    WarningDialog,
    BasePlayerContainer,
    Button,
    PlayerSendPushNotificationDialog,
    TextField,
    BaseElement, HeaderButton, CopyPlayerDialog,
} from '../components';
import actions from '../actions';
import {
    AccountBox,
    DeleteForeverOutlined,
    DeveloperModeOutlined,
    Facebook,
    Apple,
    FeaturedPlayList,
    Input,
    PermIdentity,
    ReportOutlined,
    ReportProblemOutlined,
    ContentCopyOutlined, CancelScheduleSendOutlined,
} from '@mui/icons-material';
import { formatDate, getPlayerPlatform, localeCurrencyNumber } from '../utils';
import { connect } from 'react-redux';
import { ContainerContext, mapProps } from './index';
import { UserTitleRole } from '../enums';
import { styled } from '@mui/styles';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
    readonly player?: Entities.Player;
    readonly geolocation?: Entities.Geolocation;
    readonly valueToDate?: number;
    readonly currencyCode?: string;
    readonly loginInfo?: Entities.LoginInfo;
    readonly firebaseInfo?: Entities.PlayerFirebaseInfo;
}

type DialogType = 'Delete' | 'Copy' | 'SendPushNotification' | 'ChangeLinkedAccount' | 'ConfirmSetAsDeveloper'
    | 'ConfirmSetAsCheater' | 'ConfirmSetAsBanned' | 'ConfirmExpireSession';
interface State {
    readonly openDialog: DialogType | null;
    readonly changeLinkedToPlayerId: string;
}

interface CardInfo {
    label: string;
    value: any;
    onChange?: (value: string) => void;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        changeLinkedToPlayerId: '',
    };

    async componentDidMount() {
        const { playerId } = this.props;
        await actions.player.getPlayerDetail(playerId);
    }

    protected renderContainer(): React.JSX.Element {
        const { playerId, player, firebaseInfo, loading } = this.props;
        let buttons: HeaderButton[] | undefined;
        if (player && this.props.userTitleRole > UserTitleRole.Viewer) {
            const { banned, cheater, developer } = player;
            buttons = [];
            buttons.push({
                text: 'Expire Session',
                icon: CancelScheduleSendOutlined,
                onClick: this.openConfirmExpireSessionDialog,
                color: 'secondary',
            });
            buttons.push({
                text: banned ? 'Remove Banned': 'Set Banned',
                icon: ReportOutlined,
                onClick: this.openConfirmSetBannedDialog,
                color: 'secondary',
                iconColor: 'red',
                textColor: 'red',
            });
            buttons.push({
                text: cheater ? 'Remove Cheater': 'Set Cheater',
                icon: ReportProblemOutlined,
                onClick: this.openConfirmSetCheaterDialog,
                color: 'secondary',
                iconColor: 'orange',
                textColor: 'orange',
            });
            buttons.push({
                text: developer ? 'Remove Developer': 'Set Developer',
                icon: DeveloperModeOutlined,
                onClick: this.openConfirmSetDeveloperDialog,
                color: 'secondary',
                iconColor: '#21BA47',
                textColor: '#21BA47',
            });
            if (firebaseInfo) {
                buttons.push({ text: 'Send Push Notification', onClick: this.openSendPushNotificationDialog});
            }
            if (developer) {
                buttons.push({ text: 'Copy Player', icon: ContentCopyOutlined, onClick: this.openCopyDialog});
            }
            buttons.push({ text: 'Delete Player', icon: DeleteForeverOutlined, color: 'error', onClick: this.openDeleteDialog});
        }
        return (
            <BasePlayerContainer
                {...this.props}
                loading = {!player || loading}
                themeMode={this.props.app.themeMode}
                title = {"Overview"}
                playerId = {playerId}
                showSubTabs = {true}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDeleteDialog()}
                {this.renderCopyDialog()}
                {this.renderSendPushNotificationDialog()}
                {this.renderChangeLinkedAccountDialog()}
                {this.renderConfirmSetBannedDialog()}
                {this.renderConfirmSetCheaterDialog()}
                {this.renderConfirmSetDeveloperDialog()}
                {this.renderConfirmExpireSessionDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { changeLinkedToPlayerId } = this.state;
        const { loginInfo, app } = this.props;
        if (!loginInfo) {
            return;
        }

        const { customIds, gameCenterIds, facebook, googlePlayGamesIds, customIdsBackup, appleIds, googleIds } = loginInfo;

        return (
            <Grid container={true} justifyContent="center" spacing={2}>
                <StyledGrid size={12}>
                    <Divider style={{ width: 'calc(50% - 110px)', margin: '0px 10px',}}/>
                    <StyledAccountBox />
                    <StyledTypography >
                        Player Account
                    </StyledTypography>
                    <Divider style={{ width: 'calc(50% - 110px)', margin: '0px 10px',}}/>
                </StyledGrid>
                <Grid size={12}>
                    <Grid container={true} justifyContent="flex-start" spacing={2}>
                        <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:4 }}>
                            {this.renderDisplayCard()}
                        </Grid>
                        <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:4 }}>
                            {this.renderDetail()}
                        </Grid>
                        <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:4 }}>
                            {this.renderDevice()}
                        </Grid>
                    </Grid>
                </Grid>
                {(customIds.length > 0 || gameCenterIds.length > 0 || facebook || googlePlayGamesIds.length > 0 || appleIds.length > 0 || googleIds.length > 0) && (
                    <>
                        <StyledGrid size={12}>
                            <Divider style={{ width: 'calc(50% - 110px)', margin: '0px 10px',}}/>
                            <StyledFeaturedPlayList />
                            <StyledTypography >
                                Linked Accounts
                            </StyledTypography>
                            <Divider style={{ width: 'calc(50% - 110px)', margin: '0px 10px',}}/>
                        </StyledGrid>
                        <Grid size={12} style={{ display: 'grid', gridColumnGap: 10, gridAutoFlow: 'column'}}>
                            <TextField
                                value={changeLinkedToPlayerId}
                                variant="outlined"
                                label={""}
                                fullWidth={true}
                                onChange={this.onChangeLinkedToPlayerId}
                                sx = {{
                                    '& .MuiInputBase-root': {
                                        height: '100%'
                                    }
                                }}
                            />
                            <Button
                                icon={Input}
                                text="Move linked accounts to Player"
                                variant="contained"
                                style={{ margin: 'auto 0px' }}
                                onClick={this.openChangeLinkedAccountDialog}
                                disabled={!this.validateChangeLinkedAccount()}
                            />
                        </Grid>
                        <Grid size={12}>
                            <Grid container={true} justifyContent="flex-start" spacing={2}>
                                {this.renderFacebook()}
                                {this.renderGoogleIds()}
                                {this.renderAppleIds()}
                                {this.renderGameCenterIds()}
                                {this.renderGooglePlayGames()}
                                {this.renderCustomIds()}
                            </Grid>
                        </Grid>
                    </>
                )}
                {customIdsBackup.length > 0 && (
                    <>
                        <StyledGrid size={12}>
                            <Divider style={{ width: 'calc(50% - 110px)', margin: '0px 10px',}}/>
                            <StyledFeaturedPlayList />
                            <StyledTypography >
                                History Accounts
                            </StyledTypography>
                            <Divider style={{ width: 'calc(50% - 110px)', margin: '0px 10px',}}/>
                        </StyledGrid>
                        <Grid size={12}>
                            <Grid container={true} justifyContent="flex-start" spacing={2}>
                                {this.renderCustomIdsBackup()}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    };

    private renderDisplayCard = () => {
        const { player, valueToDate, currencyCode, geolocation } = this.props;
        if (!player) {
            return;
        }
        const contents: CardInfo[] = [
            {
                label: 'Avatar Image',
                value: player.avatarImageUrl,
            },
            {
                label: 'Player ID',
                value: player.playerId,
            },
            {
                label: 'Display Name',
                value: player.displayName,
            },
            {
                label: 'Client Version',
                value: player.clientVersion,
            },
            {
                label: 'Value to date',
                value: `${valueToDate ? localeCurrencyNumber(valueToDate / 100, currencyCode) : localeCurrencyNumber(0)}`,
            },
            {
                label: 'Location',
                value: `${geolocation && geolocation.country ? geolocation.country.name : ''}, ${geolocation && geolocation.city ? geolocation.city.name : ''}`,
            },
        ];
        return this.renderCard('Display', contents);
    };

    private renderDetail = () => {
        const { player, firebaseInfo  } = this.props;
        if (!player) {
            return;
        }

        const contents: CardInfo[] = [
            {
                label: 'Last Login',
                value: formatDate(player.lastLogin),
            },
            {
                label: 'Created',
                value: formatDate(player.created),
            },
            {
                label: 'Language',
                value: player.language,
            },

        ];
        if (firebaseInfo) {
            contents.push({
                label: 'Firebase Notification Token',
                value: firebaseInfo.notificationToken,
            });
        }
        return this.renderCard('Detail', contents);
    };

    private renderDevice = () => {
        const { player } = this.props;
        if (!player) {
            return;
        }

        const contents: CardInfo[] = [
            {
                label: 'Platform',
                value: getPlayerPlatform(player, 40),
            },
            {
                label: 'Device Model',
                value: player.deviceModel,
            },
            {
                label: 'Device Name',
                value: player.deviceName,
            },
            {
                label: 'Operating System',
                value: player.operatingSystem,
            },
        ];
        return this.renderCard('Device', contents);
    };

    private renderCustomIds = () => {
        const { userTitleRole, loginInfo } = this.props;
        if (!loginInfo) {
            return;
        }

        const { customIds } = loginInfo;
        return _.map(customIds, info => {
            const contents: CardInfo[] = [
                {
                    label: 'ID',
                    value: info.customId,
                },
                {
                    label: 'Created',
                    value: formatDate(info.created),
                },
            ];
            return (
                <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:3 }}>
                    {
                        this.renderCard(
                            'Custom ID',
                            contents,
                            userTitleRole > UserTitleRole.Viewer ? () => this.unlinkCustomId(info.customId) : undefined,
                            <PermIdentity style={{height: 20, width: 20}}/>,
                        )
                    }
                </Grid>
            );
        });
    };

    private renderCustomIdsBackup = () => {
        const { userTitleRole, loginInfo } = this.props;
        if (!loginInfo) {
            return;
        }

        const { customIdsBackup } = loginInfo;
        return _.map(customIdsBackup, info => {
            const contents: CardInfo[] = [
                {
                    label: 'ID',
                    value: info.customId,
                },
                {
                    label: 'Created',
                    value: formatDate(info.created),
                },
            ];
            return (
                <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:3 }}>
                    {
                        this.renderCard(
                            'Custom ID',
                            contents,
                            userTitleRole > UserTitleRole.Viewer ? () => this.unlinkCustomId(info.customId) : undefined,
                            <PermIdentity style={{height: 20, width: 20}}/>,
                        )
                    }
                </Grid>
            );
        });
    };

    private renderGameCenterIds = () => {
        const { userTitleRole, loginInfo } = this.props;
        if (!loginInfo) {
            return;
        }

        const { gameCenterIds } = loginInfo;
        return _.map(gameCenterIds, info => {
            const contents: CardInfo[] = [
                {
                    label: 'ID',
                    value: info.gameCenterId,
                },
                {
                    label: 'Created',
                    value: formatDate(info.created),
                },
            ];
            return (
                <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:3 }}>
                    {
                        this.renderCard(
                            'Game Center ID',
                            contents,
                            userTitleRole > UserTitleRole.Viewer ? () => this.unlinkGameCenter(info.gameCenterId) : undefined,
                            <CardMedia
                                style={{ height: 20, width: 20 }}
                                image={`${process.env.PUBLIC_URL}/images/game_center_icon.png`}
                                title="Logo"
                            />
                        )
                    }
                </Grid>
            );
        });
    };

    private renderAppleIds = () => {
        const { userTitleRole, loginInfo } = this.props;
        if (!loginInfo) {
            return;
        }

        const { appleIds } = loginInfo;
        return _.map(appleIds, info => {
            const contents: CardInfo[] = [
                {
                    label: 'User Id',
                    value: info.userId,
                },
                {
                    label: 'Name',
                    value: info.name,
                },
                {
                    label: 'Email',
                    value: info.email,
                },
                {
                    label: 'Created',
                    value: formatDate(info.created),
                },
            ];
            return (
                <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:3 }}>
                    {
                        this.renderCard(
                            'Apple Id',
                            contents,
                            userTitleRole > UserTitleRole.Viewer ? () => this.unlinkAppleId(info.userId) : undefined,
                            <Apple style={{ height: 20, width: 20 }} />,
                        )
                    }
                </Grid>
            );
        });
    };

    private renderGoogleIds = () => {
        const { userTitleRole, loginInfo } = this.props;
        if (!loginInfo) {
            return;
        }

        const { googleIds } = loginInfo;
        return _.map(googleIds, info => {
            const contents: CardInfo[] = [
                {
                    label: 'User Id',
                    value: info.userId,
                },
                {
                    label: 'Name',
                    value: info.name,
                },
                {
                    label: 'Email',
                    value: info.email,
                },
                {
                    label: 'Created',
                    value: formatDate(info.created),
                },
                {
                    label: 'Avatar Image',
                    value: info.picture
                },
            ];
            return (
                <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:3 }}>
                    {
                        this.renderCard(
                            'Google',
                            contents,
                            userTitleRole > UserTitleRole.Viewer ? () => this.unlinkGoogle(info.userId) : undefined,
                            <CardMedia
                                style={{ height: 20, width: 20 }}
                                image={`${process.env.PUBLIC_URL}/images/google_icon.png`}
                                title="Logo"
                            />,
                        )
                    }
                </Grid>
            );
        });
    };

    private renderFacebook = () => {
        const { userTitleRole, loginInfo } = this.props;
        if (!loginInfo) {
            return;
        }

        const { facebook } = loginInfo;
        return !facebook
            ? null
            : <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:3 }}>
                {
                    this.renderCard(
                        'Facebook',
                        [
                            {
                                label: 'ID',
                                value: facebook.facebookId,
                            },
                            {
                                label: 'Name',
                                value: facebook.facebookName,
                            },
                            {
                                label: 'Created',
                                value: formatDate(facebook.created),
                            },
                            {
                                label: 'Avatar Image',
                                value: `https://graph.facebook.com/${facebook.facebookId}/picture?type=square`
                            },
                        ],
                        userTitleRole > UserTitleRole.Viewer ? () => this.unlinkFacebook(facebook.facebookId) : undefined,
                        <Facebook style={{ height: 20, width: 20, color: 'rgb(66, 103, 178)' }} />,
                  )
                }
            </Grid>;
    };

    private renderGooglePlayGames = () => {
        const { userTitleRole, loginInfo } = this.props;
        if (!loginInfo) {
            return;
        }

        const { googlePlayGamesIds } = loginInfo;
        return _.map(googlePlayGamesIds, info => {
            const contents: CardInfo[] = [
                {
                    label: 'ID',
                    value: info.googleId,
                },
                {
                    label: 'Name',
                    value: info.googleName,
                },
                {
                    label: 'Created',
                    value: formatDate(info.created),
                },
            ];
            if (info.email && info.email !== '') {
                contents.push({
                    label: 'Email',
                    value: info.email,
                });
            }
            if (info.picture && info.picture !== '') {
                contents.push({
                    label: 'Avatar Image',
                    value: info.picture,
                });
            }

            return (
                <Grid size={{ xs:12, sm:6, md:4, lg:4, xl:3 }}>
                    {
                        this.renderCard(
                            'Google Play Games',
                            contents,
                            userTitleRole > UserTitleRole.Viewer ? () => this.unlinkGooglePlayGames(info.googleId) : undefined,
                            <CardMedia
                                style={{ height: 20, width: 20 }}
                                image={`${process.env.PUBLIC_URL}/images/google_play_games_icon.png`}
                                title="Logo"
                            />,
                        )
                    }
                </Grid>
            );
        });
    };

    private renderCard = (
        title: string,
        contents: CardInfo[],
        onUnlink?: () => void,
        avatar?: React.ReactNode,
    ) => {
        return (
            <Card key={title}>
                <StyledCardHeader
                    title={title}
                    avatar={avatar}
                />
                <StyledCardContent>
                    <Grid container={true} spacing={0}>
                        {_.map(contents, ({ label, value }) => {
                            const isString = _.isString(value);
                            const isUrl = isString ? value ? value.startsWith('http') : false : false;
                            return (
                                <>
                                    <Grid size={12}>
                                        <InputLabel>{label}</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <>
                                            {isString && (
                                                <>
                                                    {isUrl && (
                                                        <Grid size={{ xs:12, sm:6, md:6, lg:3 }}>
                                                            <CardMedia image={value} style={{width: 80, height: 80, borderRadius: 0, border: `1px solid #c3cfdd`}}/>
                                                        </Grid>
                                                    )}
                                                    {!isUrl && (
                                                        <TextField
                                                            value={value || ''}
                                                            variant="outlined"
                                                            label={""}
                                                            fullWidth={true}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {!isString && (value)}
                                        </>
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                </StyledCardContent>
                {onUnlink && (
                    <CardActions>
                        <Button
                            text="Unlink"
                            variant="outlined"
                            onClick={onUnlink} />
                    </CardActions>
                )}
            </Card>
        );
    };

    private closeDialog = () => this.setState({ openDialog: null, changeLinkedToPlayerId: '' });

    private openDeleteDialog = () => this.setState({ openDialog: 'Delete' });
    private renderDeleteDialog = () => {
        const { openDialog } = this.state;
        const deletePlayer = async () => {
            await actions.player.deletePlayer(this.props.playerId);
            this.toLink(`/players/all`);
        };

        return (
            <WarningDialog
                themeMode={this.props.app.themeMode}
                open={openDialog === 'Delete'}
                title="Delete Player"
                content="Delete player's account and all associated data? This cannot be undone."
                onClose={this.closeDialog}
                onSubmit={deletePlayer}
                maxWidth={'xs'}
            />
        );
    };

    private openCopyDialog = () => this.setState({ openDialog: 'Copy' });
    private renderCopyDialog = () => {
        const { openDialog } = this.state;
        const onCopyPlayer = async (
            player: Entities.Player,
            copyPlayerData: boolean,
            copyInventory: boolean,
            copyWallet: boolean,
            copyStats: boolean,
            copyLiveEvents: boolean,
            copySeasons: boolean,
            copyMatchmakingProperties: boolean,
            copyAchievements: boolean,
            copyPurchases: boolean,
        ) => {
            const originPlayer = this.props.player;
            if (!originPlayer) {
                return;
            }

            await actions.player.copyFromPlayer(
                originPlayer.playerId,
                player.playerId,
                copyPlayerData,
                copyInventory,
                copyWallet,
                copyStats,
                copyLiveEvents,
                copySeasons,
                copyMatchmakingProperties,
                copyAchievements,
                copyPurchases,
            );
        };

        return (
            <CopyPlayerDialog
                open={openDialog === 'Copy'}
                title="Copy Player"
                TitleIcon = {ContentCopyOutlined}
                onClose={this.closeDialog}
                onCopyPlayer={onCopyPlayer}
            />
        );
    };

    private openSendPushNotificationDialog = () => this.setState({ openDialog: 'SendPushNotification' });
    private renderSendPushNotificationDialog = () => {
        const { openDialog } = this.state;
        const sendPushNotification = async (title: string, body: string) => {
            await actions.player.sendPushNotification(this.props.playerId, title, body);
        };

        return (
            <PlayerSendPushNotificationDialog
                open={openDialog === 'SendPushNotification'}
                title={'Send Push Notification'}
                onSend={sendPushNotification}
                onClose={this.closeDialog}
            />
        );
    };

    private openConfirmSetBannedDialog = () => this.setState({ openDialog: 'ConfirmSetAsBanned' });
    private renderConfirmSetBannedDialog = () => {
        const { player } = this.props;
        const { openDialog } = this.state;
        if (!player) {
            return;
        }

        const setBanned = async () => {
            const banned = !player.banned;
            await actions.player.setBanned(player.playerId, banned);
        };

        return (
            <WarningDialog
                themeMode={this.props.app.themeMode}
                open={openDialog === 'ConfirmSetAsBanned'}
                title={player && player.banned ? 'Remove Banned' : 'Set As Banned'}
                content={player && player.banned ? 'Are you sure you want to remove the Banned from this player?' : 'Are you sure you want to set this player as Banned?'}
                onClose={this.closeDialog}
                onSubmit={setBanned}
                maxWidth={'xs'}
            />
        );
    };

    private openConfirmExpireSessionDialog = () => this.setState({ openDialog: 'ConfirmExpireSession' });
    private renderConfirmExpireSessionDialog = () => {
        const { openDialog } = this.state;
        const expireSession = async () => {
            await actions.player.expireSession(this.props.playerId);
        };

        return (
            <WarningDialog
                themeMode={this.props.app.themeMode}
                open={openDialog === 'ConfirmExpireSession'}
                title={'Expire Session'}
                content={'This will expire the player season'}
                onClose={this.closeDialog}
                onSubmit={expireSession}
                maxWidth={'xs'}
            />
        );
    };

    private openConfirmSetCheaterDialog = () => this.setState({ openDialog: 'ConfirmSetAsCheater' });
    private renderConfirmSetCheaterDialog = () => {
        const { player } = this.props;
        if (!player) {
            return;
        }

        const { openDialog } = this.state;
        const setCheater = async () => {
            const cheater = !player.cheater;
            await actions.player.setCheater(player.playerId, cheater);
        };

        return (
            <WarningDialog
                themeMode={this.props.app.themeMode}
                open={openDialog === 'ConfirmSetAsCheater'}
                title={player && player.cheater ? 'Remove Cheater' : 'Set As Cheater'}
                content={player && player.cheater ? 'Are you sure you want to remove the Cheater from this player?': 'Are you sure you want to set this player as Cheater?'}
                onClose={this.closeDialog}
                onSubmit={setCheater}
                maxWidth={'xs'}
            />
        );
    };

    private openConfirmSetDeveloperDialog = () => this.setState({ openDialog: 'ConfirmSetAsDeveloper' });
    private renderConfirmSetDeveloperDialog = () => {
        const { player } = this.props;
        if (!player) {
            return;
        }

        const { openDialog } = this.state;
        const setDeveloper = async () => {
            const developer = !player.developer;
            await actions.player.setDeveloper(player.playerId, developer);
        };

        return (
            <WarningDialog
                themeMode={this.props.app.themeMode}
                open={openDialog === 'ConfirmSetAsDeveloper'}
                title={player && player.developer ? 'Remove Developer' : 'Set As Developer'}
                content={player && player.developer ? 'Are you sure you want to remove the Developer from this player?' : 'Are you sure you want to set this player as Developer?'}
                onClose={this.closeDialog}
                onSubmit={setDeveloper}
                maxWidth={'xs'}
            />
        );
    };

    private unlinkGoogle = (id: string) => this.unlinkId('Google', id);

    private unlinkGooglePlayGames = (id: string) => this.unlinkId('GooglePlayGames', id);

    private unlinkFacebook = (id: string) => this.unlinkId('Facebook', id);

    private unlinkGameCenter = (id: string) => this.unlinkId('GameCenter', id);

    private unlinkCustomId = (id: string) => this.unlinkId('Custom', id);

    private unlinkAppleId = (id: string) => this.unlinkId('AppleId', id);

    private unlinkId = async (linkType: string, id: string) => {
        await actions.player.unlinkId(this.props.playerId, linkType, id);
    };

    private onChangeLinkedToPlayerId = (changeLinkedToPlayerId: string) => this.setState({ changeLinkedToPlayerId });
    private validateChangeLinkedAccount() {
      return this.state.changeLinkedToPlayerId !== '';
    }

    private openChangeLinkedAccountDialog = () => this.setState({ openDialog: 'ChangeLinkedAccount' });
    private renderChangeLinkedAccountDialog = () => {
        const { openDialog, changeLinkedToPlayerId } = this.state;
        if (changeLinkedToPlayerId === '') {
            return;
        }
        return (
            <AlertDialog
                open={openDialog === 'ChangeLinkedAccount'}
                title="Change Linked Account"
                content={`This will permanently move the linked accounts to the player ${changeLinkedToPlayerId}.`}
                onClose={this.closeDialog}
                submitButtonText={'Change'}
                onSubmit={this.onChangeLinkedAccount}
            />
        );
    };
    private onChangeLinkedAccount = async () => {
        const { changeLinkedToPlayerId } = this.state;
        this.closeDialog();
        if (changeLinkedToPlayerId === '') {
            return;
        }
        await actions.player.changeLinkAccounts(this.props.playerId, changeLinkedToPlayerId);
    };
}

const StyledTypography = styled(Typography)(({
    textAlign: 'center',
    padding: 12,
    fontWeight: 600,
    fontSize: 'small',
}));

const StyledFeaturedPlayList = styled(FeaturedPlayList)(({
    marginLeft: 5,
    marginRight: 5,
    width: 30,
    height: 30,
}));

const StyledAccountBox = styled(AccountBox)(({
    marginLeft: 5,
    marginRight: 5,
    width: 30,
    height: 30,
}));

const StyledGrid = styled(Grid)(({
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 'bold',
    width: '100%',
}));

const StyledCardHeader = styled(CardHeader)(({
    '&.MuiCardHeader-root': {
        height: 0,
        padding: '12px 5px',
    },
    '& .MuiCardHeader-title': {
        fontSize: 12,
        fontWeight: 600,
    },
}));

const StyledCardContent = styled(CardContent)(({
    padding: '5px !important',
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: '',
    player: state.player.player,
    geolocation: state.player.geolocation,
    valueToDate: state.player.valueToDate,
    currencyCode: state.player.currencyCode,
    loginInfo: state.player.loginInfo,
    firebaseInfo: state.player.firebaseInfo,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
