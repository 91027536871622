import * as React from 'react';
import {
    Box, Checkbox, Chip,
    DialogContentText,
    Grid2 as Grid,
    InputLabel, ListItemText, MenuItem,
    OutlinedInput,
    Select as MuiSelect, SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { DatePicker } from './DatePicker';
import { NumberSelect } from './input';
import { RecurringResetTimerType } from '../../enums';
import { TimePicker } from './TimePicker';
import { daysMenus, DaysMenuType, DurationMenuType, getDurationMenus, Menu } from '../../utils';
import { default as moment, Moment } from 'moment';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

interface Props extends ThemeModeProps {
    readonly title: string;
    readonly start: Moment;
    readonly handleStartChange: (start: Moment) => void;
    readonly end: Moment;
    readonly handleEndChange: (start: Moment) => void;
    readonly recurringResetTimerType: RecurringResetTimerType;
    readonly handleRecurringResetTimerTypeChange: (recurringResetTimerType: RecurringResetTimerType) => void;
    readonly recurringStart: Moment;
    readonly handleRecurringStartChange: (recurringStart: Moment) => void;
    readonly recurringDuration: number;
    readonly handleRecurringDurationChange: (recurringDuration: number) => void;
    readonly recurringDailyDays: number[] | null;
    readonly handleRecurringDailyDaysChange: (recurringDailyDays: number[] | null) => void;
    readonly recurringWeeklyDayStart: number;
    readonly handleRecurringWeeklyDayStartChange: (recurringWeeklyDayStart: number) => void;
}

export class RecurringEntityComponent extends React.Component<Props> {
    render() {
        const {
            themeMode,
            title,
            start,
            end,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringDailyDays,
            recurringWeeklyDayStart,
        } = this.props;

        const timesMenus = getDurationMenus();

        return (
            <Grid container={true} spacing={0}>
                <Grid size={{ xs: 12 }}>
                    <DialogContentText>
                        {title}
                    </DialogContentText>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Start</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <DatePicker
                        value={start}
                        onChange={this.handleStartChange}
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>End</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <DatePicker
                        value={end}
                        onChange={this.handleEndChange}
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Repeats</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <NumberSelect
                        value={recurringResetTimerType}
                        onChange={this.handleRecurringResetTimerTypeChange}
                    >
                        <option key={RecurringResetTimerType.Daily} value={RecurringResetTimerType.Daily as number}>
                            Daily
                        </option>
                        <option key={RecurringResetTimerType.Weekly} value={RecurringResetTimerType.Weekly as number}>
                            Weekly
                        </option>
                    </NumberSelect>
                </Grid>
                {recurringResetTimerType === RecurringResetTimerType.Daily && (
                    <>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Time</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <TimePicker
                                value={recurringStart}
                                onChange={this.handleRecurringStartChange}
                                ampm={false}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Days</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <div style={{
                                display: 'grid',
                                gridAutoFlow: 'column',
                                gridColumnGap: 10,
                                justifyContent: 'flex-start',
                            }}>
                                <Typography variant="caption" style={{ margin: 'auto' }}>Everyday</Typography>
                                <Switch
                                    checked={recurringDailyDays !== null}
                                    onChange={this.allDaysModeChange}
                                    color="primary"
                                    name="checkedLeaderBoardView"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography variant="caption" style={{ margin: 'auto' }}>Some Days</Typography>
                            </div>
                        </Grid>
                        {recurringDailyDays !== null && (
                            <Grid size={{ xs: 12 }}>
                                <MuiSelect
                                    multiple={true}
                                    onChange={this.handleRecurringDailyDaysChange}
                                    value={recurringDailyDays}
                                    fullWidth={true}
                                    input={<OutlinedInput sx={{ padding: '5px', height: 'auto' }}/>}
                                    variant={'outlined'}
                                    renderValue={days => {
                                        const dayNames = days
                                            .sort((d1, d2) => d1 - d2)
                                            .map(day => {
                                                let dayName = '';
                                                Object.keys(daysMenus).forEach(key => {
                                                        const dayMenuType = key as DaysMenuType;
                                                        if (daysMenus[dayMenuType].value === day) {
                                                            dayName = daysMenus[dayMenuType].title;
                                                        }
                                                    }
                                                );
                                                return dayName;
                                            });
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {dayNames.map(dayName => (
                                                    <Chip
                                                        size={'small'}
                                                        key={dayName}
                                                        label={dayName}
                                                        style={{
                                                            backgroundColor: themeMode === 'light' ? '#eeeeee' : '#404243',
                                                            color: themeMode === 'light' ? '#343434' : '#ffffff',
                                                            padding: 5,
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        );
                                    }}
                                >
                                    {Object.keys(daysMenus).map(key => {
                                        const dayMenuType = key as DaysMenuType;
                                        const {title, value} = daysMenus[dayMenuType];
                                        return (
                                            <MenuItem key={key} value={value}>
                                                <Checkbox checked={recurringDailyDays.includes(value)} />
                                                <ListItemText primary={title} />
                                            </MenuItem>
                                        );
                                    })}
                                </MuiSelect>
                            </Grid>
                        )}
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Duration</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <NumberSelect
                                value={recurringDuration}
                                onChange={this.handleRecurringDurationChange}
                            >
                                {Object.keys(timesMenus)
                                    .filter(key => {
                                        const durationMenuType = key as DurationMenuType;
                                        const {title, value} = timesMenus[durationMenuType];
                                        return value < timesMenus['1day'].value;
                                    })
                                    .map(key => {
                                        const durationMenuType = key as DurationMenuType;
                                        const {title, value} = timesMenus[durationMenuType];
                                        return (
                                            <option key={key} value={value}>
                                                {title}
                                            </option>
                                        );
                                    })
                                }
                            </NumberSelect>
                        </Grid>
                    </>
                )}
                {recurringResetTimerType === RecurringResetTimerType.Weekly && (
                    <>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Days</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <NumberSelect
                                value={recurringWeeklyDayStart}
                                onChange={this.handleRecurringWeeklyDayStartChange}
                            >
                                {Object.keys(daysMenus).map(key => {
                                    const dayMenuType = key as DaysMenuType;
                                    const {title, value} = daysMenus[dayMenuType];
                                    return (
                                        <option key={key} value={value}>
                                            <Checkbox checked={recurringWeeklyDayStart === value} />
                                            <ListItemText primary={title} />
                                        </option>
                                    );
                                })}
                            </NumberSelect>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Time</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <TimePicker
                                value={recurringStart}
                                onChange={this.handleRecurringStartChange}
                                ampm={false}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Duration</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <NumberSelect
                                value={recurringDuration}
                                onChange={this.handleRecurringDurationChange}
                            >
                                {Object.keys(timesMenus)
                                    .filter(key => {
                                        const durationMenuType = key as DurationMenuType;
                                        const {title, value} = timesMenus[durationMenuType];
                                        return value < timesMenus['1week'].value;
                                    })
                                    .map(key => {
                                        const durationMenuType = key as DurationMenuType;
                                        const {title, value} = timesMenus[durationMenuType];
                                        return (
                                            <option key={key} value={value}>
                                                {title}
                                            </option>
                                        );
                                    })
                                }
                            </NumberSelect>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private handleStartChange = (start: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (start === null) {
            return;
        }
        this.props.handleStartChange(start);
    }

    private handleEndChange = (end: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (end === null) {
            return;
        }
        this.props.handleEndChange(end);
    }

    private handleRecurringResetTimerTypeChange = (recurringResetTimerType: number) => {
        this.props.handleRecurringResetTimerTypeChange(recurringResetTimerType);
    }

    private handleRecurringStartChange = (recurringStart: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (recurringStart === null) {
            return;
        }
        this.props.handleRecurringStartChange(recurringStart);
    }

    private allDaysModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleRecurringDailyDaysChange(event.target.checked ? [moment.parseZone().day()] : null);
    }

    private handleRecurringDailyDaysChange = (event: SelectChangeEvent<number[]>) => {
        const recurringDailyDays = event.target.value as number[];
        this.props.handleRecurringDailyDaysChange(recurringDailyDays);
    }

    private handleRecurringDurationChange = (recurringDuration: number) => this.props.handleRecurringDurationChange(recurringDuration);

    private handleRecurringWeeklyDayStartChange = (recurringWeeklyDayStart: number) => this.props.handleRecurringWeeklyDayStartChange(recurringWeeklyDayStart);
}