import * as React from 'react';
import {
    formatDate,
    formatNumber,
    getLeaderBoardLink,
    isActive,
    isEnded,
    Link,
    parseJson,
    truncateString,
} from '../utils';
import actions, { ActionRequest } from '../actions';
import { connect } from 'react-redux';
import { AlertDialog, BaseElement, BasePlayerContainer, JsonDialog, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import { EventType, UserTitleRole } from '../enums';
import {
    BarChartOutlined,
    DeveloperModeOutlined,
    EventOutlined,
    RestartAltOutlined,
} from '@mui/icons-material';
import { Chip, Hidden } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Query } from 'material-table';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
}

type DialogType = 'ConfirmResetProgress' | 'ExtraData';
interface State {
    readonly openDialog: DialogType | null;
    readonly selected: Entities.PlayerLiveEvent | null;
    readonly extraDataSelected: string;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selected: null,
        extraDataSelected: '',
    };

    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;

        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Live Events"}
                playerId = {playerId}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderResetProgressDialog()}
                {this.renderExtraDataDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;

        const columns = [
            { title: 'Details', field: 'details', sorting: false},
            { title: 'Statistic Name', field: 'statisticName'},
            { title: 'Position', field: 'position', sorting: false},
            { title: 'Value', field: 'value'},
            { title: 'Extra Data', field: 'extraData', sorting: false},
        ];

        return (
            <Table
                data={this.getData}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                    actionsColumnIndex: -1,
                }}
                actions= {
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                icon: RestartAltOutlined,
                                tooltip: 'Reset Progress',
                                iconProps: {
                                    color: 'primary',
                                },
                                hidden: userTitleRole === UserTitleRole.Viewer,
                                onClick: () => this.openResetProgressDialog(rowData.liveEvent)
                            })
                        ]
                        : undefined
                }
            />
        );
    };

    private getData = async (query: Query<any>) => {
        const { playerId } = this.props;
        const { search, page, pageSize, orderBy, orderDirection } = query;

        const result = await ActionRequest.get<Search.PlayerLiveEvents>(`player/${playerId}/liveEvents`, {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection,
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.liveEvents.map(liveEvent => {
            return {
                details: this.getDetails(liveEvent),
                statisticName: getLeaderBoardLink(liveEvent.statisticName),
                value: formatNumber(liveEvent.value),
                position: liveEvent.position !== null ? `#${liveEvent.position+1}` : '',
                extraData: parseJson(liveEvent.extraData, this.onExtraDataSelected),
                liveEvent,
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getDetails = (playerLiveEvent: Entities.PlayerLiveEvent) => {
        const liveEvent = playerLiveEvent.liveEvent;
        const to = `/liveEvent/${liveEvent.eventId}/details`;
        let icon: React.JSX.Element = (<></>);
        switch (liveEvent.eventType) {
            case EventType.Regular:
                icon = <EventOutlined style={{width: 25, height: 25}}/>;
                break;
            case EventType.LeaderBoard:
                icon = <BarChartOutlined style={{width: 25, height: 25}}/>;
                break;
            default:
                break;
        }

        let status = <div style={{color: 'grey', fontWeight: 500}}>Scheduled</div>;
        if (isActive(liveEvent.startTimestamp, liveEvent.endTimestamp)) {
            status = <div style={{color: 'green', fontWeight: 500}}>In Progress</div>;
        }
        else if (isEnded(liveEvent.endTimestamp)) {
            status = <div style={{color: 'orange', fontWeight: 500}}>Completed</div>;
        }

        return (
            <div>
                {liveEvent.developerOnly && (
                    <Chip
                        color={'secondary'}
                        icon={<DeveloperModeOutlined />}
                        size='small'
                        label='Developer Only'
                        style={{ backgroundColor: '#21BA47', color: 'white', margin: '2px 8px' }}
                    />
                )}
                <div style={{ display: 'flex' }}>
                    <Hidden smDown={true}>
                        <div style={{
                            padding: 4,
                            display: 'grid',
                            justifyContent: 'flex-end',
                            gridAutoFlow: 'column',
                        }}>
                            {icon}
                        </div>
                    </Hidden>
                    <div style={{display: 'inline-block', marginLeft: 10, verticalAlign: 'top'}}>
                        <Link to={to}>
                            {liveEvent.name !== '' ? truncateString(liveEvent.name) : liveEvent.eventId}
                        </Link>
                        {status}
                        {playerLiveEvent.joinedTimestamp && (
                            <div>
                                <div style={{ fontWeight: 500 }}>Joined</div>
                                {formatDate(playerLiveEvent.joinedTimestamp)}
                            </div>
                        )}
                        {playerLiveEvent.finishedTimestamp && (
                            <div>
                                <div style={{ fontWeight: 500 }}>Finished</div>
                                {formatDate(playerLiveEvent.finishedTimestamp)}
                            </div>
                        )}
                        {playerLiveEvent.rewardCollectedTimestamp && (
                            <div>
                                <div style={{ fontWeight: 500 }}>Reward Collected</div>
                                {formatDate(playerLiveEvent.rewardCollectedTimestamp)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    private openResetProgressDialog = (liveEvent: Entities.PlayerLiveEvent) => this.setState({openDialog: 'ConfirmResetProgress', selected: liveEvent});
    private renderResetProgressDialog = () => {
        const { openDialog} = this.state;
        return (
            <AlertDialog
                open={openDialog === 'ConfirmResetProgress'}
                title={'Confirm Reset'}
                content="This will permanently reset all the season progress."
                onClose={this.closeDialog}
                submitButtonText={'Reset'}
                onSubmit={this.resetProgress}
            />
        );
    };
    private resetProgress = async () => {
        const { playerId } = this.props;
        const { selected } = this.state;
        this.closeDialog();
        if (!selected) {
            return;
        }
        const { eventId, version} = selected;
        await actions.player.resetLiveEvent(playerId, eventId, version);
    };

    private onExtraDataSelected = (extraDataSelected: string) => {
        if (extraDataSelected !== '') {
            this.setState({ openDialog: 'ExtraData', extraDataSelected });
        }
    };
    private renderExtraDataDialog = () => {
        const { openDialog, extraDataSelected } = this.state;
        return (
            <JsonDialog
                open={openDialog === 'ExtraData'}
                title="Extra Data"
                value={extraDataSelected}
                onClose={this.closeDialog}
            />
        );
    };

    private closeDialog = () => {
        this.setState({
            openDialog: null,
            selected: null,
            extraDataSelected: '',
        });
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: '',
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
