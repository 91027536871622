import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer,
    BaseElement,
    Button,
    NumberSelect,
    RecurringEntityComponent,
    ScheduledEntityComponent,
    TextField,
} from '../components';
import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    ExpandMore,
    PollOutlined as PollIcon,
} from '@mui/icons-material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import {
    DialogContentText,
    Grid2 as Grid,
    InputLabel,
    Step,
    StepButton,
    Stepper,
    Tab,
    Tabs,
} from '@mui/material';
import { AggregationMethod, LeaderboardType, TimerType, RecurringResetTimerType } from '../enums';
import {
    AggregationMethodMenuType,
    formatMomentDate,
    getAggregationMethodMenus,
    getDurationMenus,
    getLeaderboardMenus,
    LeaderboardMenuType, RecurringEntity,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../utils';
import { default as moment, Moment } from 'moment/moment';
import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/styles';
import actions from '../actions';

interface Props extends ContainerContext.Props {
}

type Step = 'Detail' | 'Reset' | 'Confirm';
interface State {
    readonly step: Step;
    readonly name: string;
    readonly leaderboardType: LeaderboardType;
    readonly aggregationMethod: AggregationMethod;
    readonly start: Moment;
    readonly end: Moment;
    readonly timerType: TimerType;
    readonly recurringResetTimerType: RecurringResetTimerType;
    readonly recurringStart: Moment;
    readonly recurringDuration: number;
    readonly recurringDailyDays: number[] | null;
    readonly recurringWeeklyDayStart: number;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        step: 'Detail',
        name: '',
        leaderboardType: LeaderboardType.Player,
        aggregationMethod: AggregationMethod.Last,
        start: moment.parseZone(),
        end: moment.parseZone().add(1, 'day'),
        timerType: TimerType.None,
        recurringResetTimerType: RecurringResetTimerType.Daily,
        recurringStart: moment.parseZone(),
        recurringDuration: getDurationMenus()['1hour'].value,
        recurringDailyDays: null,
        recurringWeeklyDayStart: 0,
    };

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'LeaderBoard',
                to: `/leaderBoard/all`,
            },
            {
                text: 'Create',
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Create LeaderBoard"}
                TitleIcon = {PollIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { step } = this.state;

        let detailContent: React.JSX.Element;
        let detailButtons: React.JSX.Element;

        let activeStep;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Reset':
                activeStep = 1;
                detailContent = this.renderResetContent();
                detailButtons = this.renderResetButtons();
                break;
            case 'Confirm':
                activeStep = 3;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
            default:
                activeStep = -1;
                detailContent = <></>;
                detailButtons = <></>;
                break;
        }

        return (
            <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                <Grid size={12}>
                    <Stepper
                        alternativeLabel={true}
                        nonLinear={true}
                        activeStep={activeStep}
                        style={{
                            width: '80%',
                            margin: 'auto',
                            borderRadius: 5,
                        }}
                    >
                        <Step key={'Details'} completed={activeStep > 0}>
                            <StepButton onClick={this.onDetailStepSet} >
                                {'Details'}
                            </StepButton>
                        </Step>
                        <Step key={'Schedule'} completed={activeStep > 1}>
                            <StepButton onClick={this.onResetStepSet} >
                                {'Reset'}
                            </StepButton>
                        </Step>
                        <Step key={'Confirm'} completed={activeStep > 2}>
                            <StepButton onClick={this.onConfirmStepSet} >
                                {'Confirm'}
                            </StepButton>
                        </Step>
                    </Stepper>
                </Grid>
                <Grid size={12} style={{height: 30}}/>
                <Grid size={12}>
                    {detailContent}
                </Grid>
                <Grid size={12} style={{height: 30}}/>
                <Grid size={12} style={{
                    display: 'grid',
                    justifyContent: 'flex-end',
                    gridAutoFlow: 'column',
                    gridColumnGap: 10,
                }}>
                    {detailButtons}
                </Grid>
            </Grid>
        );
    }

    private renderDetailContent(): React.JSX.Element {
        const {
            name,
            leaderboardType,
            aggregationMethod,
        } = this.state;

        const leaderboardMenus = getLeaderboardMenus();
        const aggregationMethodMenus = getAggregationMethodMenus();

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        id="live-event-name"
                        label=""
                        value={name}
                        onChange={this.handleNameChange}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Type</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberSelect
                        labelId={`select-leaderboard-type`}
                        value={leaderboardType}
                        onChange={this.handleLeaderboardTypeChange}
                    >
                        {Object.keys(leaderboardMenus).map(key => {
                            const leaderboardType = key as LeaderboardMenuType;
                            const {title, value} = leaderboardMenus[leaderboardType];
                            return (
                                <option key={key} value={value}>
                                    {title}
                                </option>
                            );
                        })}
                    </NumberSelect>
                </Grid>
                <Grid size={12}>
                    <InputLabel>Aggregation method</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberSelect
                        labelId={`select-aggregation-method`}
                        value={aggregationMethod}
                        onChange={this.handleAggregationMethodChange}
                    >
                        {Object.keys(aggregationMethodMenus).map(key => {
                            const aggregationMethodMenuType = key as AggregationMethodMenuType;
                            const {title, value} = aggregationMethodMenus[aggregationMethodMenuType];
                            return (
                                <option key={key} value={value}>
                                    {title}
                                </option>
                            );
                        })}
                    </NumberSelect>
                </Grid>
            </Grid>
        );
    }

    private renderDetailButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onResetStepSet} variant="contained"/>
            </>
        );
    }

    private onDetailStepSet = () => this.setState({ step: 'Detail'});

    private renderResetContent(): React.JSX.Element {
        const { app } = this.props;
        const {
            timerType,
            start,
            end,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringWeeklyDayStart,
            recurringDailyDays
        } = this.state;

        return (
            <TabContext value={timerType.toString()}>
                <Tabs
                    value={timerType.toString()}
                    onChange={(event, value) => this.setState({ timerType: parseInt(value) })}
                >
                    <Tab label="Never" value={"0"}/>
                    <Tab label="Scheduled" value={"1"}/>
                    <Tab label="Recurring" value={"2"}/>
                </Tabs>
                <StyledTabPanel value={"0"}>
                    <DialogContentText>
                        The leaderboard will start immediately after you complete the setup and never reset.
                    </DialogContentText>
                </StyledTabPanel>
                <StyledTabPanel value={"1"}>
                    <ScheduledEntityComponent
                        title={'Scheduled leaderboards only run once at the selected date and time in the future.'}
                        themeMode={app.themeMode}
                        start={start}
                        handleStartChange={this.handleStartChange}
                        end={end}
                        handleEndChange={this.handleEndChange}
                    />
                </StyledTabPanel>
                <StyledTabPanel value={"2"}>
                    <RecurringEntityComponent
                        title={'Recurring leaderboards will continue to run until you stop them or a specific end date you set is reached.'}
                        themeMode={app.themeMode}
                        start={start}
                        handleStartChange={this.handleStartChange}
                        end={end}
                        handleEndChange={this.handleEndChange}
                        recurringResetTimerType={recurringResetTimerType}
                        handleRecurringResetTimerTypeChange={this.handleRecurringResetTimerTypeChange}
                        recurringStart={recurringStart}
                        handleRecurringStartChange={this.handleRecurringStartChange}
                        recurringDuration={recurringDuration}
                        handleRecurringDurationChange={this.handleRecurringDurationChange}
                        recurringDailyDays={recurringDailyDays}
                        handleRecurringDailyDaysChange={this.handleRecurringDailyDaysChange}
                        recurringWeeklyDayStart={recurringWeeklyDayStart}
                        handleRecurringWeeklyDayStartChange={this.handleRecurringWeeklyDayStartChange}
                    />
                </StyledTabPanel>
            </TabContext>
        );
    };

    private renderResetButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained"/>
            </>
        );
    }

    private onResetStepSet = () => this.setState({ step: 'Reset'});

    private renderConfirmContent(): React.JSX.Element {
        const {
            name,
            start,
            end,
            timerType,
            recurringResetTimerType,
            recurringStart,
            recurringDuration,
            recurringWeeklyDayStart,
        } = this.state;

        const recurringStartTimestamp = recurringStart.clone().utc().valueOf() - recurringStart.clone().utc().startOf('day').valueOf();;
        const recurringEntity = new RecurringEntity({
            createdTimestamp: 0,
            startTimestamp: 0,
            endTimestamp: 0,
            timerType,
            recurringResetTimerType,
            recurringStart: recurringStartTimestamp,
            recurringDuration: recurringDuration * 60 * 1000,
            recurringWeeklyDayStart,
        });
        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                {timerType === TimerType.Scheduled && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start)}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end)}
                                        </Grid>
                                    </>
                                )}
                                {timerType === TimerType.Recurring && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Repeat</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {recurringEntity.getRecurringText()}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
            </Grid>
        );
    }

    protected renderConfirmButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onResetStepSet} variant="contained" color="secondary" />
                <Button text="Confirm" onClick={this.onCreate} variant="contained"/>
            </>
        );
    }

    private onCreate = async () => {
        const {
            name,
            leaderboardType,
            aggregationMethod,
            start,
            end,
            timerType,
            recurringStart,
        } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        recurringStart.set('seconds', 0);
        recurringStart.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        let startTimestamp: number | null = null;
        let endTimestamp: number | null = null;
        let recurringResetTimerType: number | null = null;
        let recurringStartTimestamp: number | null = null;
        let recurringDuration: number | null = null;
        let recurringDailyDays: number[] | null = null;
        let recurringWeeklyDayStart: number | null = null;
        switch (timerType) {
            case TimerType.Scheduled:
                startTimestamp = start.clone().utc().valueOf();
                endTimestamp = end.clone().utc().valueOf();
                break;
            case TimerType.Recurring:
                startTimestamp = start.clone().utc().startOf('day').valueOf();
                endTimestamp = end.clone().utc().endOf('day').valueOf();
                recurringResetTimerType = this.state.recurringResetTimerType;
                recurringStartTimestamp = recurringStart.clone().utc().valueOf() - recurringStart.clone().utc().startOf('day').valueOf();
                recurringDuration = this.state.recurringDuration * 60 * 1000;
                switch (this.state.recurringResetTimerType) {
                    case RecurringResetTimerType.Daily:
                        recurringDailyDays = this.state.recurringDailyDays;
                        break;
                    case RecurringResetTimerType.Weekly:
                        recurringWeeklyDayStart = this.state.recurringWeeklyDayStart;
                        break;
                }
                break;
        }
        await actions.leaderboard.create(name, leaderboardType, aggregationMethod, startTimestamp, endTimestamp, timerType, recurringResetTimerType, recurringStartTimestamp, recurringDuration, recurringDailyDays, recurringWeeklyDayStart);
        this.toLink(`/leaderBoard/all`);
    }

    private onConfirmStepSet = () => this.setState({ step: 'Confirm'});

    private handleNameChange = (name: string) => this.setState({ name });

    private handleStartChange = (start: Moment) => this.setState({ start });

    private handleEndChange = (end: Moment) => this.setState({ end });

    private handleLeaderboardTypeChange = (leaderboardType: number) => this.setState({ leaderboardType });

    private handleAggregationMethodChange = (aggregationMethod: number) => this.setState({ aggregationMethod });

    private handleRecurringDurationChange = (recurringDuration: number) => this.setState({ recurringDuration });

    private handleRecurringResetTimerTypeChange = (recurringResetTimerType: number) => this.setState({ recurringResetTimerType });

    private handleRecurringDailyDaysChange = (recurringDailyDays: number[] | null) => this.setState({ recurringDailyDays });

    private handleRecurringWeeklyDayStartChange = (recurringWeeklyDayStart: number) => this.setState({ recurringWeeklyDayStart });

    private handleRecurringStartChange = (recurringStart: Moment) => this.setState({ recurringStart });
}

const StyledTabPanel = styled(TabPanel)(({
    '&.MuiTabPanel-root': {
        padding: 20,
    }
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
